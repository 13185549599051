import * as THREE from 'three';
import Raycaster from './Raycaster';
import AudioSampler from './AudioSampler';
import ExplodeAnimation from "./ExplodeAnimation";

const notes = ['drop', 'flutter', 'modem', 'osc', 'tada', 'voz',
               'drop', 'flutter', 'modem', 'osc', 'tada', 'osc'];

export default class Dissevelt {
  constructor(scene, camera, position = [0,0,0], pointsTexture) {
    this.audioSampler = new AudioSampler();
    this.scene = scene;
    this.groupPosition = position;
    this.raycaster = new Raycaster(camera);
    this.objects = [];
    this.explosions = {};
    this.pointsTexture = pointsTexture;
    this.raycaster.setOnClickCallback(this.onClick);
    const initialPositions = [
      0.9154600159849346,
      0.5538454486903899,
      0.11018920490592099,
      0.2747872396625365,
      0.9212448545188698,
      0.2618078601241225,
      0.549122009831299,
      0.04144933744775847,
      0.6448243837878027,
      0.7820487017416116,
      0.5660238099398291,
      0.5819232805037913,
      0.12449953090830235,
      0.004765296082731352,
      0.37791834964011173,
      0.6274158857502841,
      0.6050277844927738,
      0.23813795982775465,
      0.9213220668103457,
      0.4244314497934012,
      0.39751354395241
    ];

    const cylinderGeometry = new THREE.CylinderGeometry(0, 1, 2, 4, 3);
    const notesGroup = new THREE.Group();

    for (let i = 0; i < 12; i++) {
      const mesh = new THREE.Mesh(cylinderGeometry, new THREE.MeshNormalMaterial());
      mesh.position.x = (initialPositions[i] - 0.5) * 20;
      mesh.position.y = 5 + (initialPositions[i + 1] - 0.5) * 20;
      mesh.position.z = (initialPositions[i + 2] - 0.5) * 10;
      mesh.rotation.x = initialPositions[i + 3] * 2 * Math.PI;
      mesh.rotation.y = initialPositions[i + 4] * 2 * Math.PI;
      mesh.rotation.z = initialPositions[i + 5] * 2 * Math.PI;
      mesh.note = i;
      notesGroup.add(mesh);
      this.objects.push(mesh);
      this.raycaster.addClickableObject(mesh);
    }
    notesGroup.position.set(...position);
    this.scene.add(notesGroup);
  }

  onClick = (object) => {
    const {uuid, position, note} = object;
    this.audioSampler.playNote(notes[note]);
    if(this.explosions[uuid]) {
      this.scene.remove(this.explosions[uuid].getObject());
    }
    this.explosions[uuid] = new ExplodeAnimation(
      position.x + this.groupPosition[0],
      position.y + this.groupPosition[1],
      position.z + this.groupPosition[2],
      0xBAF7C7,
      this.pointsTexture,
    );

    this.scene.add(this.explosions[uuid].getObject());

    setTimeout(() => {
      if(this.explosions[uuid]) {
        this.scene.remove(this.explosions[uuid].getObject());
      }
      delete this.explosions[uuid];
    }, 15000);

  }

  update = () => {
    for (let i = 0; i < this.objects.length; i++) {
      this.objects[i].rotation.y += 0.02;
      this.objects[i].position.y += Math.sin(Date.now() * 0.0001)*.01;
      const {uuid} = this.objects[i];
      if(this.explosions[uuid]) this.explosions[uuid].update();
    }
  }



}
