export const SET_SHOULD_MOVE = 'SET_SHOULD_MOVE';
export const SET_SHOULD_NOT_MOVE = 'SET_SHOULD_NOT_MOVE';
export const SET_OXXXO_CONFIG = 'SET_OXXXO_CONFIG';
export const SET_DEBUG_MODE = 'SET_DEBUG_MODE';
export const UPDATE_CONNECTED_USERS_COUNT = 'UPDATE_CONNECTED_USERS_COUNT';

export function shouldMove() {
  return { type: SET_SHOULD_MOVE };
}

export function shouldNotMove() {
  return { type: SET_SHOULD_NOT_MOVE };
}

export function setOxxxoConfig(data) {
  return { type: SET_OXXXO_CONFIG, data };
}

export function setDebugMode(data) {
  return { type: SET_DEBUG_MODE, data };
}

export function updateConnectedUsersCount(data) {
  return { type: UPDATE_CONNECTED_USERS_COUNT, data };
}
