import * as THREE from "three";
import * as CANNON from 'cannon-es';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
// import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';
import VirtualSpaceInterface from '../../../oxxxo/VirtualSpaceInterface';
import Raycaster from '../../../three-utils/Raycaster';
// import { importedModels } from '../classes/modelsLoader';
import Sanity from "../../../util/sanity";
import CreateText from "../../../three-utils/createText";
import Flag from "../../../three-utils/flag/Flag";
import Wind from "../../../three-utils/flag/Wind";
import GifAnimation from "../../../three-utils/GifAnimation";
import ExplodeAnimation from "../../../three-utils/ExplodeAnimation";
import UsaFlag from "../../../three-utils/flag/textures/ezgif.com-gif-maker.png";
import BanderaGif from "../../../three-utils/flag/textures/ezgif.com-gif-maker.png";

class VirtualSpace extends VirtualSpaceInterface {

  createSpace = () => {
    const fbxLoader = new FBXLoader();

    const light = new THREE.AmbientLight(0x101010); // soft white light
    // this.scene.add(light);

    this.textureLoader.load('textures/matazanos/cielo-360.jpg', (texture) => {
      // texture.mapping = THREE.UVMapping;
      const geometry = new THREE.SphereGeometry(300, 16, 16);
      const cielo = new THREE.Mesh(geometry,
                              new THREE.MeshPhongMaterial({ map: texture }));
      cielo.material.lightMapIntensity = .02;
      cielo.material.side = THREE.DoubleSide;
      cielo.position.set(0,0,0);
      this.interactiveObjects.sky = cielo;
      this.scene.add(cielo);
    });

    this.usaFlag = new Flag(this.scene, this.physicsWorld, UsaFlag, new THREE.Vector3(0,5,-25), false);

    this.flag = new Flag(this.scene, this.physicsWorld, BanderaGif, new THREE.Vector3(0,5,0), true);
    this.wind = new Wind(this.flag.mesh);
/*
    this.gif = new GifAnimation(
      BanderaGif,
      new THREE.Vector3(2,3,0),
      new THREE.Vector3(-1.50, 1.890, 0),
      [3,3],
      [5, 10, 49, 15]
    );

    this.scene.add(this.gif.getMesh());
*/
  }

  explosion = () => {
    if(this.interactiveObjects.explode) {
      this.scene.remove(this.interactiveObjects.explode.getObject());
    }
    this.interactiveObjects.explode = new ExplodeAnimation(1, 3.5, 8);
    this.scene.add(this.interactiveObjects.explode.getObject());
    setTimeout(() => {
      if(this.interactiveObjects.explode) {
        this.scene.remove(this.interactiveObjects.explode.getObject());
      }
      delete this.interactiveObjects.explode;
    }, 15000);
  }

  createOrtoedro = (image, url="", position, rotation, width=2, height=1, physics=true, ancho=1) => {

    /* sides order: arriba, frente, reverso, a, b */
    const sides = [
      { image: `${image}_arriba.png`,
        size: { width, height: height*ancho },
        sidePosition: { x: 0,
                    y: 0+(height/2),
                    z: 0+(height/2)*ancho },
        sideRotation: { x: Math.PI/2, y: 0, z: 0 }
      },
      { image: `${image}_frente.png`,
        size: { width, height },
        sidePosition: { x: 0,
                    y: 0,
                    z: 0},
        sideRotation: { x: 0, y: Math.PI, z: 0 }
      },
      { image: `${image}_reverso.png`,
        size: { width, height },
        sidePosition: { x: 0,
                    y: 0,
                    z: 0+(height*ancho) },
        sideRotation: { x: 0, y: 0, z: 0 }
      },
      { image: `${image}_ladoa.png`,
        size: { width: height*ancho, height },
        sidePosition: { x: 0-(width/2),
                    y: 0,
                    z: 0+(height/2)*ancho },
        sideRotation: { x: 0, y: Math.PI*1.5, z: 0 }
      },
      { image: `${image}_ladob.png`,
        size: { width: height*ancho, height },
        sidePosition: { x: 0+(width/2),
                    y: 0,
                    z: 0+(height/2)*ancho },
        sideRotation: { x: 0, y: Math.PI/2, z: 0 }
      }
    ];
    const octoedroGroup = new THREE.Group();
    sides.map((side) => {
      this.textureLoader.load(side.image, (_texture) => {
        const texture = _texture;
        texture.encoding = THREE.sRGBEncoding;
        const geometry = new THREE.PlaneGeometry(side.size.width, side.size.height);
        const material = new THREE.MeshPhongMaterial({
          map: texture,
          side: THREE.DoubleSide,
          transparent: true
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(side.sidePosition.x, side.sidePosition.y, side.sidePosition.z);
        mesh.rotation.set(side.sideRotation.x, side.sideRotation.y, side.sideRotation.z);
        mesh.userData = {URL: url};
        this.raycaster.addClickableObject(mesh);
        octoedroGroup.add(mesh);
      });
    });
    octoedroGroup.position.set(position.x, position.y, position.z);
    // octoedroGroup.rotation.set(rotation.x, rotation.y, rotation.z);

    if(physics){
      const octaedroPhysicsBox = new CANNON.Box(new CANNON.Vec3(width/2, height/2, height/2))
      const octaedroPhysicsBody = new CANNON.Body({mass: 0, shape: octaedroPhysicsBox});
      octaedroPhysicsBody.position.set(position.x, position.y, position.z+(height/2));
      this.physicsWorld.addBody(octaedroPhysicsBody);
    }
    this.scene.add(octoedroGroup);
  }

  createPlaneLink = (image, url, position, size = {height:1, width: 1}) => {
    this.textureLoader.load(image, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      const geometry = new THREE.PlaneGeometry(size.height, size.width);
      const material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true
      });
      const mesh = new THREE.Mesh(geometry, material);
      if(url){
        mesh.userData = {URL: url};
        this.raycaster.addClickableObject(mesh);
      }
      mesh.position.set(position.x, position.y, position.z);
      this.scene.add(mesh);

    });

  }


  update = () => {
    if(this.gif) this.gif.animate();
    this.wind.update();
    this.flag.update();
    this.flag.applyWind(this.wind);
    this.usaFlag.update();
    this.usaFlag.applyWind(this.wind);

    if (this.interactiveObjects.sky) this.interactiveObjects.sky.rotation.y += .0005;
  }

}

export default VirtualSpace;
