import { firebaseRealtimeDatabase } from './firebaseConnection';

export default async function isUsersLimitReached(nombreDeEspacio) {
  const refPath = `${nombreDeEspacio}${process.env.REACT_APP_FIREBASE_USERS_DATABASE_SUFFIX}`;

  try {
    const snapshot = await firebaseRealtimeDatabase.ref(refPath).once('value');
    const connectedUsersCount = snapshot.numChildren();

    const maxUsers = parseInt(process.env.REACT_APP_MAXIMUM_NUMBER_OF_USERS, 10);
    return connectedUsersCount >= maxUsers;
  } catch (error) {
    console.error('Error checking user limit:', error);
    return true; // Return true if an error occurs
  }
}
