import * as THREE from 'three';
import * as CANNON from 'cannon-es';

export const initStairs = (scene) => {
  const count = 100;
  const geometry = new THREE.BoxBufferGeometry(1, 1, 2);
  const material = new THREE.MeshBasicMaterial();
  const wireframeMaterial = new THREE.MeshBasicMaterial({
    wireframe: true,
    color: 'black',
  });

  const mesh = new THREE.InstancedMesh(geometry, material, count);
  const wireframeMesh = new THREE.InstancedMesh(geometry, wireframeMaterial, count);
  scene.add(mesh);
  scene.add(wireframeMesh);

  const dummy = new THREE.Object3D();
  const anchura = 10;
  const profundidad = 1;
  const altura = 0.2;
  const posX = 45;
  const posY = 0;
  const posZ = 70;
  const stepSize = 1.5;
  let step = 1;

  mesh.scales = new Float32Array(mesh.count);
  for (let i = 1; i < 81; i += 1) {
    dummy.scale.set(anchura * 2, altura * step * 2, profundidad * step);
    dummy.position.set(
      posX + 0,
      posY + (altura * 2 * step),
      posZ + (profundidad * 2 * step),
    );
    dummy.rotation.y = (step * 0.01 * Math.PI) / 2;
    dummy.updateMatrix();
    mesh.setMatrixAt(i, dummy.matrix);
    mesh.updateMatrix();
    wireframeMesh.setMatrixAt(i, dummy.matrix);
    step += stepSize;
  }
};

export const initStairsPhysics = (physicsWorld) => {
  for (let step = 1; step < 120; step += 1.5) {
    const anchura = 10;
    const profundidad = 1;
    const altura = 0.2;
    const posX = 45;
    const posY = 0;
    const posZ = 70;

    const escalera = new CANNON.Box(
      new CANNON.Vec3(anchura, altura * step, profundidad * step),
    );
    const escaleraBody = new CANNON.Body({ mass: 0, shape: escalera });
    escaleraBody.position.set(
      posX + 0,
      posY + (altura * 2 * step),
      posZ + (profundidad * 2 * step),
    );

    const axis = new CANNON.Vec3(0, 1, 0);
    const angle = ((step * 0.01) * Math.PI) / 2;
    escaleraBody.quaternion.setFromAxisAngle(axis, angle);

    physicsWorld.addBody(escaleraBody);

    /*
    const geometryEscalera = new THREE.BoxGeometry(anchura*2, altura*2*step, profundidad*2*step);

    const mesh = new THREE.Mesh(geometryEscalera, new THREE.MeshBasicMaterial());
    mesh.position.set(
    posX + 0,
    posY + (altura*2*step),
    posZ + (profundidad * 2 * step));
    mesh.rotation.y=(step*.01)*Math.PI / 2;
    //  this.scene.add(mesh)
    */

    /*
    const wireframe = new THREE.WireframeGeometry( geometryEscalera );

    const line = new THREE.LineSegments( wireframe );
    line.material.depthTest = true;
    line.material.opacity = 0.25;
    line.material.transparent = false;
    line.material.color = new THREE.Color(0x000000);
    line.position.set(
        posX + 0,
        posY + (altura*2*step),
        posZ + (profundidad * 2 * step));
      line.rotation.y=(step*.01)*Math.PI / 2;

    this.scene.add(line);
    */
  }
};
