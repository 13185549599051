import React from 'react';

export default {
  infoPage: {
    artists: 'Artistas',
    schedule: 'Horarios',
  },
  raveVirtualTransfronterizx: {
    main_text: (
      <>
        <span className="infoPage__text--negritas">ℝ𝕒𝕧𝕖 𝕍𝕚𝕣𝕥𝕦𝕒𝕝 𝕋𝕣𝕒𝕟𝕤𝕗𝕣𝕠𝕟𝕥𝕖𝕣𝕚𝕫𝕩</span>
        {' '}
        es un evento simbólico que pretende mostrar cómo la música y el internet pueden crear comunidades a pesar de la distancia y las barreras físicas que dividen.
        <br />
        <br />
        Dos escenarios en un mundo virtual serán divididos por la representación digital de la frontera mexico-americana. En cada lado, músicos electrónicos de ambos países tocarán simultáneamente en vivo para un público que también será ubicado en alguno de los dos lados de la barda, dependiendo su lugar de conexión a internet. A través de un avatar, los usuarios podrán explorar el espacio y encontrar la forma de cruzar al otro escenario.
        <br />
        <br />
        Inspirado en el fandango fronterizo y como pretexto de la publicación en del primer disco en vinilo del dúo musical mexico-americano
        {' '}
        <a href="http://franciscoymadero.bandcamp.com/" target="_blank" rel="noopener noreferrer">Francisco y Madero</a>
        , la
        {' '}
        <span className="infoPage__text--negritas">ℝ𝕒𝕧𝕖 𝕍𝕚𝕣𝕥𝕦𝕒𝕝 𝕋𝕣𝕒𝕟𝕤𝕗𝕣𝕠𝕟𝕥𝕖𝕣𝕚𝕫𝕩</span>
        {' '}
        se llevará a cabo el sábado 15 de agosto a través de
        {' '}
        <span className="infoPage__text--rave">oxxxo.club</span>
        {' '}
        de 6:00 pm a 3:00 am (hora de Ciudad de México, GMT-5).
        <br />
        <br />
        <span className="infoPage__text--rave">oxxxo.club</span>
        {' '}
        es una aplicación web accesible desde cualquier dispositivo móvil o de escritorio en la que anteriormente se han agendado eventos como una exposición de ilustración virtual junto al colectivo Matazanos y un escenario virtual para el festival Ceremonia 2020. Aunque es una plataforma sin fines de lucro, a través de ella se podrán realizar donaciones monetarias, el 80% se destinará a los artistas, y el resto se otorgará al proyecto
        <i>DESERT WATER DROPS</i>
        {' '}
        de
        <a href="https://www.borderangels.org" target="_blank" rel="noopener noreferrer">borderangels.org</a>
        , que coloca botellas con agua en el desierto para ser encontrados por migrantes durante su trayectoria.
      </>
    ),
    team: (<>
      El mundo virtual es el resultado de la colaboración con
      {' '}
      <a href="https://www.instagram.com/taal.mx/" target="_blank" rel="noopener noreferrer">TAAL</a>
      , estudio arquitectónico que diseña espacios que posibilitan formas eficaces de participación y convivencia social para el desarrollo urbano. La programación y logística está a cargo de la
      <a href="https://www.instagram.com/la.conato/" target="_blank" rel="noopener noreferrer">CONATO</a>
      , plataforma que busca fortalecer la producción sonora local a partir de una infraestructura comunitaria que soporta la viabilidad y visibilidad de proyectos nacionales.
    </>),
    date: '15/agosto/2020',
    oxxxoStage: <>
      <span>Escenario</span>
      {' '}
      OXXXO
                </>,
    radionopalStage: <>
      <span>Escenario</span>
      {' '}
      CONATO x Radio Nopal
                     </>,
    piranhaLabDescription: (<>
      <a href="http://piranhalab.cc" target="_blank" rel="noopener noreferrer">PiranhaLab</a>
      {' '}
      se une al
      <span className="infoPage__text--negritas">ℝ𝕒𝕧𝕖 𝕍𝕚𝕣𝕥𝕦𝕒𝕝 𝕋𝕣𝕒𝕟𝕤𝕗𝕣𝕠𝕟𝕥𝕖𝕣𝕚𝕫𝕩</span>
      {' '}
      creando su propio escenario dentro de su plataforma
      <a href="http://piranhalab.cc" target="_blank" rel="noopener noreferrer">Underborders</a>
      , que haciendo analogía a un túnel, sirve como vínculo entre los dos escenarios de
      <span className="infoPage__text--rave">oxxxo.club</span>
      .
    </>),
  },
  fonima: {
    main_text: (
      <>
        <span className="infoPage__text--negritas">FONIMA</span>
        {' '}
        es un evento que reúne exponentes de la escena
        musical, animación y vj’s a través de una sesión de
        improvisación musical acompañada de gifs animados que
        conjuntan un concierto audiovisual.
        <br />
        <br />
      </>
    ),
  },
  login: {
    enter: 'ENTRAR',
    name_placholder: 'Nombre',
    days: 'días',
    hours: 'horas',
    minutes: 'minutos',
    seconds: 'segundos',
  },
  controlsSwitches: {
    donations: (<>
      donaciones
      <br />
      {' '}
      a músicxs
    </>),
  },
  chat: {
    send: 'Enviar',
    message: 'mensaje',
  },
  landingInfo: {
    description: 'oxxxo.club es un club de música en la www',
    pastEvents: 'Eventos Anteriores',
  },
  instructions: {
    start: 'COMENZAR',
    next: 'SIGUIENTE',
    controlsTitle: 'CONTROLES',
    chatInstructions: 'ACTIVA EL CHAT PRESIONANDO EL BOTÓN EN LA PARTE SUPERIOR DERECHA',
    controlsInstructions: (
      <>
        PARA MOVERTE USA LAS FLECHAS DEL TECLADO Y LAS TECLAS [W,A,S,D] PARA GIRAR
        <br />
        USA LA BARRA ESPACIADORA PARA SALTAR
      </>
    ),
    mobileControlsInstructions: 'Gira tu celular en modo landscape para activar los controles de movimiento. Regresa a la posición vertical para activar el chat',
  },
};
