import React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Tilt from 'react-tilt';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CardsList from '../../components/CardsList';
import { ReactComponent as Logo } from '../../img/oxxxo-logo.svg';
import radionopalLogo from './img/logos/radionopal_logo.png';
import taalLogo from './img/logos/taal_logo.png';
import conatoLogo from './img/logos/conato.png';
import piranhaLabLogo from './img/logos/piranhaLab.png';
import angelExterminador from './img/artistas/angel-exterminador.jpg';
import babyBruise from './img/artistas/baby-bruise.jpg';
import choloFi from './img/artistas/cholo-fi.jpg';
import coast2c from './img/artistas/coast2c.png';
import cesarCh from './img/artistas/cesar-ch.jpg';
import espectroCaudillo from './img/artistas/espectro-caudillo.jpg';
import hexorcismos from './img/artistas/hexorcismos.jpg';
import impvlso from './img/artistas/impvlso.jpg';
import lauraCoch from './img/artistas/laura-coch.jpg';
import micropapitas from './img/artistas/micropapitas.jpg';
import orbeNacimiento from './img/artistas/orbe-nacimiento.jpg';
import tropicaza from './img/artistas/tropicaza.jpg';
import fordProco from './img/artistas/ford-proco.png';
import pajaroSinAlas from './img/artistas/pajaro-sin-alas.jpg';
// underborders
import musas from './img/artistas/underborders/musas.jpg';
import manitasNerviosas from './img/artistas/underborders/manitas-nerviosas.jpg';
import renickBell from './img/artistas/underborders/renick-bell.jpg';
import safoh from './img/artistas/underborders/safoh.jpg';
import cascadarising from './img/artistas/underborders/cascadarising.jpg';
import cesenriquez from './img/artistas/underborders/cesenriquez.jpg';
import exterminablek from './img/artistas/underborders/exterminablek.png';
import m4l4ndr0n3 from './img/artistas/underborders/m4l4ndr0n3.png';
import './RaveVirtualTransfronterizx.scss';

const underbordersArtistas = [{
  id: 1,
  name: 'Ces Enriquez',
  lugar: 'Colima',
  link: 'https://www.mixcloud.com/c%C3%A9sar-enriquez3',
  picture: cesenriquez,
}, {
  id: 2,
  name: 'Cascadia Rising',
  lugar: 'Guadalajara',
  link: 'https://cascadiarising.bandcamp.com/',
  picture: cascadarising,
}, {
  id: 3,
  name: 'Exterminable K',
  lugar: 'Guadalajara',
  link: 'https://www.youtube.com/user/axelkramble/videos?view_as=subscriber',
  picture: exterminablek,
}, {
  id: 4,
  name: 'Manitas Nerviosas',
  lugar: 'Monterrey',
  link: 'https://soundcloud.com/manitasnerviosas?fbclid=IwAR1hG2Ha4vAoR2aXqPmtJexEE02De55S1MRxuQkz_5n71AHdW6fIKDHZx1E',
  picture: manitasNerviosas,
}, {
  id: 5,
  name: 'Musas Sonideras',
  lugar: 'MX-USA',
  link: 'https://www.facebook.com/musassonideras/posts/2915307128555843',
  picture: musas,
}, {
  id: 6,
  name: 'M4l4ndr0n3',
  lugar: 'CDMX',
  link: 'https://m4l4ndr0n3.github.io/',
  picture: m4l4ndr0n3,
}, {
  id: 7,
  name: 'Renick Bell',
  lugar: 'USA',
  link: 'http://www.renickbell.net/music/',
  picture: renickBell,
}, {
  id: 8,
  name: 'Safoh',
  lugar: 'Oaxaca',
  link: 'https://safohmx.bandcamp.com/',
  picture: safoh,
}];

const artists = [{
  id: 1,
  name: 'Cesar Ch',
  lugar: 'CDMX',
  link: 'https://soundcloud.com/corrupted4ta',
  picture: cesarCh,
}, {
  id: 2,
  name: 'Coast2c',
  lugar: 'Portland',
  link: 'https://coast2c.bandcamp.com/',
  picture: coast2c,
}, {
  id: 3,
  name: 'Cholo-fi',
  lugar: 'San Francisco',
  link: 'https://soundcloud.com/marinero-2/sets/cholo-fi',
  picture: choloFi,
}, {
  id: 4,
  name: 'Espectro Caudillo',
  lugar: 'New York',
  link: 'https://lowers.bandcamp.com/album/lazos-de-familia-low008',
  picture: espectroCaudillo,
}, {
  id: 5,
  name: 'El Angel Exterminador',
  lugar: 'Vancouver',
  link: 'https://www.mixcloud.com/n10as/goo-06022018/',
  picture: angelExterminador,
}, {
  id: 13,
  name: 'Ford Proco',
  lugar: 'Tijuana',
  link: 'http://www.fordproco.com/',
  picture: fordProco,
}, {
  id: 6,
  name: 'ℌEXOℜℭℑSMOS',
  lugar: 'Berlin',
  link: 'https://soundcloud.com/h-e-x-o-r-c-i-s-m-o-s',
  picture: hexorcismos,
}, {
  id: 7,
  name: 'Impvlso',
  lugar: 'CDMX',
  link: 'https://impvlso.bandcamp.com',
  picture: impvlso,
}, {
  id: 8,
  name: 'Laura Coch',
  link: 'https://www.mixcloud.com/lauracoch',
  lugar: 'Tijuana',
  picture: lauraCoch,
}, {
  id: 9,
  name: 'Micropapitas',
  lugar: 'Guadalajara',
  link: 'https://soundcloud.com/pesinasiller',
  picture: micropapitas,
}, {
  id: 10,
  name: 'Orbe/Nacimiento',
  lugar: 'CDMX',
  link: 'https://www.instagram.com/orbe.nacimiento/',
  picture: orbeNacimiento,
}, {
  id: 14,
  name: 'Pájaro sin alas',
  lugar: 'Juárez',
  link: 'https://soundcloud.com/pajaro-sin-alas',
  picture: pajaroSinAlas,
}, {
  id: 11,
  name: 'Syrupflowers b2b Babybruise',
  lugar: 'Toluca',
  link: 'https://soundcloud.com/syrupflowers',
  picture: babyBruise,
}, {
  id: 12,
  name: 'Tropicaza',
  lugar: 'CDMX',
  link: 'https://soundcloud.com/tropicaza',
  picture: tropicaza,
}];

const RaveVirtualTransfronterizx = (props) => {
  const { t } = props;
  { /*
      <img src="https://i.giphy.com/media/l41lRMNcWNbthY6Wc/giphy.gif" style={{width:"100vw", position:"absolute", zIndex:"1"}} />
  */ }
  return (
    <>
      <div className="infoPage">
        <div className="infoPage__header">
          <Tilt className="Tilt infoPage__tilt" options={{ max: 25, perspective: 1000 }}>
            <span className="infoPage__title Tilt-inner">
              RAVE
              <br />
              {' '}
              VIRTUAL
              <br />
              {' '}
              TRANS-
              <br />
              FRONTERIZX
            </span>
            <br />
            <span className="infoPage__date">{t('raveVirtualTransfronterizx.date')}</span>
          </Tilt>
        </div>
        <div style={{ padding: '50px 20px', maxWidth: '800px', margin: 'auto' }}>
          <div style={{ textAlign: 'right', fontSize: '14px', paddingBottom: '50px' }}>
            <i>
              Border culture means to speak from the crevasse, desde acá, desde el medio. The border is the juncture, not the edge, and monoculturalism has been expelled to the margins.
            </i>
            <br />
            - Guillermo Gómez Peña
          </div>

          <div className="infoPage__text">
            {t('raveVirtualTransfronterizx.main_text')}
          </div>
          <h1>{t('raveVirtualTransfronterizx.artists')}</h1>
          <Grid container spacing={3}>
            {artists.map((artist) => (
              <Grid
                key={artist.id}
                item
                xs={6}
                sm={6}
                md={4}
                lg={4}
                xl={3}
              >
                <CardsList
                  key={artist.id}
                  text={artist.name}
                  image={artist.picture}
                  link={artist.link}
                  detail={artist.lugar}
                />
              </Grid>
            ))}
          </Grid>

          <br />
          <br />
          <div className="infoPage__text">
            {t('raveVirtualTransfronterizx.team')}
          </div>

          <div className="infoPage__logos">
            <a
              className="infoPage__logo"
              href="https://www.instagram.com/taal.mx/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={taalLogo} alt="taal.mx logo" />
            </a>
            <a
              className="infoPage__logo"
              href="https://www.instagram.com/la.conato/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={conatoLogo} style={{ maxHeight: '100px' }} alt="conato logo" />
            </a>
            <a
              className="infoPage__logo"
              href="https://www.radionopal.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={radionopalLogo} alt="radionopal logo" />
            </a>
            <a
              className="infoPage__logo"
              href="https://piranhalab.github.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={piranhaLabLogo} alt="Piranha Lab logo" />
            </a>
            <a className="infoPage__logo">
              <div className="login__logoBg">
                <Logo className="login__logo" />
              </div>
            </a>
          </div>

          <br />

          <h1>{t('raveVirtualTransfronterizx.schedule')}</h1>
          <div className="infoPage__horarios">
            <div className="infoPage__horarios__column">
              <h3>{t('raveVirtualTransfronterizx.oxxxoStage')}</h3>
              <ul>
                <li>
                  <span>6pm - 7pm</span>
                  {' '}
                  Pájaro Sin Alas
                </li>
                <li>
                  <span>7pm - 8pm</span>
                  {' '}
                  Tropicaza
                </li>
                <li>
                  <span>8pm - 9pm</span>
                  {' '}
                  Cholo-Fi
                </li>
                <li>
                  <span>9pm - 10pm</span>
                  {' '}
                  Micropapitas
                </li>
                <li>
                  <span>10pm - 11pm</span>
                  {' '}
                  Laura Coch
                </li>
                <li>
                  <span>11pm - 12am</span>
                  {' '}
                  Ford Proco
                </li>
                <li>
                  <span>12am - 1am</span>
                  {' '}
                  Espectro Caudillo
                </li>
                <li>
                  <span>1am - 2am</span>
                  {' '}
                  Hexorcismos
                </li>
              </ul>
            </div>
            <div className="infoPage__horarios__column">
              <h3>{t('raveVirtualTransfronterizx.radionopalStage')}</h3>
              <ul>
                <li>
                  <span>8pm - 9pm</span>
                  {' '}
                  Orbe//Nacimiento
                </li>
                <li>
                  <span>9pm - 10pm</span>
                  {' '}
                  El Ángel Exterminador
                </li>
                <li>
                  <span>10pm - 11pm</span>
                  {' '}
                  Impvlso
                </li>
                <li>
                  <span>11pm - 12am</span>
                  {' '}
                  Coast2c
                </li>
                <li>
                  <span>12am - 1am</span>
                  {' '}
                  Cesar Ch
                </li>
                <li>
                  <span>1am - 3am</span>
                  {' '}
                  Babybruise b2b Syrupflowers
                </li>
              </ul>
            </div>
          </div>

          <br />
          <hr style={{ borderColor: 'gray' }} />
          <br />

          <h1>PiranhaLab</h1>
          <div className="infoPage__text">
            {t('raveVirtualTransfronterizx.piranhaLabDescription')}
          </div>
          <h3>{t('raveVirtualTransfronterizx.artists')}</h3>
          <Grid container spacing={3}>
            {underbordersArtistas.map((artist) => (
              <Grid
                key={artist.id}
                item
                xs={6}
                sm={6}
                md={4}
                lg={4}
                xl={3}
              >
                <CardsList
                  key={artist.id}
                  text={artist.name}
                  image={artist.picture}
                  link={artist.link}
                  detail={artist.lugar}
                />
              </Grid>
            ))}
          </Grid>
          <div className="infoPage__horarios">
            <div className="infoPage__horarios__column">
              <h2>{t('raveVirtualTransfronterizx.schedule')}</h2>
              <ul>
                <li>
                  <span>6:00pm</span>
                  {' '}
                  Exterminable K
                </li>
                <li>
                  <span>6:50pm</span>
                  {' '}
                  m4l4ndr0n3
                </li>
                <li>
                  <span>7:30pm</span>
                  {' '}
                  Musas Sonideras
                </li>
                <li>
                  <span>9:30pm</span>
                  {' '}
                  Cascadia Rising
                </li>
                <li>
                  <span>10:30pm</span>
                  {' '}
                  Ces Enriquez
                </li>
                <li>
                  <span>11:30pm</span>
                  {' '}
                  Manitas Nerviosas
                </li>
                <li>
                  <span>12:20pm</span>
                  {' '}
                  Safoh
                </li>
                <li>
                  <span>1:20pm</span>
                  {' '}
                  Renick Bell
                </li>
              </ul>
            </div>
          </div>
          <br />
          <hr style={{ borderColor: 'gray' }} />
          <br />
          <a href="/">
            <ArrowBackIosIcon />
          </a>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(RaveVirtualTransfronterizx);
