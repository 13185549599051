import * as THREE from "three";
// import { PositionalAudioHelper } from 'three/examples/jsm/helpers/PositionalAudioHelper';

export default class AudioStream {
    constructor(streamUrl){
      this.audioData = [];
      this.stream = streamUrl;
      this.fftSize = 128;
	  //  this.dataArray = this.analyser.data;
	   // this.getAudioData(this.dataArray);
     this.listener = new THREE.AudioListener();
     this.audio = new THREE.PositionalAudio(this.listener);
     this.audio.setRolloffFactor(15);
     this.audio.setDistanceModel("exponential");
     this.audio.setRefDistance(160);
     // this.audio.setMaxDistance(1);

     this.audio.crossOrigin = "anonymous";
     // const helper = new PositionalAudioHelper(this.audio, 1);
     // this.audio.add(helper);

     this.mediaElement = new Audio(this.stream);
     this.mediaElement.crossOrigin = "anonymous";
     this.mediaElement.loop = true;
     this.audio.setMediaElementSource(this.mediaElement);
     this.analyser = new THREE.AudioAnalyser(this.audio, this.fftSize);
     this.analyser.analyser.maxDecibels = -3;
     this.analyser.analyser.minDecibels = -100;
    }

    setDistance = (val) => {
        this.audio.setRefDistance(val);
    }

    play = () => {
    	this.mediaElement.play();
    }

    isPlaying = () => {
      return !this.mediaElement.paused;
    }

    stop = () => {
      this.mediaElement.pause();
    }

    setVolume = (value) => {
      this.audio.volume = value;
      this.mediaElement.volume = value;
      if(this.audio.source){
        this.audio.source.gain.value = value;
      }
    }

    renderFftData = () => {
      // this.getAudioData(this.dataArray);
      // boxes.forEach((particle, i) => (particle.position.y = audioData[0] / 50 - 0.48));
      // balls.forEach((particle, i) => (particle.position.y = audioData[0] / 200 - 0.48));
      this.analyser.getFrequencyData();
    }

    getAudioData = (data) => {
    	// Split array into 3
    	const frequencyArray = this.splitFrenquencyArray(data, 3);
    	// Make average of frenquency array entries
    	for (let i = 0; i < frequencyArray.length; i++) {
    		let average = 0;
    		for (let j = 0; j < frequencyArray[i].length; j++) {
    			average += frequencyArray[i][j];
    		}
    		this.audioData[i] = average / frequencyArray[i].length;
    	}
    	return this.audioData;
    }

    splitFrenquencyArray = (arr, n) => {
    	const tab = Object.keys(arr).map( key => {
    		return arr[key];
    	});
    	const len = tab.length;
    	const result = [];
    	let	i = 0;
    	while (i < len) {
    		const size = Math.ceil((len - i) / n--);
    		result.push(tab.slice(i, i + size));
    		i += size;
    	}
    	return result;
    }

    mapRange = (value, low1, high1, low2, high2) => {
      return low2 + (high2-low2) * (value-low1) / (high1-low1);
    }

}
