import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import ChatIcon from '@material-ui/icons/Chat';
import KurenniemiControls from './KurenniemiControls';
import { shouldMove, shouldNotMove } from '../store/actions';
import './ControlsSwitchesComponent.scss';

const ControlsSwitchesComponent = (props) => {
  const { isMobile, setVolume, toggleShowChat } = props;
  const dispatch = useDispatch();
  const [showVolume, setShowVolume] = useState(false);
  const [volumeVal, setVolumeVal] = useState(1);

  const onVolumeChange = (e) => {
    setVolumeVal(e.target.value);
    setVolume(e.target.value);
  };

  const afterVolumeSet = () => {
    setShowVolume(false);
    dispatch(shouldMove());
  };

  const connectedUsers = useSelector(state => state.connectedUsers);

  const { t } = props;

  return (
    <div className="controlsSwitches">
      <>

        <button
          className="controlsSwitches__button "
          type="submit"
        >
          <span className="controlsSwitches__conected_users">{connectedUsers}</span>
          <span
            className="controlsSwitches__conected_users_icon"
            role="img"
            aria-label="conected users"
          >
            👥
          </span>
        </button>
        <br />

        {!isMobile
        && (
        <button
          className="controlsSwitches__button"
          type="submit"
          onClick={toggleShowChat}
        >
          <ChatIcon />
        </button>
        )}
        <br />
        {showVolume ? (

          <div>
            <label htmlFor="vol-control">
              Volume
              <br />
              <input
                className="vol-control"
                id="vol-control"
                type="range"
                min="0"
                max="1"
                step=".01"
                value={volumeVal}
                onMouseDown={() => dispatch(shouldNotMove())}
                onMouseUp={() => afterVolumeSet()}
                onChange={onVolumeChange}
              />
            </label>
          </div>

        ) : (
          !isMobile
        && (
        <>
          <button
            className="controlsSwitches__button"
            type="submit"
            onClick={() => setShowVolume(!showVolume)}
          >
            <VolumeUpIcon />
          </button>
        </>
        )
        )}
        <KurenniemiControls />
      </>
    </div>
  );
};

export default withTranslation()(ControlsSwitchesComponent);
