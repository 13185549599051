import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LngDetector from 'i18next-browser-languagedetector';
import renderHTML from 'react-render-html';
import en from './en';
import es from './es';

i18n
  .use(LngDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: en,
      },
      es: {
        translations: es,
      },
    },
    fallbackLng: 'en',
    debug: true,
    returnedObjectHandler: (key, value, options) => {
      if ('html' in value) return renderHTML(value.html);
      return value;
    },
    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: '.',
    },
    react: {
      wait: true,
    },
  });

export default i18n;
