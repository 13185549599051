import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Sanity from '../util/sanity';
import loadManager from './loaderManager';

const sanityModelLoader = (nombreDeEspacio, scene) => {
  const fbxLoader = new FBXLoader(loadManager);
  const gltfLoader = new GLTFLoader(loadManager);

  const query = `*[_type == 'modelos' && nombreDeEspacio == '${nombreDeEspacio}' ]
    {
      "modelo": modelo.asset->url,
      escala,
      posicionX,
      posicionY,
      posicionZ,
    }`;

  Sanity.fetch(query)
    .then((models) => {
      models.forEach(({
        modelo, escala = 1, posicionX = 0, posicionY = 0, posicionZ = 0,
      }) => {
        // Check file extension to determine which loader to use
        const extension = modelo.split('.').pop().toLowerCase();
        if (extension === 'fbx') {
          fbxLoader.load(modelo, (loadedModel) => {
            loadedModel.position.set(posicionX, posicionY, posicionZ);
            loadedModel.scale.set(escala, escala, escala);
            scene.add(loadedModel);
          }, undefined, (error) => {
            console.warning(error);
          });
        } else if (extension === 'glb' || extension === 'gltf') {
          gltfLoader.load(modelo, (loadedModel) => {
            loadedModel.scene.position.set(posicionX, posicionY, posicionZ);
            loadedModel.scene.scale.set(escala, escala, escala);
            scene.add(loadedModel.scene);
          }, undefined, (error) => {
            console.warning(error);
          });
        } else {
          console.error('Unsupported file format:', modelo);
        }
      });
    })
    .catch((err) => {
      console.error('Oh no, error occurred: ', err);
    });
};

export default sanityModelLoader;
