import * as THREE from 'three';

const initEspiropapa = (scene) => {
  /*
  const points = [];
    for (let j = 0; j <= Math.PI*2.1; j += (2 * Math.PI) / 50) {
     points.push(Math.cos(j), Math.sin(j), 0);
    }

    const lineMaterial = new MeshLineMaterial({color: new THREE.Color( 0x000000), lineWidth: .2});
    this.interactiveObjects.lineas = [];
      for (let j = 0; j <= 85; j+=1) {
        const line = new MeshLine();
        line.setPoints(points);

        const mesh = new THREE.Mesh(line, lineMaterial);
        mesh.position.set(4+j,1.5+j*.4,-2-(j*2))
        mesh.scale.set(1,(.5*j)+.5,1+j*.5)
        this.scene.add(mesh);
        this.interactiveObjects.lineas.push(mesh)
      }
      */

  const count = 81;
  const geometry = new THREE.TorusBufferGeometry(1, 0.01, 3, 32);
  const material = new THREE.MeshBasicMaterial({
    color: 'black',
  });
  const espiroMesh = new THREE.InstancedMesh(geometry, material, count);
  espiroMesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage);

  const container = new THREE.Group();
  const dummy = new THREE.Object3D();
  const rotations = [];
  for (let j = 0; j < count; j += 1) {
    dummy.position.set(4 + j, 1.5 + j * 0.4, -2 - (j * 2));
    dummy.scale.set(1, (0.5 * j) + 0.5, 1 + j * 0.5);
    rotations[j] = 0;
    espiroMesh.setMatrixAt(j, dummy.matrix);
  }

  container.add(espiroMesh);
  container.position.set(0, 0, 0);
  scene.add(container);

  return () => {
    for (let j = 0; j < count; j += 1) {
      rotations[j] += 0.0001 * (j + 1);
      dummy.rotation.x = rotations[j];
      dummy.position.set(4 + j, 1.5 + j * 0.4, -2 - (j * 2));
      dummy.scale.set(1, (0.5 * j) + 0.5, 1 + j * 0.5);
      dummy.updateMatrix();
      espiroMesh.setMatrixAt(j, dummy.matrix);
    }
    espiroMesh.instanceMatrix.needsUpdate = true;
  };
};

export default initEspiropapa;
