import * as THREE from 'three';

const postersData = [
  {
    url: 'textures/fonima/fonima-senaletica/ventana.svg',
    position: [50, 4.5, -5],
    rotation: [0, 0, 0],
    size: [10, 5],
  },
  {
    url: 'textures/fonima/fonima-senaletica/fonima.png',
    position: [0, 2, 0],
    rotation: [0, 0, 0],
    size: [3, 3],
    animated: true,
  },
  {
    url: 'textures/fonima/gifs-gallery/pabellon.png',
    position: [-436, 1.5, -13],
    rotation: [0, Math.PI / 2, 0],
    size: [4, 1],
  },
  {
    url: 'textures/fonima/fonima-senaletica/senaletica_01_sesiones.png',
    position: [36.5, 3, -30.1],
    rotation: [0, 0.8, 0],
    size: [5, 5],
  },
  {
    url: 'textures/fonima/fonima-senaletica/senaletica_02_talleres.png',
    position: [294, 3, -318],
    rotation: [0, 0, 0],
    size: [6, 6],
  },
  {
    url: 'textures/fonima/fonima-senaletica/senaletica_03_info.png',
    position: [-43.9, 2, 28.649],
    rotation: [0, -2.6, 0],
    size: [5, 5],
  },
  {
    url: 'textures/fonima/fonima-senaletica/senaletica_04_salto.png',
    position: [55.90, 4, 59.71],
    rotation: [0, Math.PI, 0],
    size: [5, 10],
  },
  {
    url: 'textures/fonima/fonima-senaletica/senaletica_05_gif.png',
    position: [-176.43, 3, -3.39626],
    rotation: [0, Math.PI, 0],
    size: [5, 5],
  },
  {
    url: 'textures/fonima/fonima-senaletica/senaletica_06_steve.png',
    position: [-224, 32, -171.5],
    rotation: [0, 0, 0],
    size: [3, 3],
    animated: true,
  },
  {
    url: 'textures/fonima/fonima-senaletica/senaletica_07_lineup.png',
    position: [182.981, 6, -385.341],
    rotation: [0, Math.PI / 3, 0],
    size: [12, 12],
  },
  {
    url: 'textures/fonima/fonima-senaletica/mapa_concierto.jpg',
    position: [110.657, 3, -7.900],
    rotation: [0, -Math.PI / 1.8, 0],
    size: [6, 6],
  },
];

const initPosters = (scene) => {
  const textureLoader = new THREE.TextureLoader();
  const animated = new Set();
  postersData.forEach((item) => {
    const [xPos, yPos, zPos] = item.position;
    const [xRot, yRot, zRot] = item.rotation;
    const [width, height] = item.size;

    textureLoader.load(item.url, (texture) => {
      const geometry = new THREE.PlaneGeometry(width, height);
      const material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true,
      });
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.set(xPos, yPos, zPos);
      mesh.rotation.set(xRot, yRot, zRot);
      scene.add(mesh);
      if (item.animated) animated.add(mesh);
    });
  });

  return () => {
    animated.forEach((item) => {
      item.rotation.y += 0.01;
    });
  };
};

export default initPosters;
