import * as THREE from "three";
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { BloomPass } from 'three/examples/jsm/postprocessing/BloomPass';
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader';
import vertexShader from "./glsl/vertex";
import fragmentShader from "./glsl/fragment";

export default class videoShader {
  constructor(videoTexture, renderer, scene, camera){
    this.renderer = renderer;
    this.scene = scene;
    this.camera = camera;
    const xgrid = 10;
    const ygrid = 5;
    let i;
    let j;
    let ox;
    let oy;
    let geometry;
    this.materials = [];
    this.meshes = [];
    const texture = new THREE.VideoTexture( videoTexture );
            const ux = 1 / xgrid;
            const uy = 1 / ygrid;

            const xsize = 40 / xgrid;
            const ysize = 17 / ygrid;

            const parameters = { color: 0xffffff, map: texture };

            this.cube_count = 0;

            for ( i = 0; i < xgrid; i ++ ) {

              for ( j = 0; j < ygrid; j ++ ) {



                const uniforms = {
                  tOne: { type: "t", value: texture },
                  tSec: { type: "t",value: THREE.TextureLoader( "textures/fonima/fonima.png" )  }
                };

                const materialShh = new THREE.ShaderMaterial({

                  uniforms,
                  vertexShader,
                  fragmentShader,
                  transparent:  true,
                });




                ox = i;
                oy = j;

                geometry = new THREE.BoxBufferGeometry( xsize, ysize, xsize );

                this.change_uvs( geometry, ux, uy, ox, oy );

this.materials[ this.cube_count ] = materialShh;
                // this.materials[ this.cube_count ] = new THREE.MeshLambertMaterial( parameters );

                const material = this.materials[ this.cube_count ];

/*
                material.hue = i / xgrid;
                material.saturation = 1 - j / ygrid;
                material.color.setHSL( material.hue, material.saturation, 0.5 );
*/
                const mesh = new THREE.Mesh( geometry, material );

                mesh.position.x = ( i - xgrid / 2 ) * xsize;
                mesh.position.y = 10 + ( j - ygrid / 2 ) * ysize;
                mesh.position.z = 0;

                mesh.scale.x = 1
                mesh.scale.y = 1
                mesh.scale.z = 1;

                this.scene.add( mesh );

                mesh.dx = 0.001 * ( 0.5 - Math.random() );
                mesh.dy = 0.001 * ( 0.5 - Math.random() );

                this.meshes[ this.cube_count ] = mesh;

                this.cube_count += 1;

              }

            }

            this.renderer.autoClear = false;



            // postprocessing

            const renderModel = new RenderPass( this.scene, this.camera );
            const effectBloom = new BloomPass( 1.3 );
            const effectCopy = new ShaderPass( CopyShader );

            this.composer = new EffectComposer( this.renderer );

            this.composer.addPass( renderModel );
            this.composer.addPass( effectBloom );
            this.composer.addPass( effectCopy );

            this.h = 1;
            this.counter = 1;

  }

  getMesh = () => {

  }

  change_uvs = ( geometry, unitx, unity, offsetx, offsety ) => {

    const uvs = geometry.attributes.uv.array;

    for ( let i = 0; i < uvs.length; i += 2 ) {

      uvs[ i ] = ( uvs[ i ] + offsetx ) * unitx;
      uvs[ i + 1 ] = ( uvs[ i + 1 ] + offsety ) * unity;

    }

  }

  update = () => {
    const time = Date.now() * 0.00005;
    for ( let i = 0; i < this.cube_count; i ++ ) {

  const material = this.materials[ i ];

  const h = ( 360 * ( material.hue + time ) % 360 ) / 360;
  // material.color.setHSL( h, material.saturation, 0.5 );

  }

  if ( this.counter % 1000 > 200 ) {

  for ( let i = 0; i < this.cube_count; i ++ ) {

    const mesh = this.meshes[ i ];

    mesh.rotation.x += 10 * mesh.dx;
    mesh.rotation.y += 10 * mesh.dy;

    mesh.position.x -= 150 * mesh.dx;
    mesh.position.y += 150 * mesh.dy;
    mesh.position.z += 300 * mesh.dx;

  }

  }

  if ( this.counter % 1000 === 0 ) {

  for ( let i = 0; i < this.cube_count; i ++ ) {

    const mesh = this.meshes[ i ];

    mesh.dx *= - 1;
    mesh.dy *= - 1;

  }


  }


  this.counter++;

  this.renderer.clear();
  this.composer.render();
  }

}
