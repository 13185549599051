import fonimaConfig from './fonima/config';
import matazanosConfig from './matazanos/config';
import Kurenniemi from './Kurenniemi/config';
import cumpleConfig from './cumple/config';
import ceremoniaConfig from './ceremonia/config';
import modelsTestSpaceThree from './modelsTestSpaceThree/config';
import modelsTestSpaceTwo from './modelsTestSpaceTwo/config';
import modelsTestSpace from './modelsTestSpace/config';
import virtualSpaceTest from './virtualSpaceTest/config';

const virtualSpacesIndex = [{
  path: '/fonima',
  config: fonimaConfig,
},
{
  path: '/matazanos',
  config: matazanosConfig,
},
{
  path: '/cumple',
  config: cumpleConfig,
},
{
  path: '/ceremonia',
  config: ceremoniaConfig,
},
{
  path: '/caja3',
  config: modelsTestSpaceThree,
},
{
  path: '/caja2',
  config: modelsTestSpaceTwo,
},
{
  path: '/caja1',
  config: modelsTestSpace,
},
{
  path: '/virtualSpaceTest',
  config: virtualSpaceTest,
},
{
  path: '/',
  config: Kurenniemi,
},
];

export default virtualSpacesIndex;
