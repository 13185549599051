import * as THREE from "three";
import TWEEN from '@tweenjs/tween.js';
import * as CANNON from 'cannon-es';
import Avatar from '../Avatar/Avatar';
import FirebaseMultiplayerSync from './FirebaseMultiplayerSync';

export default class Multiplayer {
    constructor(scene, physicsWorld, groundMaterial, uuid){
        this.scene = scene;
        this.physicsWorld = physicsWorld;
        this.avatarMaterial = groundMaterial;
        this.players = {};
        this.users = {};
        this.animationMixers = {}
        this.username = null;
        this.countryFlag = null;
        this.myID = uuid;
        this.remoteConnection = new FirebaseMultiplayerSync(this.players, this.myID);
        this.initListeners();
    }

    initListeners = () => {
      this.remoteConnection.setAddPlayer(this.addPlayer);
      this.remoteConnection.setRemovePlayer(this.removePlayer);
      this.remoteConnection.setUpdatePositions(this.updatePositions);
      this.remoteConnection.initListeners();
    }

    addPlayer = async (newUser) => {
      if (newUser.id === this.myID) return;
      if(this.users[newUser.id]) return;
    	this.users[newUser.id] = newUser.data;
      const { position, username, countryFlag, avatar } = this.users[newUser.id];
      const newAvatar = await new Avatar(avatar, position, this.avatarMaterial, 50);
      this.players[newUser.id] = {
              object3D: new THREE.Object3D(),
              avatar: newAvatar,
              physicsBody: newAvatar.getPhysicsBody()
      }
      this.players[newUser.id].avatar.createName(username, countryFlag);
      this.players[newUser.id].object3D.position.copy(this.players[newUser.id].physicsBody.position);
      this.players[newUser.id].object3D.add(this.players[newUser.id].avatar.getModelMesh());
      this.players[newUser.id].object3D.add(this.players[newUser.id].avatar.getName());
      //this.physicsWorld.addBody(this.players[newUser.id].physicsBody);

      this.scene.add(this.players[newUser.id].object3D);
    }

    removePlayer = (userID) => {
      if (!this.players[userID]) return;
    	this.scene.remove(this.players[userID].object3D);
    	this.physicsWorld.removeBody(this.players[userID].physicsBody );
    	delete this.players[userID];
    	delete this.users[userID];
    	delete this.animationMixers[userID];
    }

    updatePositions = (user) => {
      if(!this.players[user.id]) return;
      if(user.id === this.myID) return;

      try{
        const [x,y,z] = user.data.position;
        const newPosition = new CANNON.Vec3(x,y,z);
        const currentPositionPhysics = this.players[user.id].object3D.position;
        // TODO correctly send postion
        // const minY = newPosition.y < 1 ? currentPositionPhysics.y : newPosition.y;
        const minY = newPosition.y;
        const targetPhysics = this.players[user.id].object3D
          .position.clone().set(newPosition.x, minY, newPosition.z);

        if(Math.abs(currentPositionPhysics.x-newPosition.x)>0.2 ||
           Math.abs(currentPositionPhysics.z-newPosition.z)>0.2 ||
           Math.abs(currentPositionPhysics.y-minY)>0.5) {
            new TWEEN.Tween(currentPositionPhysics)
              .onStart(() => {
                this.players[user.id].avatar.walk();
              })
            .to(targetPhysics, 1000)
            .onComplete(() => {
              this.players[user.id].avatar.stopWalking();
            })
            .start();
        } else {
          this.players[user.id].avatar.stopWalking();
        }

        const newRotation = user.data.rotation;
        const currentRotation = this.players[user.id].object3D.rotation;
      	new TWEEN.Tween(currentRotation)
          .to({x: newRotation._x, y: newRotation._y, z: newRotation._z} , 500)
          .onUpdate(()=>{
              /*
              if(!this.players[user.id]) return;

              this.players[user.id].physicsBody.quaternion.copy(
                  this.players[user.id].object3D.quaternion
              );
              */
          })
          .start();
        }
        catch(err){
          TWEEN.removeAll();
          console.log(err);
        }
    }

    emitMoveAvatar = (position, rotation) => {
        this.remoteConnection.emitMoveAvatar(position, rotation, this.myID);
    }

    getPlayers = () => {
        return this.players;
    }
}
