import React from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CardsList from './CardsList';
import fonima from '../img/landingInfo/fonima.svg';
import matazanos from '../img/landingInfo/matazanos.jpg';
import cumple from '../img/landingInfo/cumple.jpg';
import ceremonia from '../img/landingInfo/ceremonia.jpg';
import './LandingInfo.scss';

const pastEvents = [
  {
    id: 1,
    name: 'Cumple Pesina',
    date: '18 abril 2020',
    link: '/cumple',
    picture: cumple,
    linkTarget: '_self',
  }, {
    id: 2,
    name: 'Festival Ceremonia',
    date: '25 abril 2020',
    link: '/ceremonia',
    picture: ceremonia,
    linkTarget: '_self',
  }, {
    id: 3,
    name: 'Matazanos',
    date: '4 julio 2020',
    link: '/matazanos',
    picture: matazanos,
    linkTarget: '_self',
  },
  {
    id: 4,
    name: 'FONIMA',
    link: '/fonima',
    date: '28 nov 2020',
    picture: fonima,
    linkTarget: '_self',
  },
];

const LandingInfo = (props) => {
  const { t } = props;
  return (
    <section className="landingInfo landingInfo--fonima" id="events">
      <div style={{ textAlign: 'center' }}>
        <span className="infoPage__text--rave">
          {t('landingInfo.description')}
        </span>
      </div>
      <br />
      <br />
      <br />
      <h1>{t('landingInfo.pastEvents')}</h1>
      <Grid container spacing={3}>
        {pastEvents.map((pastEvent) => (
          <Grid
            key={pastEvent.id}
            item
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={3}
          >
            <CardsList
              key={pastEvent.id}
              text={pastEvent.name}
              image={pastEvent.picture}
              link={pastEvent.link}
              detail={pastEvent.date}
              target={pastEvent.linkTarget}
            />
          </Grid>
        ))}
      </Grid>
    </section>
  );
};

export default withTranslation()(LandingInfo);
