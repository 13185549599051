import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ReactGA from 'react-ga';
import { I18nextProvider } from 'react-i18next';
import store from './store/store';
import i18n from './locale/i18n';
import App from './App';
import RaveVirtualTransfronterizx from './pages/RaveVirtualTransfronterizx/index';
import Fonima from './pages/fonima/index';
import virtualSpacesIndex from './VirtualSpaces';
import Instructions from './pages/instructions/index';

console.log('%c oxxxo.club ',
  `font-weight: bold;
font-size: 50px;
color: #FF4000;
text-shadow: 3px 3px 0 #0D0106 ,
6px 6px 0 #3626A7 ,
9px 9px 0 #657ED4 ,
12px 12px 0 #FF331F ,
15px 15px 0 #50B2C0 ,
18px 18px 0 rgb(40,127,75) ,
21px 21px 0 rgb(42,21,113)`);
console.log('%c /* \n‍👨🏽‍💻 https://pesinasiller.github.io\n */',
  'font-size: 15px; color: blue;');

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
ReactGA.pageview('/homepage');

const MainRouter = () => {
  const uuid = uuidv4();

  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/rave_virtual_transfronterizxxx"
              render={() => <RaveVirtualTransfronterizx />}
            />
            <Route
              exact
              path="/instrucciones"
              render={() => <Instructions />}
            />
            <Route
              exact
              path="/fonima/info"
              render={() => <Fonima />}
            />

            {virtualSpacesIndex.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                component={({ location }) => (
                  <App
                    urlQuery={location.search}
                    settings={item.config}
                    uuid={uuid}
                  />
                )}
              />
            ))}
          </Switch>
        </BrowserRouter>
      </Provider>
    </I18nextProvider>
  );
};

const rootElement = document.getElementById('root');
ReactDOM.render((<MainRouter />), rootElement);
