import React from 'react';

export default {
  infoPage: {
    artists: 'Artists',
    schedule: 'Schedule',
  },
  raveVirtualTransfronterizx: {
    main_text: (
      <>
        <span className="infoPage__text--negritas">𝕍𝕚𝕣𝕥𝕦𝕒𝕝 𝕋𝕣𝕒𝕟𝕤𝕗𝕣𝕠𝕟𝕥𝕖𝕣𝕚𝕫𝕩 ℝ𝕒𝕧𝕖</span>
        {' '}
        is a symbolic event that aims to show how music and the internet can create communities despite the distance and physical barriers that separate us.
        <br />
        <br />
        Two scenarios in this virtual world will be divided by a digital representation of the Mexican-American border. On each side, electronic musicians from both countries will play live simultaneously for an audience that will also be placed on either side of the fence, depending on the location of their internet connection. Through an avatar, users will be able to explore the space to find a way to cross to the other stage.
        <br />
        <br />
        Inspired by the fandango fronterizo and devised as a release party of the first vinyl record from the Mexican-American duo
        {' '}
        <a href="http://franciscoymadero.bandcamp.com/" target="_blank" rel="noopener noreferrer">Francisco y Madero</a>
        , the
        {' '}
        <span className="infoPage__text--negritas">𝕍𝕚𝕣𝕥𝕦𝕒𝕝 𝕋𝕣𝕒𝕟𝕤𝕗𝕣𝕠𝕟𝕥𝕖𝕣𝕚𝕫𝕩 ℝ𝕒𝕧𝕖</span>
        {' '}
        will take place on Saturday, August 15 through
        {' '}
        <span className="infoPage__text--rave">oxxxo.club</span>
        {' '}
        at 6:00 pm to 3:00 am (Mexico City time, GMT-5).
        <br />
        <br />
        <span className="infoPage__text--rave">oxxxo.club</span>
        {' '}
        is a non-profit platform accessible from any mobile or desktop device and where other events have previously taken place, such as a virtual illustration exhibition with the Matazanos collective and a virtual stage for the music festival Ceremonia 2020 from Mexico City. You can make monetary donations through the site, 80% of which will go to artists, the rest will be donated to the
        <i>DESERT WATER DROPS</i>
        {' '}
        service provided by
        <a href="https://www.borderangels.org" target="_blank" rel="noopener noreferrer">borderangels.org</a>
        , this service places water bottles in the desert to be found by migrants during their travels.
      </>
    ),
    team: (<>
      The virtual world was made in collaboration with
      {' '}
      <a href="https://www.instagram.com/taal.mx/" target="_blank" rel="noopener noreferrer">TAAL</a>
      , an architectural studio that designs spaces that enable effective forms of participation and social coexistence for urban development. Programming and logistics are managed by
      <a href="https://www.instagram.com/la.conato/" target="_blank" rel="noopener noreferrer">CONATO</a>
      , a platform that seeks to strengthen local sound production based on a community infrastructure.
    </>),
    date: 'august/15/2020',
    oxxxoStage: (<>
      OXXXO
      <span>stage</span>
                 </>),
    radionopalStage: (<>
      CONATO x Radio Nopal
      <span>stage</span>
                      </>),
    piranhaLabDescription: (<>
      <a href="http://piranhalab.cc" target="_blank" rel="noopener noreferrer">PiranhaLab</a>
      {' '}
      joins the
      <span className="infoPage__text--negritas">𝕍𝕚𝕣𝕥𝕦𝕒𝕝 𝕋𝕣𝕒𝕟𝕤𝕗𝕣𝕠𝕟𝕥𝕖𝕣𝕚𝕫𝕩 ℝ𝕒𝕧𝕖</span>
      {' '}
      by creating its own stage within its
      <a href="http://piranhalab.cc" target="_blank" rel="noopener noreferrer">Underborders</a>
      {' '}
      platform, which, making an analogy to a tunnel, serves as a link between the two stages of
      <span className="infoPage__text--rave">oxxxo.club</span>
      .
    </>),
  },
  fonima: {
    main_text: (
      <>
        <span className="infoPage__text--negritas">FONIMA</span>
        {' '}
        is an event that brings together musicians, animators and vj’s through a musical improvisation session accompanied by animated gifs that combine into an audiovisual concert.
        <br />
        <br />
      </>
    ),
  },
  login: {
    enter: 'ENTER',
    name_placholder: 'Name',
    days: 'days',
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds',
  },
  controlsSwitches: {
    donations: (<>
      support
      <br />
      {' '}
      the musicians
    </>),
  },
  chat: {
    send: 'Send',
    message: 'message',
  },
  landingInfo: {
    description: 'oxxxo.club is an online virtual music club',
    pastEvents: 'Past Events',
  },
  instructions: {
    start: 'START',
    next: 'NEXT',
    controlsTitle: 'CONTROLS',
    chatInstructions: 'TO ACTIVATE THE CHAT, PRESS THE BUTTON IN THE UPPER RIGHT CORNER',
    controlsInstructions: (
      <>
        USE THE ARROW KEYS TO MOVE AND [W,A,S,D] TO ROTATE
        <br />
        USE THE SPACE BAR TO JUMP
      </>
    ),
    mobileControlsInstructions: 'ROTATE YOUR DEVICE 90 DEGREES TO SEE THE CONTROLS. GO BACK TO PORTRAIT MODE TO SEE THE CHAT.',
  },
};
