/*
By Arno Di Nunzio in Tutorials on February 11, 2020
https://tympanus.net/codrops/2020/02/11/how-to-create-a-physics-based-3d-cloth-with-cannon-js-and-three-js/
*/
import SimplexNoise from "simplex-noise";
import { Clock, Vector3 } from "three";

const noise = new SimplexNoise();
const baseForce = 400;
const off = .05;

export default class Wind {
  constructor(flag) {
    const { count } = flag.geometry.attributes.position;
    this.flag = flag;
    this.force = baseForce / count;
    this.clock = new Clock();
    this.direction = new Vector3(0.5, 0, -1);
    this.flowfield = new Array(count);
    this.update();
  }

  update() {
    const time = this.clock.getElapsedTime();
    const { position } = this.flag.geometry.attributes;
    const size = this.flag.geometry.parameters.widthSegments;
    for (let i = 0; i < position.count; i+=1) {
      const col = i % (size + 1);
      const row = Math.floor(i / (size + 1));
      const force =
        (noise.noise3D(row * off, col * off, time) * 0.5 + 0.5) * this.force;
      this.flowfield[i] = this.direction.clone().multiplyScalar(force);
    }
  }
}
