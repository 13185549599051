import React from 'react';
import store from '../store/store';
import './AvatarSelector.scss';

const AvatarSelector = ({ selectedAvatar, setSelectedAvatar }) => {
  const { config } = store.getState();
  const { avatars } = config;

  const avatarButtons = avatars.map(({ avatarSelectorImagePath, id }) => (
    <button key={id} onClick={() => setSelectedAvatar(id)} type="button">
      <img
        className={`
    avatarSelector__image
    ${selectedAvatar === id ? 'avatarSelector__image-selected' : ''}
    `}
        src={avatarSelectorImagePath}
        alt={`avatar ${id}`}
      />
    </button>
  ));
  return (
    <div className="avatarSelector">
      {avatarButtons}
    </div>
  );
};

export default AvatarSelector;
