import * as THREE from "three";
import * as CANNON from 'cannon-es';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';
import VirtualSpaceInterface from '../../../oxxxo/VirtualSpaceInterface';
import Raycaster from '../../../three-utils/Raycaster';
// import { importedModels } from '../classes/modelsLoader';
// import Sanity from "./sanity/sanityClient";
import createText from "../../../three-utils/createText";
import GifAnimation from "../../../three-utils/GifAnimation";
import ExplodeAnimation from "../../../three-utils/ExplodeAnimation";
import Bajapodcast from "../../../three-utils/flag/textures/ezgif.com-gif-maker.png";

const ilustradores = [
  {
    nombre: "atopica",
    link: "https://www.instagram.com/atopica_editorial"
  },
  {
    nombre: "benitez",
    link: "https://www.instagram.com/jesusbenitezf"
  },
  {
    nombre: "buentrabajo",
    link: "https://www.instagram.com/buen.trabajo"
  },
  {
    nombre: "chacho",
    link: "https://www.instagram.com/destierrografica"
  },
  {
    nombre: "chula",
    link: "https://www.instagram.com/chularecords"
  },
  {
    nombre: "fabuloso",
    link: "https://www.instagram.com/fabuloso_darks"
  },
  {
    nombre: "fauna",
    link: "https://www.instagram.com/editorialfaunanociva"
  },
  {
    nombre: "frosh",
    link: "https://www.instagram.com/frossshhh"
  },
  {
    nombre: "fuck",
    link: "https://www.instagram.com/fuckzines"
  },
  {
    nombre: "gibran",
    link: "https://www.instagram.com/gibranturon"
  },
  {
    nombre: "hungria",
    link: "https://www.instagram.com/edicioneshungria"
  },
  {
    nombre: "magana",
    link: "https://www.instagram.com/mariamagania"
  },
  {
    nombre: "molusculo",
    link: "https://www.instagram.com/elmolusculo"
  },
  {
    nombre: "mono",
    link: "https://www.instagram.com/monoebrioed"
  },
  {
    nombre: "pachi",
    link: "https://www.instagram.com/pachiclon"
  },
  {
    nombre: "panela",
    link: "https://www.instagram.com/panelismo"
  },
  {
    nombre: "payaso",
    link: "https://www.instagram.com/payasito_ediciones"
  },
  {
    nombre: "rabia",
    link: "https://www.instagram.com/rabia15410"
  },
  {
    nombre: "vegan",
    link: "https://www.instagram.com/vegan_canibal"
  },
  {
    nombre: "zini",
    link: "https://www.instagram.com/zinibooks"
  }
];

const ilustraciones = [
  {
    nombre: "adri1",
    link: "https://www.instagram.com/adrianacassianodibuja/"
  },
  {
    nombre: "adri2",
    link: "https://www.instagram.com/adrianacassianodibuja"
  },
  {
    nombre: "adri3",
    link: "https://www.instagram.com/adrianacassianodibuja"
  },
  {
    nombre: "adrian1",
    link: "https://www.instagram.com/adrian_regnier/"
  },
  {
    nombre: "adrian2",
    link: "https://www.instagram.com/adrian_regnier"
  },
  {
    nombre: "adrian3",
    link: "https://www.instagram.com/adrian_regnierr"
  },
  {
    nombre: "ed1",
    link: "https://www.instagram.com/eddspecialagent"
  },
  {
    nombre: "ed2",
    link: "https://www.instagram.com/eddspecialagent"
  },
  {
    nombre: "ed3",
    link: "https://www.instagram.com/eddspecialagent"
  },
  {
    nombre: "marco1",
    link: "https://www.instagram.com/marco__caballero"
  },
  {
    nombre: "marco2",
    link: "https://www.instagram.com/marco__caballero"
  },
  {
    nombre: "moni1",
    link: "https://www.instagram.com/monicaquant"
  },
  {
    nombre: "moni2",
    link: "https://www.instagram.com/monicaquant"
  },
  {
    nombre: "pepe1",
    link: "https://www.instagram.com/ppkmkzztt"
  },
  {
    nombre: "pepe2",
    link: "https://www.instagram.com/ppkmkzztt"
  },
  {
    nombre: "tamara1",
    link: "https://www.instagram.com/cumbiasborrascosas"
  },
  {
    nombre: "tamara2",
    link: "https://www.instagram.com/cumbiasborrascosas"
  },
  {
    nombre: "urba1",
    link: "https://www.instagram.com/urbano.mata"
  },
  {
    nombre: "urba2",
    link: "https://www.instagram.com/urbano.mata"
  },
  {
    nombre: "urba3",
    link: "https://www.instagram.com/urbano.mata"
  },
  {
    nombre: "yoce1",
    link: "https://www.instagram.com/jocelyn_samayoa_"
  },
  {
    nombre: "yoce2",
    link: "https://www.instagram.com/jocelyn_samayoa_"
  },
  {
    nombre: "payaso1",
    link: "http://www.omfgdogs.com/"
  },
  {
    nombre: "yakult1",
    link: "https://www.instagram.com/35k_yakult/"
  },
  {
    nombre: "yakult2",
    link: "https://www.instagram.com/35k_yakult/"
  },
  {
    nombre: "simi",
    link: "https://www.youtube.com/watch?v=XaCBuYD_Y20"
  },
  {
    nombre: "kemonito",
    link: "https://www.youtube.com/watch?v=gFampquURRg"
  },
  {
    nombre: "churi",
    link: "https://www.instagram.com/monoschuridos_sa_de_cv/"
  },
  {
    nombre: "anahi1",
    link: "http://salmonofcapistrano.com/"
  },
  {
    nombre: "chula1",
    link: "https://cat-bounce.com/"
  },
];

const extras = [
  {
    nombre: "siempreeshoy",
    link: "https://www.instagram.com/vacacionestrabajo",
    x: 70, y: 3, z: 30,
    height: 3, width: 6
  },
  {
    nombre: "acab",
    link: "https://www.instagram.com/gatitoscontraladesigualdad/",
    x: 70, y: 2, z: -4,
    height: 8, width: 4
  },
  {
    nombre: "basura",
    link: "http://www.eelslap.com",
    x: 10, y: 1, z: -20,
    height: 3, width: 2
  },
  {
    nombre: "cables",
    link: "https://www.youtube.com/watch?v=cd0PV82e1jE",
    x: 7, y: 4, z: -12,
    height: 30, width: 10
  },
  {
    nombre: "cables",
    link: "https://www.youtube.com/watch?v=sD9_KUBLyHo",
    x: 100, y: 4, z: -100,
    height: 30, width: 10
  },
  {
    nombre: "dino",
    link: "https://www.youtube.com/results?search_query=burutabu+chan+",
    x: 52, y: 2, z: -55,
    height: 8, width: 4
  },
  {
    nombre: "gato",
    link: "https://cat-bounce.com",
    x: 10, y: 1, z: -40,
    height: 2, width: 2
  },
  {
    nombre: "pared",
    link: "https://www.youtube.com/watch?v=-HJwbxVe8Rg",
    x: 100, y: 2, z: 8,
    height: 12, width: 6
  },
  {
    nombre: "pared",
    link: "https://www.youtube.com/watch?v=-HJwbxVe8Rg",
    x: 30, y: 2, z: -80,
    height: 12, width: 6
  }
]

class VirtualSpace extends VirtualSpaceInterface {

  createSpace = () => {
    const fbxLoader = new FBXLoader();

    // instructions
    const instructions = createText(
      `Haz doble click en las ilustraciones\n
      para ir al sitio de lxs ilustradorxs`,
      0xa61469, .2);
    instructions.position.set(50, 3, -4);
    this.scene.add(instructions);

    const light = new THREE.AmbientLight(0x303030);
    this.scene.add(light);

    // pantalla matazanos
    const screenWidth = 5;
    const screenHeight = 2;
    const blueRectLightCeremonia = new THREE.RectAreaLight(0xf3aaaa, 0, screenWidth, screenHeight);
    blueRectLightCeremonia.position.set(45, 5, -20);
    blueRectLightCeremonia.rotation.set(0, Math.PI, 0);

    this.scene.add(blueRectLightCeremonia);
    const blueRectLightCeremoniaHelper = new RectAreaLightHelper(blueRectLightCeremonia, 0xffffff);
    blueRectLightCeremonia.add(blueRectLightCeremoniaHelper);
    blueRectLightCeremoniaHelper.color = new THREE.Color(1, 0, 0);
    blueRectLightCeremoniaHelper.update();
    this.interactiveObjects.blueRectLightCeremonia = blueRectLightCeremonia;
    this.interactiveObjects.blueRectLightCeremoniaHelper = blueRectLightCeremoniaHelper;

    const display1 = new THREE.Group();
    display1.position.set(45.1, 5, -19.9);
    this.interactiveObjects.display1 = display1;
    this.scene.add(display1);

    const sphere = new THREE.SphereGeometry(.01, 16, 8);
		const light1 = new THREE.PointLight(0xff0000, 1, 20);
		light1.add(new THREE.Mesh(sphere, new THREE.MeshPhongMaterial( {
		  color: 0xff0000,
		  emissive:0xff0000,
		  specular: 0xff0000,
		  emissiveIntensity: 10,
		  shininess: 500})
    ));
		light1.castShadow = true;
    light1.position.set(45, 1.3, -20);
    this.interactiveObjects.luzEscenarioMatazanos = light1;
    this.scene.add(light1);

    // pantalla oxxxo
		const blueRectLightOxxxo = new THREE.RectAreaLight(0xf3aaaa, 0, screenWidth, screenHeight);
		blueRectLightOxxxo.position.set(2, 3.5, 7.5);
		blueRectLightOxxxo.rotation.set(0, Math.PI*1.5, 0);

		this.scene.add(blueRectLightOxxxo);
		const blueRectLightOxxxoHelper = new RectAreaLightHelper(blueRectLightOxxxo, 0xffffff);
		blueRectLightOxxxo.add(blueRectLightOxxxoHelper);
    blueRectLightOxxxoHelper.color = new THREE.Color(1, 0, 0);
    blueRectLightOxxxoHelper.update();
    this.interactiveObjects.blueRectLightOxxxo = blueRectLightOxxxo;
    this.interactiveObjects.blueRectLightOxxxoHelper = blueRectLightOxxxoHelper;

    const display2 = new THREE.Group();
    display2.position.set(2.1, 3.5, 7.5);
    display2.rotation.set(0, Math.PI/2, 0);
    this.interactiveObjects.display2 = display2;
    this.scene.add(display2);

		const light2 = new THREE.PointLight(0x55009f, 1, 20);
		light2.add(new THREE.Mesh(sphere, new THREE.MeshPhongMaterial( {
		  color: 0x55009f,
		  emissive:0xff0000,
		  specular: 0xff0000,
		  emissiveIntensity: 10,
		  shininess: 500})
    ));
		light2.castShadow = true;
    light2.position.set(2, 1.3, 7.5);
    this.interactiveObjects.luzEscenarioOxxxo = light2;
    this.scene.add(light2);


    this.textureLoader.load("textures/matazanos/suelo.jpg", (texture) => {
      const groundTexture = texture;
			groundTexture.wrapS = THREE.RepeatWrapping;
      groundTexture.wrapT = THREE.RepeatWrapping;
      groundTexture.encoding = THREE.sRGBEncoding;
			groundTexture.repeat.set(250,250);
      groundTexture.anisotropy = 8;

      const groundMaterialz = new THREE.MeshPhongMaterial({ map: groundTexture });
      const mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(1000, 1000), groundMaterialz);
      mesh.receiveShadow = true;
      mesh.rotation.x = - Math.PI / 2;
      this.scene.add(mesh);
  	});


    fbxLoader.load("models/matazanos/harte.fbx", (loadedModel) => {
      loadedModel.position.set(0,0,0);
      loadedModel.scale.set(1,1,1);
      this.scene.add(loadedModel);
    }, undefined,  (error) => {
      console.error(error);
    });

    let centerX = -45;
    let centerY = -20;
    let radius = 15;
    let items = ilustradores.length;
    let i = 0;

    ilustradores.map((ilustrador) => {
      const x = centerX + radius * Math.sin(2 * Math.PI * i / items);
      const z = centerY + radius * Math.cos(2 * Math.PI * i / items);

      this.createOrtoedro(
        `textures/matazanos/mesas/${ilustrador.nombre}`,
        ilustrador.link,
        new THREE.Vector3(-x, 0.5, z),
        new THREE.Vector3(0, Math.sin(4* Math.PI * i / items), 0)
      );
      i+=1;
    });

    centerX = 100;
    centerY = -58;
    radius = 20;
    items = ilustraciones.length;
    i = 0;

    ilustraciones.map((ilustrador) => {
      const x = centerX + radius * Math.sin(2 * Math.PI * i / items);
      const z = centerY + radius * Math.cos(2 * Math.PI * i / items);

      this.createPlaneLink(
        `textures/matazanos/ilustradores/${ilustrador.nombre}.png`,
        ilustrador.link,
        new THREE.Vector3(x,2,z),
        {height:4, width: 4}
      );
      i+=1;
    });

    extras.map((extra) => {
      this.createPlaneLink(
        `textures/matazanos/props/${extra.nombre}.png`,
        extra.link ?? undefined,
        new THREE.Vector3(extra.x,extra.y,extra.z),
        {height:extra.height, width: extra.width}
      );
    });

    const light4 = new THREE.PointLight(0xFF6060, 10, 7);
    light4.add(new THREE.Mesh(sphere, new THREE.MeshPhongMaterial( {
      color: 0xff0000,
      emissive:0xff0000,
      specular: 0xff0000,
      emissiveIntensity: 10,
      shininess: 500})
    ));
    light4.castShadow = true;
    light4.position.set(70, 2, -4);
    this.scene.add(light4);

    this.createOrtoedro(
      "textures/matazanos/oxxxo/oxxxo",
      "https://www.instagram.com/_matazanos_/",
      new THREE.Vector3(1, 5, 1),
      new THREE.Vector3(0, 0, 0),
      25,15,
      false
    );

    this.createOrtoedro(
      "textures/matazanos/oxxxo/oxxxo_interior",
      "https://www.instagram.com/_matazanos_/",
      new THREE.Vector3(1, 5, 1.1),
      new THREE.Vector3(0, 0, 0),
      24.7,14.7,
      false
    );

    this.createOrtoedro(
      "textures/matazanos/ricosjugos/afuera",
      "https://www.instagram.com/ricosjugos",
      new THREE.Vector3(70, 5, 20),
      new THREE.Vector3(Math.PI, 0, 0),
      20,10,
      false,
      2
    );

    this.createOrtoedro(
      "textures/matazanos/ricosjugos/interiores",
      "https://www.instagram.com/ricosjugos",
      new THREE.Vector3(70, 5, 20.1),
      new THREE.Vector3(Math.PI, 0, 0),
      19.7,9.7,
      false,
      2
    );

    const light3 = new THREE.PointLight(0x905090, 3, 15);
    light3.add(new THREE.Mesh(sphere, new THREE.MeshPhongMaterial( {
      color: 0xff0000,
      emissive:0xff0000,
      specular: 0xff0000,
      emissiveIntensity: 10,
      shininess: 500})
    ));
    light3.position.set(70, 3, 30);
    this.scene.add(light3);

    for (let step = 1; step<50; step+=1){
        const anchura = 2;
        const profundidad = .5;
        const altura = .2;
        const posX = 45;
        const posY = 0;
        const posZ = -70;

        const escalera = new CANNON.Box(new CANNON.Vec3(anchura, altura, profundidad))
        const escaleraBody = new CANNON.Body({mass: 0, shape: escalera});
        escaleraBody.position.set(
            posX + 0,
            posY + (altura*2*step),
            posZ + (profundidad * 2 * step));
        this.physicsWorld.addBody(escaleraBody);
        const color = new THREE.Color(`rgb(${255-(step*3)},${0},${step*3})`);
        const geometry = new THREE.BoxGeometry(anchura*2, altura*2, profundidad*2);
        const mesh = new THREE.Mesh(geometry, new THREE.MeshPhongMaterial({color}));
        mesh.position.set(
            posX + 0,
            posY + (altura*2*step),
            posZ + (profundidad * 2 * step));
        this.scene.add(mesh);
    }

    const textureLoader = new THREE.TextureLoader();
    textureLoader.load('textures/matazanos/domo/frosh_panoramica.png', (texture) => {
      // texture.mapping = THREE.UVMapping;
      const geometry = new THREE.CylinderBufferGeometry(10, 10, 10, 32, undefined, true);

      // const geometry = new THREE.SphereBufferGeometry(10, 32, 16)
      const fondo = new THREE.Mesh(geometry,
                              new THREE.MeshPhongMaterial({ map: texture }));
      fondo.material.lightMapIntensity = .02;
      fondo.material.side = THREE.DoubleSide;
      fondo.position.set(45, 4, -20);
      this.interactiveObjects.ThreeSixtyBackground = fondo;
      this.scene.add(fondo);
    });

    textureLoader.load('textures/matazanos/cielo.jpg', (texture) => {
      // texture.mapping = THREE.UVMapping;
      const geometry = new THREE.SphereGeometry(700, 4, 4);
      const cielo = new THREE.Mesh(geometry,
                              new THREE.MeshPhongMaterial({ map: texture }));
      cielo.material.lightMapIntensity = .02;
      cielo.material.side = THREE.DoubleSide;
      cielo.position.set(0,0,0);
      this.interactiveObjects.sky = cielo;
      this.scene.add(cielo);
    });

    this.gif = new GifAnimation(
      Bajapodcast,
      new THREE.Vector3(32,2,-55),
      new THREE.Vector3(0, 0, 0),
      [2,2],
      [5, 6, 49, 15]
    );

    this.scene.add(this.gif.getMesh());

    /************* physics ************/
    const mass = 0;

    // galeria
    let shape = new CANNON.Sphere(22);
    let body = new CANNON.Body({mass, shape});
    body.position.set(102,19,-56);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 19));
    body = new CANNON.Body({mass, shape});
    body.position.set(70,2,-58);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 19));
    body = new CANNON.Body({mass, shape});
    body.position.set(134,2,-58);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(19, 7, 1));
    body = new CANNON.Body({mass, shape});
    body.position.set(100,2,-25);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(19, 7, 1));
    body = new CANNON.Body({mass, shape});
    body.position.set(100,2,-90);
    this.physicsWorld.addBody(body);


    shape = new CANNON.Box(new CANNON.Vec3(6.5, 7, 1));
    body = new CANNON.Body({mass, shape});
    body.position.set(120,2,-33);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(6.5, 7, 1));
    body = new CANNON.Body({mass, shape});
    body.position.set(83.5,2,-33);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(7.5, 7, 1));
    body = new CANNON.Body({mass, shape});
    body.position.set(84.5,2,-81.5);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(8, 7, 1));
    body = new CANNON.Body({mass, shape});
    body.position.set(119,2,-81.5);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 8));
    body = new CANNON.Body({mass, shape});
    body.position.set(126,2,-73);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 8));
    body = new CANNON.Body({mass, shape});
    body.position.set(126,2,-41);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 8));
    body = new CANNON.Body({mass, shape});
    body.position.set(77,2,-40);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 8));
    body = new CANNON.Body({mass, shape});
    body.position.set(77,2,-74);
    this.physicsWorld.addBody(body);

    // ricos jugos
    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 10));
    body = new CANNON.Body({mass, shape});
    body.position.set(80,3,30);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 10));
    body = new CANNON.Body({mass, shape});
    body.position.set(60,3,30);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(10, 7, 1));
    body = new CANNON.Body({mass, shape});
    body.position.set(70,3,40);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(8, 7, 1));
    body = new CANNON.Body({mass, shape});
    body.position.set(72,3,20);
    this.physicsWorld.addBody(body);

    //  oxxxo
    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 8));
    body = new CANNON.Body({mass, shape});
    body.position.set(-11, 5, 8);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(12, 7, 1));
    body = new CANNON.Body({mass, shape});
    body.position.set(1, 5, 16);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(12, 7, 1));
    body = new CANNON.Body({mass, shape});
    body.position.set(1, 5, 1);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 2));
    body = new CANNON.Body({mass, shape});
    body.position.set(13, 5, 14);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 2));
    body = new CANNON.Body({mass, shape});
    body.position.set(13, 5, 3);
    this.physicsWorld.addBody(body);
  }

  explosion = () => {
    if(this.interactiveObjects.explode) {
      this.scene.remove(this.interactiveObjects.explode.getObject());
    }
    this.interactiveObjects.explode = new ExplodeAnimation(1, 3.5, 8);
    this.scene.add(this.interactiveObjects.explode.getObject());
    setTimeout(() => {
      if(this.interactiveObjects.explode) {
        this.scene.remove(this.interactiveObjects.explode.getObject());
      }
      delete this.interactiveObjects.explode;
    }, 15000);
  }

  createOrtoedro = (image, url="", position, rotation, width=2, height=1, physics=true, ancho=1) => {

    /* sides order: arriba, frente, reverso, a, b */
    const sides = [
      { image: `${image}_arriba.png`,
        size: { width, height: height*ancho },
        sidePosition: { x: 0,
                    y: 0+(height/2),
                    z: 0+(height/2)*ancho },
        sideRotation: { x: Math.PI/2, y: 0, z: 0 }
      },
      { image: `${image}_frente.png`,
        size: { width, height },
        sidePosition: { x: 0,
                    y: 0,
                    z: 0},
        sideRotation: { x: 0, y: Math.PI, z: 0 }
      },
      { image: `${image}_reverso.png`,
        size: { width, height },
        sidePosition: { x: 0,
                    y: 0,
                    z: 0+(height*ancho) },
        sideRotation: { x: 0, y: 0, z: 0 }
      },
      { image: `${image}_ladoa.png`,
        size: { width: height*ancho, height },
        sidePosition: { x: 0-(width/2),
                    y: 0,
                    z: 0+(height/2)*ancho },
        sideRotation: { x: 0, y: Math.PI*1.5, z: 0 }
      },
      { image: `${image}_ladob.png`,
        size: { width: height*ancho, height },
        sidePosition: { x: 0+(width/2),
                    y: 0,
                    z: 0+(height/2)*ancho },
        sideRotation: { x: 0, y: Math.PI/2, z: 0 }
      }
    ];
    const octoedroGroup = new THREE.Group();
    sides.map((side) => {
      this.textureLoader.load(side.image, (_texture) => {
        const texture = _texture;
        texture.encoding = THREE.sRGBEncoding;
        const geometry = new THREE.PlaneGeometry(side.size.width, side.size.height);
        const material = new THREE.MeshPhongMaterial({
          map: texture,
          side: THREE.DoubleSide,
          transparent: true
        });
        const mesh = new THREE.Mesh(geometry, material);
        mesh.position.set(side.sidePosition.x, side.sidePosition.y, side.sidePosition.z);
        mesh.rotation.set(side.sideRotation.x, side.sideRotation.y, side.sideRotation.z);
        mesh.userData = {URL: url};
        this.raycaster.addClickableObject(mesh);
        octoedroGroup.add(mesh);
      });
    });
    octoedroGroup.position.set(position.x, position.y, position.z);
    // octoedroGroup.rotation.set(rotation.x, rotation.y, rotation.z);

    if(physics){
      const octaedroPhysicsBox = new CANNON.Box(new CANNON.Vec3(width/2, height/2, height/2))
      const octaedroPhysicsBody = new CANNON.Body({mass: 0, shape: octaedroPhysicsBox});
      octaedroPhysicsBody.position.set(position.x, position.y, position.z+(height/2));
      this.physicsWorld.addBody(octaedroPhysicsBody);
    }
    this.scene.add(octoedroGroup);
  }

  createPlaneLink = (image, url, position, size = {height:1, width: 1}) => {
    this.textureLoader.load(image, (texture) => {
      texture.encoding = THREE.sRGBEncoding;
      const geometry = new THREE.PlaneGeometry(size.height, size.width);
      const material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true
      });
      const mesh = new THREE.Mesh(geometry, material);
      if(url){
        mesh.userData = {URL: url};
        this.raycaster.addClickableObject(mesh);
      }
      mesh.position.set(position.x, position.y, position.z);
      this.scene.add(mesh);

    });

  }

  update = () => {
    if(this.interactiveObjects.explode) this.interactiveObjects.explode.update();
    this.gif.animate();
    if(this.interactiveObjects.ThreeSixtyBackground){
         this.interactiveObjects.ThreeSixtyBackground.rotation.y += .001;
         this.interactiveObjects.sky.rotation.y += .0005;
       }

     if(this.audioStreams[0]){ // matazanos
       this.audioStreams[0].renderFftData();
       const audioData = this.audioStreams[0].analyser.data[0];
       const hex = this.audioStreams[0].mapRange(Math.max(150, audioData), 150, 220, -.5, .5);
       this.interactiveObjects.luzEscenarioMatazanos.intensity = Math.max(0, (hex * 10)) ;
       // this.interactiveObjects.luzEscenarioMatazanos.position.y = Math.cos(this.time*0.0005)*0.75+1.25;
       this.interactiveObjects.blueRectLightCeremonia.intensity = hex;
       this.interactiveObjects.blueRectLightCeremoniaHelper.color = new THREE.Color(hex, hex, hex);
       this.interactiveObjects.blueRectLightCeremoniaHelper.update();
     }

     if(this.audioStreams[1]){ // oxxxo
       this.audioStreams[1].renderFftData();
       const audioData = this.audioStreams[1].analyser.data[0];
       const hex = this.audioStreams[0].mapRange(Math.max(150, audioData), 150, 220, -.5, .5);
       this.interactiveObjects.luzEscenarioOxxxo.intensity = Math.max(0, (hex * 10)) ;
       this.interactiveObjects.blueRectLightOxxxo.intensity = hex
       this.interactiveObjects.blueRectLightOxxxoHelper.color = new THREE.Color(hex, hex, hex);
       this.interactiveObjects.blueRectLightOxxxoHelper.update();
     }

  }

}
export default VirtualSpace;
