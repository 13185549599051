export const ilustradores = [
  {
    nombre: 'atopica',
    link: 'https://www.instagram.com/atopica_editorial',
  },
  {
    nombre: 'benitez',
    link: 'https://www.instagram.com/jesusbenitezf',
  },
  {
    nombre: 'buentrabajo',
    link: 'https://www.instagram.com/buen.trabajo',
  },
  {
    nombre: 'chacho',
    link: 'https://www.instagram.com/destierrografica',
  },
  {
    nombre: 'chula',
    link: 'https://www.instagram.com/chularecords',
  },
  {
    nombre: 'fabuloso',
    link: 'https://www.instagram.com/fabuloso_darks',
  },
  {
    nombre: 'fauna',
    link: 'https://www.instagram.com/editorialfaunanociva',
  },
  {
    nombre: 'frosh',
    link: 'https://www.instagram.com/frossshhh',
  },
  {
    nombre: 'fuck',
    link: 'https://www.instagram.com/fuckzines',
  },
  {
    nombre: 'gibran',
    link: 'https://www.instagram.com/gibranturon',
  },
  {
    nombre: 'hungria',
    link: 'https://www.instagram.com/edicioneshungria',
  },
  {
    nombre: 'magana',
    link: 'https://www.instagram.com/mariamagania',
  },
  {
    nombre: 'molusculo',
    link: 'https://www.instagram.com/elmolusculo',
  },
  {
    nombre: 'mono',
    link: 'https://www.instagram.com/monoebrioed',
  },
  {
    nombre: 'pachi',
    link: 'https://www.instagram.com/pachiclon',
  },
  {
    nombre: 'panela',
    link: 'https://www.instagram.com/panelismo',
  },
  {
    nombre: 'payaso',
    link: 'https://www.instagram.com/payasito_ediciones',
  },
  {
    nombre: 'rabia',
    link: 'https://www.instagram.com/rabia15410',
  },
  {
    nombre: 'vegan',
    link: 'https://www.instagram.com/vegan_canibal',
  },
  {
    nombre: 'zini',
    link: 'https://www.instagram.com/zinibooks',
  },
];

export const ilustraciones = [
  {
    nombre: 'adri1',
    link: 'https://www.instagram.com/adrianacassianodibuja/',
  },
  {
    nombre: 'adri2',
    link: 'https://www.instagram.com/adrianacassianodibuja',
  },
  {
    nombre: 'adri3',
    link: 'https://www.instagram.com/adrianacassianodibuja',
  },
  {
    nombre: 'adrian1',
    link: 'https://www.instagram.com/adrian_regnier/',
  },
  {
    nombre: 'adrian2',
    link: 'https://www.instagram.com/adrian_regnier',
  },
  {
    nombre: 'adrian3',
    link: 'https://www.instagram.com/adrian_regnierr',
  },
  {
    nombre: 'ed1',
    link: 'https://www.instagram.com/eddspecialagent',
  },
  {
    nombre: 'ed2',
    link: 'https://www.instagram.com/eddspecialagent',
  },
  {
    nombre: 'ed3',
    link: 'https://www.instagram.com/eddspecialagent',
  },
  {
    nombre: 'marco1',
    link: 'https://www.instagram.com/marco__caballero',
  },
  {
    nombre: 'marco2',
    link: 'https://www.instagram.com/marco__caballero',
  },
  {
    nombre: 'moni1',
    link: 'https://www.instagram.com/monicaquant',
  },
  {
    nombre: 'moni2',
    link: 'https://www.instagram.com/monicaquant',
  },
  {
    nombre: 'pepe1',
    link: 'https://www.instagram.com/ppkmkzztt',
  },
  {
    nombre: 'pepe2',
    link: 'https://www.instagram.com/ppkmkzztt',
  },
  {
    nombre: 'tamara1',
    link: 'https://www.instagram.com/cumbiasborrascosas',
  },
  {
    nombre: 'tamara2',
    link: 'https://www.instagram.com/cumbiasborrascosas',
  },
  {
    nombre: 'urba1',
    link: 'https://www.instagram.com/urbano.mata',
  },
  {
    nombre: 'urba2',
    link: 'https://www.instagram.com/urbano.mata',
  },
  {
    nombre: 'urba3',
    link: 'https://www.instagram.com/urbano.mata',
  },
  {
    nombre: 'yoce1',
    link: 'https://www.instagram.com/jocelyn_samayoa_',
  },
  {
    nombre: 'yoce2',
    link: 'https://www.instagram.com/jocelyn_samayoa_',
  },
  {
    nombre: 'payaso1',
    link: 'http://www.omfgdogs.com/',
  },
  {
    nombre: 'yakult1',
    link: 'https://www.instagram.com/35k_yakult/',
  },
  {
    nombre: 'yakult2',
    link: 'https://www.instagram.com/35k_yakult/',
  },
  {
    nombre: 'simi',
    link: 'https://www.youtube.com/watch?v=XaCBuYD_Y20',
  },
  {
    nombre: 'kemonito',
    link: 'https://www.youtube.com/watch?v=gFampquURRg',
  },
  {
    nombre: 'churi',
    link: 'https://www.instagram.com/monoschuridos_sa_de_cv/',
  },
  {
    nombre: 'anahi1',
    link: 'http://salmonofcapistrano.com/',
  },
  {
    nombre: 'chula1',
    link: 'https://cat-bounce.com/',
  },
];

export const extras = [
  {
    nombre: 'siempreeshoy',
    link: 'https://www.instagram.com/vacacionestrabajo',
    x: 70,
    y: 3,
    z: 30,
    height: 3,
    width: 6,
  },
  {
    nombre: 'acab',
    link: 'https://www.instagram.com/gatitoscontraladesigualdad/',
    x: 70,
    y: 2,
    z: -4,
    height: 8,
    width: 4,
  },
  {
    nombre: 'basura',
    link: 'http://www.eelslap.com',
    x: 10,
    y: 1,
    z: -20,
    height: 3,
    width: 2,
  },
  {
    nombre: 'cables',
    link: 'https://www.youtube.com/watch?v=cd0PV82e1jE',
    x: 7,
    y: 4,
    z: -12,
    height: 30,
    width: 10,
  },
  {
    nombre: 'cables',
    link: 'https://www.youtube.com/watch?v=sD9_KUBLyHo',
    x: 100,
    y: 4,
    z: -100,
    height: 30,
    width: 10,
  },
  {
    nombre: 'dino',
    link: 'https://www.youtube.com/results?search_query=burutabu+chan+',
    x: 52,
    y: 2,
    z: -55,
    height: 8,
    width: 4,
  },
  {
    nombre: 'gato',
    link: 'https://cat-bounce.com',
    x: 10,
    y: 1,
    z: -40,
    height: 2,
    width: 2,
  },
  {
    nombre: 'pared',
    link: 'https://www.youtube.com/watch?v=-HJwbxVe8Rg',
    x: 100,
    y: 2,
    z: 8,
    height: 12,
    width: 6,
  },
  {
    nombre: 'pared',
    link: 'https://www.youtube.com/watch?v=-HJwbxVe8Rg',
    x: 30,
    y: 2,
    z: -80,
    height: 12,
    width: 6,
  },
];
