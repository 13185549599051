import React, { useEffect, useState } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ControlsComponent from './ControlsComponent';
import ChatComponent from './Chat/ChatComponent';
import ControlsSwitchesComponent from './ControlsSwitchesComponent';
import { ReactComponent as Logo } from '../img/oxxxo-logo_invertido.svg';
import LogoPNG from '../img/oxxxo-logo.png';

const MainContainer = React.forwardRef((props, refContainer) => {
  const {
    oxxxo, isMobile, uuid, connected, chatContainer, controlsContainer, username, nombreDeEspacio,
  } = props;
  const [state, setState] = useState({
    showChat: !!isMobile,
    audioChannelOne: true,
  });

  const buttonRef = React.createRef();

  const toggleShowChat = () => {
    setState((prev) => ({
      ...prev,
      showChat: !prev.showChat,
    }));
  };

  const switchAudioChannel = () => {
    oxxxo.switchAudioChannel();
    setState((prev) => ({
      ...prev,
      audioChannelOne: !prev.audioChannelOne,
    }));
  };

  const handleWindowResize = () => {
    oxxxo.handleWindowResize();
  };

  const setVolume = (val) => {
    oxxxo.setVolume(val);
  };

  useEffect(() => {
    if (!oxxxo) return;
    refContainer.current.appendChild(oxxxo.renderer.domElement);
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
      oxxxo.unmount();
    };
  }, [refContainer]);

  const { audioChannelOne } = state;

  const audioChannelName = audioChannelOne ? 'escenario Matazanos' : 'escenario oxxxo';

  return (
    <div className="container" ref={refContainer}>
      <div className="main-logo">
        <Logo className="main-logo__svg" />
        <img className="main-logo__png" src={LogoPNG} alt="logo" />
      </div>
      {false && isMobile && connected
        && (
        <>
          <button
            className="channelSwitchButton"
            type="submit"
            onClick={switchAudioChannel}
          >
            <span role="img" aria-label="switch audio channel">
              {`Audio: ${audioChannelName}`}
              <br />
              (cambiar)
            </span>
          </button>
          <FormControl variant="outlined" style={{ background: 'red' }}>
            <InputLabel id="demo-simple-select-outlined-label">Age</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={30}
              label="Age"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={10}>Ten</MenuItem>
              <MenuItem value={20}>Twenty</MenuItem>
              <MenuItem value={30}>Thirty</MenuItem>
            </Select>
          </FormControl>
        </>
        )}
      <ControlsSwitchesComponent
        isMobile={isMobile}
        toggleShowChat={toggleShowChat}
        setVolume={setVolume}
      />
      <ControlsComponent
        ref={controlsContainer}
        buttonref={buttonRef}
      />
      <ChatComponent
        showChat={state.showChat}
        userId={uuid}
        username={username}
        ref={chatContainer}
        nombreDeEspacio={nombreDeEspacio}
      />
    </div>
  );
});

export default MainContainer;
