import { firebaseRealtimeDatabase } from './firebaseConnection';

const initUser = async (
  nombreDeEspacio, uuid, avatar, username, initialAvatarPosition, country, flag,
) => {
  const refPath = `${nombreDeEspacio}${process.env.REACT_APP_FIREBASE_USERS_DATABASE_SUFFIX}`;

  const userData = {
    id: uuid,
    avatar,
    username,
    country: country || '',
    countryFlag: flag || '',
    position: initialAvatarPosition,
    lastUpdate: Date.now(),
    nombreDeEspacio,
    rotation: {
      _order: 'XYZ',
      _x: 0,
      _y: 0,
      _z: 0,
    },
    frequency: 1, // kurenniemi variable
    modulation: 1000, // kurenniemi variable
  };
  const userRefPath = `${refPath}/${uuid}`;
  firebaseRealtimeDatabase.ref('.info/connected').on('value', (snapshot) => {
    // If we're not currently connected, don't do anything.
    if (snapshot.val() === false) {
      return;
    }
    firebaseRealtimeDatabase.ref(userRefPath).onDisconnect().remove();
    firebaseRealtimeDatabase.ref(userRefPath).set(userData);
  });
};

export default initUser;
