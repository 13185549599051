import React from 'react';
import './Loader.scss';

export const FullPageLoader = () => (
  <div className="loader">
    <div className="spinner" />
  </div>
);

export const Loader = () => (
  <div className="spinner" />
);
