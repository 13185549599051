import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { withTranslation } from 'react-i18next';
import { ReactComponent as RotatePhonePicture } from '../img/instructions/rotate_phone.svg';
import { ReactComponent as ArrowKeysPicture } from '../img/instructions/keyboard_keys.svg';
import { ReactComponent as ChatIcon } from '../img/instructions/chat-icon.svg';
import './InstructionsSlider.scss';

const Instructions = (props) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const { t, isMobile } = props;
  return (
    <>
      {isMobile
        ? (
          <SwipeableViews className="instructionsSlider" enableMouseEvents index={currentSlide}>
            <div className="instructionsSlider__container">
              <div className="instructionsSlider__content">
                {t('instructions.mobileControlsInstructions')}
                <br />
                <br />
                <RotatePhonePicture style={{ width: '70px' }} />
                <br />
                <button
                  type="submit"
                  onClick={() => props.setInstructionsCompleted(true)}
                  className="instructionsSlider__startButton"
                >
                  {t('instructions.start')}
                </button>
              </div>
            </div>
          </SwipeableViews>
        ) : (
          <SwipeableViews className="instructionsSlider" enableMouseEvents index={currentSlide}>
            <div className="instructionsSlider__container">
              <div className="instructionsSlider__content">
                <span className="instructionsSlider__title">
                  {t('instructions.controlsTitle')}
                </span>
                <br />
                <br />
                {t('instructions.controlsInstructions')}
                <br />
                <br />
                <ArrowKeysPicture />
                <br />
                <button
                  type="submit"
                  onClick={() => setCurrentSlide(1)}
                  className="instructionsSlider__nextButton"
                >
                  {t('instructions.next')}
                </button>
              </div>
            </div>
            <div className="instructionsSlider__container">
              <div className="instructionsSlider__content">
                {t('instructions.chatInstructions')}
                <br />
                <br />
                <ChatIcon style={{ width: '40px' }} />
                <br />
                <button
                  type="submit"
                  onClick={() => props.setInstructionsCompleted(true)}
                  className="instructionsSlider__startButton"
                >
                  {t('instructions.start')}
                </button>
              </div>
            </div>
          </SwipeableViews>
        )}
    </>
  );
};

export default withTranslation()(Instructions);
