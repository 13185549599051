import * as THREE from 'three';
import axios from 'axios';

export default class VideoPlayer {
  constructor(videoUrl, position, scene) {
    const [x, y, z] = position;
    this.video = document.createElement('video');
    this.video.setAttribute('webkit-playsinline', true);
    this.video.crossOrigin = 'anonymous';
    this.video.loop = true;
    this.video.playsInline = true;
    this.video.style.display = 'none';
    const source = document.createElement('source');
    source.src = videoUrl;
    this.video.appendChild(source);
    this.video.play();

    const videoTexture = new THREE.VideoTexture(this.video);

    const materialT = new THREE.MeshBasicMaterial({
      map: videoTexture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    const geometry = new THREE.SphereGeometry(30, 32, 32);

    const videoMesh = new THREE.Mesh(geometry, materialT);
    videoMesh.position.set(x, y, z);
    scene.add(videoMesh);
  }

  replaceVideo(videoUrl) {
    axios.get(videoUrl).then(() => {
      this.video.pause();
      let firstChild = this.video.firstElementChild;
      while (firstChild) {
        firstChild.remove();
        firstChild = this.video.firstElementChild;
      }
      const source = document.createElement('source');
      this.video.setAttribute('src', videoUrl);
      this.video.appendChild(source);
      this.video.load();
      this.video.play();
    }).catch((error) => {
      console.log(error);
    });
  }
}
