import * as THREE from "three";
import store from "../store/store";
export default class RayCaster {
  constructor(camera, isDoubleClick = false) {
    this.camera = camera;
    this.isDoubleClick = isDoubleClick;
    this.clickableObjects = [];
    this.intersectedObject = null;
    this.mouse = new THREE.Vector2();
    this.initEventlisteners();
    this.clickTimer = null;
  }

  addClickableObject = (object) => {
    this.clickableObjects.push(object);
  };

  onMouseMove = (event) => {
    this.mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    this.mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  };

  setOnClickCallback = (fn) => {
    this.onClickCallback = fn;
  };

  onClick = () => {
    if (!store.getState().shouldMove) return;

    if (this.isDoubleClick) {
      if (this.clickTimer == null) {
        this.clickTimer = setTimeout(() => {
          this.clickTimer = null;
        }, 500);
        return;
      }
      clearTimeout(this.clickTimer);
    }

    this.clickTimer = null;
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = raycaster.intersectObjects(this.clickableObjects);
    if (intersects.length > 0) {
      if (this.onClickCallback) this.onClickCallback(intersects[0].object);
    }
  };

  setOnMouseOverCallback = (fn) => {
    this.onMouseOverCallback = fn;
  };

  setOnMouseOutCallback = (fn) => {
    this.setOnMouseOutCallback = fn;
  };

  onMouseOver = () => {
    if (!store.getState().shouldMove) return;
    const raycaster = new THREE.Raycaster();
    raycaster.setFromCamera(this.mouse, this.camera);
    const intersects = raycaster.intersectObjects(this.clickableObjects);
    if (intersects.length > 0) {
      if (this.intersectedObject !== intersects[0].object) {
        intersects[0].object.material.color.set(0x0000ff);
        this.intersectedObject = intersects[0].object;
      }
    } else {
      // return to normal state then set null
      if (this.intersectedObject)
        this.intersectedObject.material.color.set(0xff0000);
      this.intersectedObject = null;
    }
  };

  initEventlisteners = () => {
    document.addEventListener("mousemove", this.onMouseMove, false);
    document.addEventListener("click", this.onClick, false);
  };
}
