import * as THREE from 'three';

const createPlaneLink = ({
  textureLoader, raycaster, scene, image, url, position, size = { height: 1, width: 1 },
}) => {
  textureLoader.load(image, (texture) => {
    texture.encoding = THREE.sRGBEncoding;
    const geometry = new THREE.PlaneGeometry(size.height, size.width);
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    const mesh = new THREE.Mesh(geometry, material);
    if (url) {
      mesh.userData = { URL: url };
      raycaster.addClickableObject(mesh);
    }
    mesh.position.set(position.x, position.y, position.z);
    scene.add(mesh);
  });
};

export default createPlaneLink;
