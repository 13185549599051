import VirtualSpace from './VirtualSpace';

const config = {
  virtualSpace: new VirtualSpace(),
  avatars: [
    {
      id: 0, avatarSelectorImagePath: '/avatars/fonima/monita.gif', isGif: true, avatarPath: '/textures/gif-avatars/fonima/woman.png',
    },
    {
      id: 1, avatarSelectorImagePath: '/avatars/fonima/monito.gif', isGif: true, avatarPath: '/textures/gif-avatars/fonima/man.png',
    },
    {
      id: 2, avatarSelectorImagePath: '/avatars/fonima/monite.gif', isGif: true, avatarPath: '/textures/gif-avatars/fonima/monite.png',
    },
  ],
  socketRoom: 'room',
  avatarLight: false,
  nombreDeEspacio: 'fonima',
  audioStreams: [
    {
      url: 'https://stream-relay-geo.ntslive.net/stream?client=NTSWebApp&t=1617256601983',
      position: [0, 2, -60],
      distance: 50,
    },
    {
      url: 'https://stream-relay-geo.ntslive.net/stream?client=NTSWebApp&t=1617256601983',
      position: [70, 1.3, -10],
      distance: 50,
    },
  ],
};

export default config;
