import * as THREE from 'three';
import * as CANNON from 'cannon-es';

const initInteractiveSphere = (scene, physicsWorld) => {
  const sphereGeometry2 = new THREE.SphereGeometry(1, 64, 64);
  const geo2 = new THREE.EdgesGeometry(sphereGeometry2);
  const mat2 = new THREE.LineBasicMaterial({ color: new THREE.Color(0x000000), linewidth: 2 });
  const wireframe2 = new THREE.LineSegments(geo2, mat2);
  wireframe2.position.set(230, 52, 275);
  wireframe2.scale.set(40, 40, 40);
  scene.add(wireframe2);

  const wSphereInt = new THREE.Mesh(
    sphereGeometry2,
    new THREE.MeshBasicMaterial({ side: THREE.BackSide }),
  );
  wSphereInt.position.set(230, 52, 275);
  wSphereInt.scale.set(40, 40, 40);
  scene.add(wSphereInt);
  const pisoInteractivx = new CANNON.Cylinder(44, 44, 1, 16);
  const pisoInteractivxBody = new CANNON.Body({ mass: 0, shape: pisoInteractivx });
  pisoInteractivxBody.position.set(230, 52, 275);

  const axis = new CANNON.Vec3(0, 0, 0);
  const angle = 0;
  pisoInteractivxBody.quaternion.setFromAxisAngle(axis, angle);

  physicsWorld.addBody(pisoInteractivxBody);
};

export default initInteractiveSphere;
