import * as THREE from 'three';
import GifAnimation from '../../../three-utils/GifAnimation';

const gifsData = [
  {
    url: 'fon_16',
    position: [-498, 2, 19],
    rotation: [-Math.PI / 2, Math.PI / 2, Math.PI / 2],
    size: [3, 3],
    spriteConfig: [20, 13, 242, 15],
  },
  {
    url: 'fon_19',
    position: [-498, 2, 11],
    rotation: [-Math.PI / 2, Math.PI / 2, Math.PI / 2],
    size: [3, 3],
    spriteConfig: [20, 7, 140, 15],
  },
  {
    url: 'fon_43',
    position: [-478, 2, 10.5],
    rotation: [0, Math.PI, 0],
    size: [3, 3],
    spriteConfig: [20, 3, 57, 10],
  },
  {
    url: 'fon_45',
    position: [-486, 2, 10.5],
    rotation: [0, Math.PI, 0],
    size: [3, 3],
    spriteConfig: [20, 10, 194, 25],
  },
  {
    url: 'fon_51',
    position: [-509, 2, -7.5],
    rotation: [0, 0, 0],
    size: [3, 3],
    spriteConfig: [20, 10, 188, 25],
  },
  {
    url: 'fon_62',
    position: [-503, 2, -7.5],
    rotation: [0, 0, 0],
    size: [3, 3],
    spriteConfig: [20, 16, 308, 8],
  },
  {
    url: 'fon_64',
    position: [-492.7, 2, -16],
    rotation: [-Math.PI / 2, Math.PI / 2, Math.PI / 2],
    size: [3, 3],
    spriteConfig: [20, 7, 135, 12],
  },
  {
    url: 'fon_67',
    position: [-492.7, 2, -24],
    rotation: [-Math.PI / 2, Math.PI / 2, Math.PI / 2],
    size: [3, 3],
    spriteConfig: [13, 10, 129, 15],
  },
  // animasivo
  {
    url: 'animasivo/mapping',
    position: [328.535, 18, -355.359],
    rotation: [0, Math.PI / 2, 0],
    size: [9, 9],
    spriteConfig: [27, 10, 270, 15],
    link: 'https://www.youtube.com/channel/UCsRwjeWm8k-r0_rcStE-Llw/videos',
  },
  {
    url: 'animasivo/anim_light',
    position: [343.669, 18, -336.6],
    rotation: [0, Math.PI, 0],
    size: [12, 8],
    spriteConfig: [4, 3, 12, 15],
    link: 'https://www.youtube.com/channel/UCsRwjeWm8k-r0_rcStE-Llw/videos',
  },
  {
    url: 'animasivo/vjinglight',
    position: [341.53, 18, -374.50],
    rotation: [0, 0, 0],
    size: [12, 8],
    spriteConfig: [28, 11, 301, 15],
    link: 'https://www.youtube.com/channel/UCsRwjeWm8k-r0_rcStE-Llw/videos',
  },
];

const initGifsGallery = (scene, raycaster) => {
  const gifs = new Set();
  gifsData.forEach((item) => {
    const container = new THREE.Group();

    const [xPos, yPos, zPos] = item.position;
    const [xRot, yRot, zRot] = item.rotation;
    const gif = new GifAnimation(
      `textures/fonima/gifs-gallery/sprites/${item.url}.png`,
      new THREE.Vector3(0, 0, 0),
      // new THREE.Vector3(xPos, yPos, zPos),
      // new THREE.Vector3(xRot, yRot, zRot),
      new THREE.Vector3(0, 0, 0),
      [item.size[0], item.size[1]],
      item.spriteConfig,
      true,
    );
    gifs.add(gif);
    const mesh = gif.getMesh();
    container.add(mesh);
    if (item.link) {
      mesh.userData = { URL: item.link };
      raycaster.addClickableObject(mesh);
    }

    const textureLoader = new THREE.TextureLoader();
    textureLoader.load(`textures/fonima/gifs-gallery/titulos/${item.url}.png`, (texture) => {
      const geometry = new THREE.PlaneGeometry(2, 0.50);
      const material = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true,
      });
      const title = new THREE.Mesh(geometry, material);
      title.position.set(2.5, 0, 0);
      container.add(title);
    });
    container.position.set(xPos, yPos, zPos);
    container.rotation.set(xRot, yRot, zRot);
    scene.add(container);
  });

  return () => {
    gifs.forEach((item) => {
      item.animate();
    });
  };
};

export default initGifsGallery;
