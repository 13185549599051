import * as THREE from 'three';

const initParticles = (count = 400000, position = [-17, 3, -9], scene) => {
  const geometry = new THREE.BufferGeometry();
  const arrayBuffer = new ArrayBuffer(count * 16);
  const interleavedFloat32Buffer = new Float32Array(arrayBuffer);
  const interleavedUint8Buffer = new Uint8Array(arrayBuffer);

  const color = new THREE.Color();
  const n = 80;
  const n2 = n / 2;
  for (let i = 0; i < interleavedFloat32Buffer.length; i += 4) {
    const x = Math.random() * n - n2;
    const y = Math.random() * n - n2;
    const z = Math.random() * n - n2;

    interleavedFloat32Buffer[i + 0] = x;
    interleavedFloat32Buffer[i + 1] = y;
    interleavedFloat32Buffer[i + 2] = z;

    // color (last 4 bytes)
    const vx = (x / n) + 0.5;
    const vy = (y / n) + 0.5;
    const vz = (z / n) + 0.5;
    color.setRGB(vx, vy, vz);

    const j = (i + 3) * 4;
    interleavedUint8Buffer[j + 0] = color.r * 255;
    interleavedUint8Buffer[j + 1] = color.g * 255;
    interleavedUint8Buffer[j + 2] = color.b * 255;
    interleavedUint8Buffer[j + 3] = 0; // not needed
  }

  const interleavedBuffer32 = new THREE.InterleavedBuffer(interleavedFloat32Buffer, 4);
  const interleavedBuffer8 = new THREE.InterleavedBuffer(interleavedUint8Buffer, 16);

  geometry.setAttribute('position', new THREE.InterleavedBufferAttribute(interleavedBuffer32, 3, 0, false));
  geometry.setAttribute('color', new THREE.InterleavedBufferAttribute(interleavedBuffer8, 3, 12, true));

  const material = new THREE.PointsMaterial({ size: 0.1, vertexColors: true });
  const points = new THREE.Points(geometry, material);
  points.position.set(...position);
  scene.add(points);
};

export default initParticles;
