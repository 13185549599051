import {
  SET_SHOULD_MOVE,
  SET_SHOULD_NOT_MOVE,
  SET_OXXXO_CONFIG,
  SET_DEBUG_MODE,
  UPDATE_CONNECTED_USERS_COUNT,
} from '../actions';
import initialState from './initialState';

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SHOULD_MOVE: return { ...state, shouldMove: true };

    case SET_SHOULD_NOT_MOVE: return { ...state, shouldMove: false };

    case SET_OXXXO_CONFIG: return { ...state, config: action.data };

    case SET_DEBUG_MODE: return { ...state, debugMode: action.data };

    case UPDATE_CONNECTED_USERS_COUNT: return { ...state, connectedUsers: action.data };

    default:
      return state;
  }
}

export default rootReducer;
