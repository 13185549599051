import axios from 'axios';

const checkIfFileExists = async (url) => {
  axios.get(url).then((res) => {
    console.log(res);
    return true;
  })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export default checkIfFileExists;
