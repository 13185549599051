import * as THREE from 'three';
import { createFloor } from '../../../three-utils/physicsUtils';

const initBlackHoleSpace = (scene, physicsWorld) => {
  const sphereGeometry2 = new THREE.SphereGeometry(1, 128, 128);
  const geo2 = new THREE.EdgesGeometry(sphereGeometry2);
  const mat2 = new THREE.LineBasicMaterial({ color: new THREE.Color(0x000000), linewidth: 2 });
  const wireframe2 = new THREE.LineSegments(geo2, mat2);
  wireframe2.position.set(-493.15, 2, 0);
  wireframe2.scale.set(94, 94, 94);
  scene.add(wireframe2);

  for (let i = 0; i < wireframe2.geometry.attributes.position.count; i += 10) {
    const p = wireframe2.geometry.attributes.position.array[i];
    wireframe2.geometry.attributes.position.array[i] = p * Math.random();
  }
  wireframe2.geometry.verticesNeedUpdate = true;

  const insideGeo = new THREE.SphereGeometry(1, 32, 32);
  const interiorMat = new THREE.MeshBasicMaterial({
    color: new THREE.Color(0x000000),
    side: THREE.BackSide,
  });
  const interior = new THREE.Mesh(insideGeo, interiorMat);
  interior.position.set(-493.15, 2, 0);
  interior.scale.set(35, 35, 35);
  scene.add(interior);

  const floorGeometry = new THREE.CircleGeometry(35, 64);
  const floorMaterial = new THREE.MeshBasicMaterial({
    color: 0x000000,
    side: THREE.BackSide,
  });
  const circle = new THREE.Mesh(floorGeometry, floorMaterial);
  circle.position.set(-493, 0.001, 0);
  circle.rotation.x = Math.PI / 2;
  scene.add(circle);

  createFloor(physicsWorld, [8.25, 0.5, 16.5], [-494.36, 4.2, 0]);
  createFloor(physicsWorld, [0.1, 2, 12.5], [-493, 2, -20.26]);
  createFloor(physicsWorld, [0.1, 2, 13.4], [-498.830, 2, 15.780]);
  createFloor(physicsWorld, [10.5, 2, 0.1], [-481.240, 2, 10.7]);
  createFloor(physicsWorld, [7, 2, 0.1], [-505.860, 2, -7.660]);
};

export default initBlackHoleSpace;
