const initialState = {
  shouldMove: false,
  debugMode: false,
  connectedUsers: 1,
  config: {
    avatars: [
      {
        id: 0, avatarSelectorImagePath: '/avatars/fonima/monita.gif', isGif: true, avatarPath: '/textures/gif-avatars/fonima/woman.png',
      },
      {
        id: 1, avatarSelectorImagePath: '/avatars/fonima/monito.gif', isGif: true, avatarPath: '/textures/gif-avatars/fonima/man.png',
      },
      {
        id: 2, avatarSelectorImagePath: '/avatars/fonima/monite.gif', isGif: true, avatarPath: '/textures/gif-avatars/fonima/monite.png',
      },
    ],
    socketRoom: 'room',
    avatarLight: false,
    nombreDeEspacio: 'chats',
    uuid: null,
  },
};

export default initialState;
