import store from '../store/store';
import { setDebugMode } from '../store/actions';

export const setDebug = (urlQueryParams) => {
  const debugParam = urlQueryParams.get('debug');
  // 0: fps, 1: ms, 2: mb
  const debugType = ['0', '1', '2'].includes(debugParam) ? debugParam : false;
  store.dispatch(setDebugMode(debugType));
  return !!debugType;
};

export const isDebugMode = () => {
  const { debugMode } = store.getState();
  return debugMode;
};
