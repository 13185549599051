import * as THREE from "three";
import * as CANNON from 'cannon-es';
import CharacterModel from './CharacterModel';
import CharacterGif from './CharacterGif';
import { importedModels } from '../../three-utils/modelsLoader';
import store from '../../store/store';

export default class Avatar {
    constructor(modelId = 0,
                initialPosition = [0, 0, 0],
                physicsMaterial,
                mass = 0) {
      const { config } = store.getState();
      const { avatars } = config;
      // encontrar avatar seleccionado, si no, usar el primero
      const selectedAvatar = avatars.find((avatar) => avatar.id === parseInt(modelId)) || avatars[0];
      this.isGif = selectedAvatar.isGif;
      this.mass = mass;
      this.material = physicsMaterial;
      this.initialPosition = new THREE.Vector3().fromArray(initialPosition);
      this.mixer = null;
      this.isWalking = false;
      this.name = null;
      this.physicsBody = this.createPhysicsBody();
      this.loadModel(selectedAvatar.avatarPath);
    }

    loadModel = (avatarPath) => {
      this.characterModel = this.isGif ? new CharacterGif(avatarPath) : new CharacterModel(avatarPath);
      this.modelMesh = this.characterModel.getModel();
      this.modelMesh.position.y = this.isGif ? 0 : -0.7;
    }

    createName = (username, countryFlag) => {
      this.name = new THREE.Object3D();
      this.name.rotateY(Math.PI);
    	const color = new THREE.Color(0xFFFFFF);
    	const matDark = new THREE.MeshNormalMaterial({
    		side: THREE.DoubleSide
    	});

    	const shapes = importedModels.font.generateShapes(username, .1);
    	const geometry = new THREE.ShapeBufferGeometry(shapes);
    	geometry.computeBoundingBox();
    	const xMid = -0.5*(geometry.boundingBox.max.x - geometry.boundingBox.min.x);
    	geometry.translate(xMid, 0, 0);
    	const text = new THREE.Mesh(geometry, matDark);
    	text.position.y = 1.2;
      this.name.add(text);

      const countryFlagGeometry = new THREE.PlaneBufferGeometry(.5, .2);
      const canvas = document.createElement('canvas');
      canvas.width = 256;
      canvas.height = 128;
      const ctx = canvas.getContext('2d');
      ctx.font = '200px Arial';
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(countryFlag || " ", canvas.width / 2, canvas.height / 2);

      const texture = new THREE.Texture(canvas);
      texture.needsUpdate = true;
      const countryFlagMaterial = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true
      });
      const countryFlagMesh = new THREE.Mesh(countryFlagGeometry, countryFlagMaterial);
      countryFlagMesh.position.y = 1;
      this.name.add(countryFlagMesh);
    }

    walk = () => {
      if(this.isWalking) return
      this.characterModel.playAnimation();
      this.isWalking = true;
    }

    stopWalking = () => {
      this.isWalking = false;
      this.characterModel.stopAnimation();
    }

    createPhysicsBody = () => {
      const physicsShape = new CANNON.Box(new CANNON.Vec3(.7, .7, .7));
      // const physicsShape = new CANNON.Sphere(0.7);
      const physicsBody = new CANNON.Body({ mass: this.mass, material: this.material });
//      physicsBody.linearDamping = 0.9;
      physicsBody.addShape(physicsShape);
      physicsBody.position.set(this.initialPosition.x, this.initialPosition.y+2, this.initialPosition.z);
      physicsBody.position.set(this.initialPosition.x, this.initialPosition.y, this.initialPosition.z);
      return physicsBody;
    }

    getName = () => {
      return this.name;
    }

    getModelMesh = () => {
      return this.modelMesh;
    }

    getPhysicsBody = () => {
      return this.physicsBody;
    }

    update = (delta) => {
      this.characterModel.updateAnimation(delta);
    }
}
