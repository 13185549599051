import React, { useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import {
  List, AutoSizer, CellMeasurer, CellMeasurerCache,
} from 'react-virtualized';
import { isMobile } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { shouldMove, shouldNotMove } from '../../store/actions';
import FirebaseChat from './FirebaseChat';
import './ChatComponent.scss';

const ChatComponent = React.forwardRef((
  {
    nombreDeEspacio, showChat, username, userId, t,
  },
  refContainer,
) => {
  const dispatch = useDispatch();

  const [message, setMessage] = useState('');
  const [messageList, setMessageList] = useState([]);
  const [lastMessageTime, setLastMessageTime] = useState(Date.now());
  const messageListContainer = useRef(null);
  const remoteConnection = new FirebaseChat(nombreDeEspacio);


  const receiveChatMessage = (messageData) => {
    setMessageList((list) => {
      const lastN = list.slice(-1 * (parseInt(process.env.REACT_APP_FIREBASE_LIMIT_OF_CHAT_MESSAGES, 10) - 1));
      return [...lastN, messageData];
    });
  };

  useEffect(() => {
    remoteConnection.receiveChatMessage((messageData) => {
      receiveChatMessage(messageData);
    });
  }, []);

  const sendMessage = () => {
    if (!message) return;
    const now = Date.now();
    if (lastMessageTime + 1500 > now) return;
    setLastMessageTime(now);
    const messageData = {
      userId,
      username,
      message,
      timestamp: now,
    };
    remoteConnection.sendChatMessage(messageData);

    setMessage('');
  };

  const onChangeHandler = (event) => {
    setMessage(event.target.value);
  };

  const onKeyPress = (event) => {
    if (event.charCode === 13) {
      sendMessage();
    }
  };

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    minHeight: 40,
  });

  const rowRenderer = ({
    index, parent, key, style,
  }) => (
    <CellMeasurer
      key={key}
      parent={parent}
      cache={cache}
      rowIndex={index}
    >
      <div key={key} style={style} className={`${messageList[index].userId === userId ? 'chatbox__message-container--my-message' : ''}`}>
        <div className="chatbox__message-username">
          {messageList[index].username}
          :
        </div>
        <div className="chatbox__message-message">
          {messageList[index].message}
        </div>
      </div>
    </CellMeasurer>
  );

  const onFocus = () => { dispatch(shouldNotMove()); };
  const onBlur = () => { dispatch(shouldMove()); };

  if (showChat) {
    return (
      <div
        ref={refContainer}
        className={
        `chatbox chatbox--show${isMobile ? ' chatbox--mobile-responsive' : ''}`
      }
      >
        <div className="chatbox__message-list-container">
          <div className="chatbox__message-list" ref={messageListContainer}>
            <AutoSizer>
              {({ height, width }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={cache.rowHeight}
                  rowRenderer={rowRenderer}
                  rowCount={messageList.length}
                  scrollToIndex={messageList.length - 1}
                />
              )}
            </AutoSizer>
          </div>
        </div>
        <input
          className="chatbox__chat-text-input"
          placeholder={t('chat.message')}
          type="text"
          onChange={onChangeHandler}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          onBlur={onBlur}
          value={message}
        />
        <button
          type="submit"
          className="chatbox__emit-chat-message-button"
          onClick={sendMessage}
        >
          {t('chat.send')}
        </button>
      </div>
    );
  }

  return (
    <div ref={refContainer} className={`chatbox${isMobile ? ' chatbox--mobile-responsive' : ''}`} />
  );
});

export default withTranslation('translations', { withRef: true })(ChatComponent);
