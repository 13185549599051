import * as THREE from 'three';
import { isMobile } from 'react-device-detect';
import AudioStream from './AudioStream';
import store from '../store/store';

const createAudioStreams = (scene, userAvatar) => {
  const { config, debugMode } = store.getState();
  const { audioStreams = [] } = config;
  const streamsConfigurations = isMobile ? [audioStreams[0]] : audioStreams;

  const streams = streamsConfigurations?.map(({ url, position, distance }) => {
    const stream = new AudioStream(url);
    stream.setDistance(distance);
    stream.play();
    const sphereSize = debugMode ? distance : 0.01;
    const sphere = new THREE.SphereGeometry(sphereSize, 32, 16);
    const material = new THREE.MeshBasicMaterial({ color: 0xff2200, wireframe: true });
    const mesh = new THREE.Mesh(sphere, material);
    mesh.position.set(...position);
    mesh.add(stream.audio);
    scene.add(mesh);
    userAvatar.getModelMesh().add(stream.listener);
    return stream;
  });
  return streams;
};

export default createAudioStreams;
