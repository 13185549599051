import * as THREE from "three";
import GifAnimation from "../../three-utils/GifAnimation";

export default class CharacterGif{
  constructor(avatarPath){
    this.gif = new GifAnimation(
      avatarPath,
      new THREE.Vector3(0,0,0),
      new THREE.Vector3(0,0,0),
      [1.5,1.5],
      [4, 1, 4, 10]
  //    [4, 1, 4, 15]
    );
  }

  getModel = () => {
    return this.gif.getMesh();
  }

  playAnimation = () => {
    this.play = true;
  }

  stopAnimation = () => {
    this.play = false;
  }

  updateAnimation = () => {
    if(this.play) this.gif.animate();
  }

}
