import * as THREE from 'three';
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';
import createText from './createText';

export default class TextDisplay {
  constructor(scene, position, width, height) {
    const screenWidth = width;
    const screenHeight = height;
    const [x, y, z] = position;
    this.position = position;
    this.rectAreaLight = new THREE.RectAreaLight(0xf3aaaa, 0, screenWidth, screenHeight);
    this.rectAreaLight.position.set(x, y, z);
    this.rectAreaLight.rotation.set(0, 0.95 + (Math.PI), 0);

    scene.add(this.rectAreaLight);
    this.rectAreaLightHelper = new RectAreaLightHelper(this.rectAreaLight, 0xffffff);
    this.rectAreaLight.add(this.rectAreaLightHelper);
    this.rectAreaLightHelper.color = new THREE.Color(0, 0, 0);
    this.rectAreaLightHelper.update();

    this.display = new THREE.Group();
    this.display.position.set(x + 0.1, y, z + 0.1);
    this.display.rotation.set(0, 0.95 + (Math.PI * 2), 0);
    scene.add(this.display);
  }

  setDisplayText(text) {
    for (let i = this.display.children.length - 1; i >= 0; i -= 1) {
      this.display.remove(this.display.children[i]);
    }

    const newDisplay = createText(
      text,
      undefined,
      5,
    );
    const [x, y, z] = this.position;
    this.display.position.set(x + 0.1, y, z + 0.1);
    this.display.rotation.set(0, 0.95 + (Math.PI * 2), 0);
    this.display.add(newDisplay);
  }

  update(audioStream) {
    if (audioStream) {
      audioStream.renderFftData();
      const audioData = audioStream.analyser.data[0];
      const hex = audioStream.mapRange(Math.max(190, audioData), 190, 255, 0, 255);
      this.rectAreaLight.intensity = hex;
      this.rectAreaLightHelper.color = new THREE.Color(hex, hex, hex);
      this.rectAreaLightHelper.update();
    }
  }
}
