import * as THREE from 'three';
import { MeshLine, MeshLineMaterial } from 'three.meshline';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import VirtualSpaceInterface from '../../../oxxxo/VirtualSpaceInterface';
// import { HTMLMesh } from 'three/addons/interactive/HTMLMesh';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import Raycaster from '../../../three-utils/Raycaster';
import Blob2 from '../../../three-utils/Blob2';
import ErkkiObject from '../../../three-utils/Kurenniemi/ErkkiObject';
import { meshToHeightField, generateTerrain } from "../../../three-utils/terrainHelper";
import body2mesh from "../../../three-utils/body2Mesh";
import Flag from '../../../three-utils/flag/Flag';
import Wind from '../../../three-utils/flag/Wind';

class VirtualSpace extends VirtualSpaceInterface {

  createSpace = () => {


    this.flag = new Flag(
      this.scene,
      this.physicsWorld,
      '/textures/kurenniemi/kurenniemi-description-flag.png',
      new THREE.Vector3(120, 2, 30),
      true,
      4,
      1,
      4,
      5
    );
    
    this.wind = new Wind(this.flag.mesh);


    this.scene.fog = new THREE.Fog(0x661022, 10, 100);

    const light = new THREE.AmbientLight(0xFFFFFF, 0.01);
    this.scene.add(light);
    this.scene.background = new THREE.Color(0x661022);

  
    const light1 = new THREE.PointLight(0xFFFFFF, 3, 80);
    light1.position.set(0, 50, 0);
    this.scene.add(light1);
/*
    const light2 = new THREE.PointLight(0xFFFFFF, 13, 280);
    light2.position.set(355, 30, 255);
    this.scene.add(light2);
    */
    /*
    const sphere = new THREE.SphereBufferGeometry(5, 8, 8);
		light1.add(
      new THREE.Mesh(sphere, new THREE.MeshStandardMaterial({
        color: 0xFFFFFF,
        emissive:0xFFFFFF,
        specular: 0xFFFFFF,
        emissiveIntensity: 10,
        shininess: 500
      })
    ));
    */
  


    const loader = new GLTFLoader();
  
  
    const terrainPhysics = generateTerrain();

    const textureLoader = new THREE.TextureLoader();




      textureLoader.load('textures/galeria/piedra.jpg', (texture) => {








      const groundTexture = texture;
      groundTexture.wrapS = THREE.RepeatWrapping;
      groundTexture.wrapT = THREE.RepeatWrapping;
      groundTexture.encoding = THREE.sRGBEncoding;
      groundTexture.repeat.set(90, 90);
      groundTexture.anisotropy = 8;
      const groundMaterial = new THREE.MeshStandardMaterial({
        map: groundTexture,
      });
     //  groundMaterial.map.needsUpdate = true;

      const mesh = new THREE.Mesh(new THREE.PlaneGeometry(500, 500), groundMaterial);
      // mesh.receiveShadow = true;
      mesh.rotation.x = -Math.PI / 2;
      this.scene.add(mesh);




 
      const terrainTexture = texture.clone();
      terrainTexture.wrapS = THREE.RepeatWrapping;
      terrainTexture.wrapT = THREE.RepeatWrapping;
      terrainTexture.encoding = THREE.sRGBEncoding;
      terrainTexture.repeat.set(0.1, 0.1);
      terrainTexture.anisotropy = 8;
      const terrainMaterial = new THREE.MeshStandardMaterial({
        map: terrainTexture,
        side: THREE.DoubleSide,
      });
      terrainMaterial.map.needsUpdate = true;

      const terrainMesh = body2mesh(terrainPhysics, terrainMaterial);
      terrainMesh.rotation.set(-Math.PI / 2, 0, 0);
      const group = new THREE.Object3D();
      group.add(terrainMesh);
      group.position.set(-250, 8, 250);


      const terrainMesh2 = body2mesh(terrainPhysics, terrainMaterial);
      terrainMesh2.rotation.set(-Math.PI / 2, 0, 0);
      const group2 = new THREE.Object3D();
     // group2.add(terrainMesh2);
      group2.position.set(-250, 30, 250);



      terrainPhysics.position.set(-250, 8, 250);



      this.scene.add(group);
     this.scene.add(group2);
     this.physicsWorld.addBody(terrainPhysics);


    });


   //  this.initEscultura();

    this.erkki = new ErkkiObject(this.userAvatar, this.audioStreams[0].audio);
    this.scene.add(this.erkki.getObject3D());







    this.cubeCamera = new THREE.CubeCamera(250, 400, 1024);
    // this.cubeCamera.scale.set(255,255,255)

    const sphereGeometry = new THREE.SphereGeometry(250, 64, 16 );
     
     const reflectiveMaterial = new THREE.MeshBasicMaterial({
      envMap: this.cubeCamera.renderTarget.texture,
      shininess: 50,
      color: 0xffffff,
     // side: THREE.DoubleSide // Enable reflections from both sides
  });
  const reflectiveSphere = new THREE.Mesh(sphereGeometry, reflectiveMaterial);

  this.scene.add(this.cubeCamera);



    

this.scene.add(reflectiveSphere);

const directionalLight = new THREE.DirectionalLight(0xffffff, .50);
directionalLight.position.set(1, 1, 1).normalize();
this.scene.add(directionalLight);

const fbxLoader = new FBXLoader();
  fbxLoader
  .load('/models/maruchan1.fbx', (model) => {
    console.log({model});
    const stemGeometry = new THREE.InstancedBufferGeometry();
    THREE.BufferGeometry.prototype.copy.call( stemGeometry, model.children[0].geometry );
    stemGeometry.scale(0.0001, 0.0001, 0.0001);
    const defaultTransform = new THREE.Matrix4()
    .multiply(new THREE.Matrix4().makeScale( 7, 7, 7 ));
  stemGeometry.applyMatrix4( defaultTransform );

  const count = 4000;
  const mesh = new THREE.InstancedMesh(stemGeometry, model.children[0].material, count);
  const amount = 15;
  let i = 0;
  const offset = (amount - 1) / 2;
  const transform = new THREE.Object3D();
  for(let x = 0; x < amount; x+=1) {
    for(let y = 0; y < amount; y+=1) {
      for(let z = 0; z < amount; z+=1) {
        transform.position.set(offset - x, offset - y, offset - z);
        transform.rotation.set(offset - x, offset - y, offset - z);
        transform.updateMatrix();
        mesh.setMatrixAt(i+=1, transform.matrix);
      }
    }
  }
  mesh.position.set(-25,7,-20)
  this.scene.add(mesh);
  })


  }

  initEscultura = () => {
    const curve = new THREE.CubicBezierCurve3(
      new THREE.Vector3(-1, 0, 0),
      new THREE.Vector3(-1, 1.5, 3),
      new THREE.Vector3(2, 1.5, -2),
      new THREE.Vector3(1, 0, 2),
      new THREE.Vector3(1, 1, 0),
      new THREE.Vector3(-1, -1.5, 3),
      new THREE.Vector3(-2, 1.5, 4),
      new THREE.Vector3(-1, 1, 2),
    );
    const pointsCurve = curve.getPoints(150);
    const geometryCurve = new THREE.BufferGeometry().setFromPoints(pointsCurve);
    const lineCurve = new MeshLine();
    lineCurve.setGeometry(geometryCurve);

    const x = new THREE.Geometry().fromBufferGeometry(lineCurve);

    this.blob = new Blob2(1, 28, 28, x); // 128

    const materialCurve = new MeshLineMaterial({ color: new THREE.Color(0xFF0099), lineWidth: 10 });

    const splineObject = new THREE.Line(this.blob.getGeometry(), materialCurve);
    splineObject.position.set(-5, 3, 4);
    // splineObject.scale.set(.05,.05,.05)
    //  this.scene.add(splineObject);

    const rand = (offset, amount) => offset + (0.5 - Math.random()) * amount;

    for (let x = 0; x < 100; x += 1) {
      const splineObject = new THREE.Line(this.blob.getGeometry(), new MeshLineMaterial({ color: new THREE.Color(Math.random() * 0xFF00FF) }));
      splineObject.position.set(rand(5, 3), rand(3, 3), rand(-10, 3));
      splineObject.rotation.set(rand(0, 3), rand(0, 3), rand(0, 3));
      splineObject.scale.set(2, 2, 2);
      this.scene.add(splineObject);
    }

    /*
    const sphereBlob4 = new THREE.Mesh(
      this.blob.getGeometry(),
      new THREE.MeshPhongMaterial({
        color: new THREE.Color(0xBAF7C7),
      }),
    );

    sphereBlob4.position.set(4,3,2);
    sphereBlob4.scale.set(2, 2, 2);
    this.scene.add(sphereBlob4);
        */
  }

  applyDynamicSettingsChanges(data) {
    if (data.turnOnFog) {
      this.scene.fog = new THREE.Fog(0x661022, 10, 100);
    } else {
      this.scene.fog = null;
    }
  }

  update = (renderer) => {
    // const distance = this.userAvatar.getPhysicsBody().position.distanceTo(new THREE.Vector3(0, 0, 0));
  //eslint-disable-next-line no-unused-expressions 
    this.blob?.update();
  //eslint-disable-next-line no-unused-expressions 
   this.erkki?.update();
   if (this.cubeCamera){
    this.cubeCamera.update(this.renderer, this.scene);
   }

   /*
    const distance = this.camera.position.distanceTo(new THREE.Vector3(0, 0, 0));

    // Check if the object is within the desired fog distance
    if (distance >= 255) {
      this.scene.fog = null;

    } else {

        this.scene.fog = new THREE.Fog(0x661022, 10, 100);
    }
    */

    if (this.flag && this.wind) {
      this.wind.update();
      this.flag.update();
      this.flag.applyWind(this.wind);
    }
    

  }
}
export default VirtualSpace;
