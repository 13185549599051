import * as THREE from 'three';
// import { CSS3DRenderer, CSS3DObject } from 'three/addons/renderers/CSS3DRenderer.js';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';
// import { avatarModels } from './modelsLoader';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import createText from "../../../three-utils/createText";
import VirtualSpaceInterface from '../../../oxxxo/VirtualSpaceInterface';

/*
import exportGLTF from '../util/exportGLTF';
import Kinectron from '../lib/kinectron-client';
import KinectGeometry from '../lib/kinectGeometry';
*/

export default class VirtualSpace extends VirtualSpaceInterface {

  createSpace = () => {
    const textureLoader = new THREE.TextureLoader();


    const colladaLoader = new ColladaLoader();
    colladaLoader.load('/models/cumple/oxxxo.dae', ({ scene }) => {
  
      this.scene.add(scene);
    });

    colladaLoader.load('/models/cumple/oxxxo_physics.dae', ({ scene }) => {
   
      scene.rotation.z = 1;
      scene.position.y = 0;
      scene.position.x = -10;
      scene.position.z = -6;
       // const shape = threeToCannon(avatarModels.oxxoPhysics.scene, {type: threeToCannon.Type.BOX});
       // const shape = threeToCannon(scene);
      //  this.physicsWorld.addBody(scene);
      this.scene.add(scene);
    });


    const mtlLoader = new MTLLoader();
    mtlLoader.setPath('models/cumple/baby/');
    mtlLoader.load("baby.mtl", (materials) => {
        materials.preload();
        const objLoader = new OBJLoader();
        objLoader.setMaterials(materials);
        objLoader.setPath('models/cumple/baby/');
        objLoader.load('baby.obj', (object) => {
          const babyOne =  object.clone();
          babyOne.position.y = 0;
          babyOne.position.x = 10;
          babyOne.position.z = -4;
          babyOne.scale.set(.1,.1,.1);
          babyOne.rotation.x = 180;
          this.scene.add(babyOne);

          const babyTwo =  object.clone();
          babyTwo.position.y = 0;
          babyTwo.position.x = 0;
          babyTwo.position.z = 3;
          // babyTwo.scale.set(1,1,1);
          this.scene.add(babyTwo);
        });
    });

    textureLoader.load('textures/cumple/360/porn2.jpeg', (texture) => {
      // texture.mapping = THREE.UVMapping;
      const fondo = new THREE.Mesh(new THREE.SphereBufferGeometry(10, 32, 16),
                              new THREE.MeshBasicMaterial({ map: texture }));
      fondo.material.lightMapIntensity = .02;
      fondo.material.side = THREE.DoubleSide;
      fondo.position.set(-30, 5, 0);
      this.interactiveObjects.ThreeSixtyBackground = fondo;
      this.scene.add(fondo);
  	});

    // instructions
    const instructions = createText(
      ` Para moverte usa las flechas\n
        y las teclas A/S/D/W/ESPACIO\n
        O gira tu cel 90 grados \n
        para ver los controles`,
        0x14a669, .2);
    instructions.position.set(0, 3, -7);
    this.scene.add(instructions);




    const display1 = new THREE.Group();
    display1.position.set(-8.3, 2.5, -12.5);
    this.interactiveObjects.display1 = display1;
    this.scene.add(display1);

    const display2 = new THREE.Group();
    display2.position.set(22, 1.5, -5);
    display2.rotation.y = -1.5;
    this.interactiveObjects.display2 = display2;
    this.scene.add(display2);

    /*
    mtlLoader.setPath('models/cristy/');
    mtlLoader.load("model.mtl", (materials) => {
        materials.preload();
        const objLoader = new OBJLoader();
        objLoader.setMaterials(materials);
        objLoader.setPath('models/cristy/');
        objLoader.load('model.obj', (object) => {
          const loadedModel =  object.clone();
          loadedModel.position.y = 1;
          loadedModel.position.x = 0;
          loadedModel.position.z = 0;
          loadedModel.scale.set(1,1,1);
          loadedModel.rotation.x = 160;
          this.scene.add(loadedModel);
        });
    });
    */
    const light = new THREE.AmbientLight(0x707070); // soft white light
    this.scene.add( light );

		const width = 5;
		const height = 5;
		const blueRectLight = new THREE.RectAreaLight(0xf3aaaa, 0, width, height);
		blueRectLight.position.set(25.3, 1, -5);
		blueRectLight.rotation.set(0, Math.PI / 2, 0);
	  //	blueRectLight.lookAt(0, 1, 0);
		this.scene.add(blueRectLight);
		const blueRectLightHelper = new RectAreaLightHelper(blueRectLight, 0xffffff);
		blueRectLight.add(blueRectLightHelper);
    blueRectLightHelper.color = new THREE.Color(1, 0, 0);
    blueRectLightHelper.update();
    this.interactiveObjects.blueRectLight = blueRectLight;
    this.interactiveObjects.blueRectLightHelper = blueRectLightHelper;

		const sphere = new THREE.SphereBufferGeometry(0.05, 16, 8);
		const light1 = new THREE.PointLight(0xff0000, 1, 10);
		light1.add(new THREE.Mesh(sphere, new THREE.MeshPhongMaterial( {
		  color: 0xff0000,
		  emissive:0xff0000,
		  specular: 0xff0000,
		  emissiveIntensity: 10,
		  shininess: 500})
    ));
		light1.castShadow = true;
    light1.position.set(-10, 1, -25);
    this.interactiveObjects.bulbLight = light1;
    this.scene.add(light1);

    const geometry = new THREE.BoxGeometry(0.5, 0.5, 0.5);
    const material = new THREE.MeshNormalMaterial();
    this.interactiveObjects.cube = new THREE.Mesh(geometry, material);
    this.interactiveObjects.cube.position.set(15, 1, -5);
    this.scene.add(this.interactiveObjects.cube);

    // TODO
    // this.rayInput.add( this.interactiveObjects['cube']  );
    // this.scene.add(this.rayInput.getMesh());


		const floorMat = new THREE.MeshStandardMaterial({
			roughness: 0.8,
			color: 0xffffff,
			metalness: 0.2,
			bumpScale: 0.0005
		});

		textureLoader.load("textures/cumple/hardwood2_diffuse.jpg", (map) => {
			map.wrapS = THREE.RepeatWrapping;
			map.wrapT = THREE.RepeatWrapping;
			map.anisotropy = 4;
			map.repeat.set(40, 96);
			floorMat.map = map;
			floorMat.needsUpdate = true;
  	});
		textureLoader.load("textures/cumple/hardwood2_bump.jpg", (map) => {
			map.wrapS = THREE.RepeatWrapping;
			map.wrapT = THREE.RepeatWrapping;
			map.anisotropy = 4;
			map.repeat.set(40, 96);
			floorMat.bumpMap = map;
			floorMat.needsUpdate = true;
    });
		textureLoader.load("textures/cumple/hardwood2_roughness.jpg", (map) => {
			map.wrapS = THREE.RepeatWrapping;
			map.wrapT = THREE.RepeatWrapping;
			map.anisotropy = 4;
			map.repeat.set( 40, 96 );
			floorMat.roughnessMap = map;
			floorMat.needsUpdate = true;
    });

    const floorGeometry = new THREE.PlaneBufferGeometry(80, 80);
    const floorMesh = new THREE.Mesh(floorGeometry, floorMat);
		floorMesh.receiveShadow = true;
		floorMesh.rotation.x = - Math.PI / 2.0;
		this.scene.add(floorMesh);
    floorMat.needsUpdate = true;

  }

}
