import * as THREE from "three";
import * as CANNON from 'cannon-es';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer';
import VirtualSpaceInterface from '../../../oxxxo/VirtualSpaceInterface';

// import { MeshLine, MeshLineMaterial } from 'three.meshline';
import Sanity from "../../../util/sanity";
import createText from "../../../three-utils/createText";
import checkIfFileExists from "../../../util/checkIfFileExists";
import BlobObject from "../../../three-utils/BlobObject";
import Flag from "../../../three-utils/flag/Flag";
import Wind from "../../../three-utils/flag/Wind";
import GifAnimation from "../../../three-utils/GifAnimation";
import FlagShader from "../../../three-utils/FlagShader";
import VideoShader from "../../../three-utils/VideoShader";
import Dissevelt from "../../../three-utils/Dissevelt";
import BanderaGif from "../../../three-utils/flag/textures/test-gif.png";
// import GalloDdeOro from "../../../three-utils/flag/textures/gallo.jpg";
//import GifPrueba from "/gifs-gallery/gif-prueba.png";
import {meshToHeightField, createPlatform, generateTerrain} from '../../../three-utils/terrainHelper';


function TextureAnimator(texture, tilesHoriz, tilesVert, numTiles, tileDispDuration)
{
  // note: texture passed by reference, will be updated by the update function.

  this.tilesHorizontal = tilesHoriz;
  this.tilesVertical = tilesVert;
  // how many images does this spritesheet contain?
  //  usually equals tilesHoriz * tilesVert, but not necessarily,
  //  if there at blank tiles at the bottom of the spritesheet.
  this.numberOfTiles = numTiles;
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set( 1 / this.tilesHorizontal, 1 / this.tilesVertical );

  // how long should each image be displayed?
  this.tileDisplayDuration = tileDispDuration;

  // how long has the current image been displayed?
  this.currentDisplayTime = 0;

  // which image is currently being displayed?
  this.currentTile = 0;

  this.update = function( milliSec )
  {
    this.currentDisplayTime += milliSec;
    while (this.currentDisplayTime > this.tileDisplayDuration)
    {
      this.currentDisplayTime -= this.tileDisplayDuration;
      this.currentTile++;
      if (this.currentTile == this.numberOfTiles)
        this.currentTile = 0;
      var currentColumn = this.currentTile % this.tilesHorizontal;
      texture.offset.x = currentColumn / this.tilesHorizontal;
      var currentRow = Math.floor( this.currentTile / this.tilesHorizontal );
      texture.offset.y = currentRow / this.tilesVertical;
    }
  };
}

class VirtualSpace extends VirtualSpaceInterface {

  createSpace = () => {
    const fbxLoader = new FBXLoader();
    const colladaLoader = new ColladaLoader();

    const light = new THREE.AmbientLight("white");
    this.scene.add(light);


    const loader = new GLTFLoader();
  //  const textureLoader = new THREE.ImageUtils.loadTexture();


    this.initHumanStairs();



colladaLoader.load('models/oxxxo.dae', (model) => {

  this.scene.add(model.scene.children[0])
});
/*
    loader.load('models/terrain.glb', (gltf) => {
      const terrain = gltf.scene;
      terrain.position.set(0,-5,0)
      terrain.scale.set(.1,.1,.1)
      const heightField = meshToHeightField(terrain);

      this.scene.add(terrain);
      this.physicsWorld.addBody(heightField);
    });
*/
// this.physicsWorld.addBody(generateTerrain());

loader.load('models/truchet.glb', (gltf) => {
  const terrain = gltf.scene;
  terrain.position.set(0,0,0)
  terrain.scale.set(.1,.1,.1)
  this.scene.add(terrain);
});
    // instructions
    const instructions = createText(
      `FONIMA`,
      0x000000, 1);
    instructions.position.set(50, 4, -4);
    this.scene.add(instructions);

    this.textureLoader.load("textures/bandera.png", (texture) => {
      const geometryT = new THREE.PlaneGeometry(10,5);
      const materialT = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true
      });
      const fonimaMesh = new THREE.Mesh(geometryT, materialT);
      fonimaMesh.position.set(50, 4.5, -5);
      this.scene.add(fonimaMesh);
    });


      this.interactiveObjects.instructions = []

    /*
    const geometry = new THREE.BoxGeometry(1, 1, 1);

		const material = new THREE.MeshBasicMaterial({color: new THREE.Color(0xA792FF)});
    const cube = new THREE.Mesh(geometry, material);
    cube.position.set(5,1,0);
  //  cube.position.set(-195,2,135);
    this.scene.add(cube);



		const material2 = new THREE.MeshBasicMaterial({color: new THREE.Color(0xFFB592)});
		const cube2 = new THREE.Mesh(geometry, material2);
		cube2.position.set(3,3,1);
		this.scene.add(cube2);

		const material3 = new THREE.MeshBasicMaterial( {color: new THREE.Color(0xBAF7C7)} );
		const cube3 = new THREE.Mesh(geometry, material3);
		cube3.position.set(1,2,3);
		this.scene.add(cube3);






    const geo = new THREE.EdgesGeometry(geometry); // or WireframeGeometry( geometry )
    const mat = new THREE.LineBasicMaterial({ color: new THREE.Color( 0x000000 ), linewidth: 2 });
		const wireframe = new THREE.LineSegments(geo, mat);
		wireframe.position.set(5, 2, 0);
    this.scene.add(wireframe);

		const edges = new THREE.EdgesHelper(cube, new THREE.Color( 0x000000));
    edges.material.linewidth = 5;
    edges.position.set(0,2,0);
    this.scene.add(edges);


    const semiCirclePoints = [];
		for (let j = 0; j <= Math.PI; j += (2 * Math.PI) / 50) {
			semiCirclePoints.push(Math.cos(j), Math.sin(j), 0);
		}

		const line = new MeshLine();
		line.setPoints(semiCirclePoints);
		const	semiCircleMaterial = new MeshLineMaterial({color: new THREE.Color( 0x000000), lineWidth: .3});
		const mesh = new THREE.Mesh(line, semiCircleMaterial);
		mesh.position.set(4,3,5)
		mesh.scale.set(1.5,1.5,1.5)
		this.scene.add(mesh);
		// this.interactiveObjects.lineas.push(mesh)

*/




    this.espiropapaUpdate = this.espiropapa();


/*

		const curve = new THREE.SplineCurve( [
			new THREE.Vector2( -10, 0 ),
			new THREE.Vector2( -5, 5 ),
			new THREE.Vector2( 0, 0 ),
			new THREE.Vector2( 5, -5 ),
			new THREE.Vector2( 10, 0 ),

      new THREE.Vector2( 15, 0 ),
			new THREE.Vector2( 20, 5 ),
			new THREE.Vector2( 25, 0 ),
			new THREE.Vector2( 30, -5 ),
			new THREE.Vector2( 35, 0 ),

		]);

		const pointsCurve = curve.getPoints(50);
		const geometryCurve = new THREE.BufferGeometry().setFromPoints(pointsCurve);
		const lineCurve = new MeshLine();
		lineCurve.setGeometry(geometryCurve);
		const materialCurve = new MeshLineMaterial({color: new THREE.Color(0x000000), lineWidth: 4});
		const splineObject = new THREE.Line( lineCurve, materialCurve );
		splineObject.position.set(25,3,5)

		this.scene.add(splineObject);
*/
		this.textureLoader.load("textures/fonima.png", (texture) => {
			const geometryT = new THREE.PlaneGeometry(3,3);
			const materialT = new THREE.MeshBasicMaterial({
				map: texture,
				side: THREE.DoubleSide,
				transparent: true
			});
			const fonimaMesh = new THREE.Mesh(geometryT, materialT);
			fonimaMesh.position.set(0,2,0);
			this.interactiveObjects.rotante = fonimaMesh;
			this.scene.add(fonimaMesh);
		});



		const groundWhiteMaterial = new THREE.MeshToonMaterial( {color: new THREE.Color("white") });
		const groundMesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(1000, 1000), groundWhiteMaterial);
		groundMesh.receiveShadow = true;
		groundMesh.rotation.x = - Math.PI / 2;
		this.scene.add(groundMesh);

		this.flag = new Flag(this.scene, this.physicsWorld, BanderaGif, new THREE.Vector3(-4,3,6), true);
		this.wind = new Wind(this.flag.mesh);
//(138,74,280)
  //  this.flag2 = new Flag(this.scene, this.physicsWorld, GalloDdeOro, new THREE.Vector3(-65,2,65), false);


      // blob

      this.blob = new BlobObject(1, 128, 128);

      this.initMainBlob();

      this.createWireframeSpace();





// esfera interactivx

    const sphereGeometry2 = new THREE.SphereGeometry(1, 64, 64);
          const geo2 = new THREE.EdgesGeometry(sphereGeometry2); // or WireframeGeometry( geometry )
          const mat2 = new THREE.LineBasicMaterial({ color: new THREE.Color( 0x000000 ), linewidth: 2 });
          const wireframe2 = new THREE.LineSegments(geo2, mat2);
          wireframe2.position.set(230,52,275);
          wireframe2.scale.set(40,40,40);
          this.scene.add(wireframe2);

    const wSphereInt = new THREE.Mesh(sphereGeometry2, new THREE.MeshBasicMaterial({side: THREE.BackSide}));
    wSphereInt.position.set(230,52,275);
    wSphereInt.scale.set(40,40,40);
    this.scene.add(wSphereInt)

    // 250,52,275 / 37, 0, 1.5
    this.dissevelt = new Dissevelt(this.scene, this.camera, [230,52,275]);

    const pisoInteractivx = new CANNON.Cylinder( 44,  44,  1,  16 )
    const pisoInteractivxBody = new CANNON.Body({mass: 0, shape: pisoInteractivx});
    pisoInteractivxBody.position.set(230,52,275);

        const axis = new CANNON.Vec3(1,0,0);
const angle = 1*Math.PI / 2;
pisoInteractivxBody.quaternion.setFromAxisAngle(axis, angle);

    this.physicsWorld.addBody(pisoInteractivxBody);


   // this.initTunnel([-177, 2, -2]);
    //this.createPoints(400000,[221,25,6]);

/*
    this.initStairs();
    this.initInstancedStairs();
    this.initFonimaDomo();
    this.initVideoDomo();
*/






	}

  initTunnel = (position=[0,2,0]) => {
    const [x, y, z] = position;
    const insideGeo = new THREE.SphereGeometry(1, 128, 128);
    const interiorMat = new THREE.MeshBasicMaterial({ color: new THREE.Color( 0x000000 ),side: THREE.BackSide });
    const interior = new THREE.LineSegments(insideGeo, interiorMat);
    interior.position.set(x, y, z);
    interior.scale.set(70,10,10);
    this.scene.add(interior);

  const interiorMatCirculo = new THREE.MeshBasicMaterial({ color: new THREE.Color( 0x000000 ),side: THREE.BackSide });
    const interiorCirculo = new THREE.LineSegments(insideGeo, interiorMatCirculo);
  //  interiorCirculo.position.set(77,2,135);
    interiorCirculo.position.set(x, y, z);
    interiorCirculo.scale.set(10,10,10);
    this.scene.add(interiorCirculo);

    const insideGeoFondo = new THREE.SphereGeometry(1, 128, 128);
  const interiorMatFondo = new THREE.MeshBasicMaterial({ color: new THREE.Color( 0xFFFFFF ),side: THREE.BackSide });
    const interiorFondo = new THREE.Mesh(insideGeoFondo, interiorMatFondo);
    interiorFondo.position.set(x, y, z);
    interiorFondo.scale.set(70,10,10);
    this.scene.add(interiorFondo);

    /*
    this.flagShader = new FlagShader(GalloDdeOro);
    const flagShaderMesh = this.flagShader.getMesh();
    flagShaderMesh.position.y = 5;
    this.scene.add(flagShaderMesh);
    */
  }

  initVideoDomo = () => {

    this.video = document.getElementById('video');
    const source = document.createElement('source');
    source.setAttribute('src', 'videos/ejemplo.mp4');
    this.video.appendChild(source);
    // this.video.play();
        /*
    		video.addEventListener('play', function() {
    			this.currentTime = 3;
          console.log("playing?")
    		}, false);
    */
    const	videoTexture = new THREE.VideoTexture(this.video);
    const geometryT = new THREE.PlaneGeometry(3,3);
    const geometryVideo = new THREE.SphereGeometry(30, 16, 16);
    const materialT = new THREE.MeshBasicMaterial({
      map: videoTexture,
      side: THREE.DoubleSide,
      transparent: true
    });

    const videoSphere = new THREE.Mesh(geometryVideo, materialT);
    videoSphere.position.set(170,1.8,-370);
    this.scene.add(videoSphere);
    /*

    const videoMesh = new THREE.Mesh(geometryT, materialT);
    videoMesh.position.set(2,2,5);
    this.scene.add(videoMesh);


    const cylinderGeometry = new THREE.CylinderGeometry( 15, 15, 40, 32, 8, true );
    const videoCylinder = new THREE.Mesh(cylinderGeometry, materialT);
    videoCylinder.position.set(157,2,101);
    this.scene.add(videoCylinder);

    */
  }

  createPoints = (cuantos=400000, donde=[-17,3,-9]) => {

    const particles = cuantos;

				const geometry = new THREE.BufferGeometry();

				// create a generic buffer of binary data (a single particle has 16 bytes of data)

				const arrayBuffer = new ArrayBuffer( particles * 16 );

				// the following typed arrays share the same buffer

				const interleavedFloat32Buffer = new Float32Array( arrayBuffer );
				const interleavedUint8Buffer = new Uint8Array( arrayBuffer );

				//

				const color = new THREE.Color();

				const n = 80;
        const n2 = n / 2; // particles spread in the cube

				for ( let i = 0; i < interleavedFloat32Buffer.length; i += 4 ) {

					// position (first 12 bytes)

					const x = Math.random() * n - n2;
					const y = Math.random() * n - n2;
					const z = Math.random() * n - n2;

					interleavedFloat32Buffer[ i + 0 ] = x;
					interleavedFloat32Buffer[ i + 1 ] = y;
					interleavedFloat32Buffer[ i + 2 ] = z;

					// color (last 4 bytes)

					const vx = ( x / n ) + 0.5;
					const vy = ( y / n ) + 0.5;
					const vz = ( z / n ) + 0.5;

					color.setRGB( vx, vy, vz );

					const j = ( i + 3 ) * 4;

					interleavedUint8Buffer[ j + 0 ] = color.r * 255;
					interleavedUint8Buffer[ j + 1 ] = color.g * 255;
					interleavedUint8Buffer[ j + 2 ] = color.b * 255;
					interleavedUint8Buffer[ j + 3 ] = 0; // not needed

				}

				const interleavedBuffer32 = new THREE.InterleavedBuffer( interleavedFloat32Buffer, 4 );
				const interleavedBuffer8 = new THREE.InterleavedBuffer( interleavedUint8Buffer, 16 );

				geometry.setAttribute( 'position', new THREE.InterleavedBufferAttribute( interleavedBuffer32, 3, 0, false ) );
				geometry.setAttribute( 'color', new THREE.InterleavedBufferAttribute( interleavedBuffer8, 3, 12, true ) );


				const material = new THREE.PointsMaterial({ size: .1, vertexColors: true});

				const points = new THREE.Points(geometry, material);
        points.position.set(...donde)
				this.scene.add(points);

  }

  initMainBlob = () => {
    // escenario
    const sphereBlob2 = new THREE.Mesh(
      this.blob.getGeometry(),
      new THREE.MeshNormalMaterial({
        side: THREE.DoubleSide
      })
    );
    //sphereBlob2.position.set(190,2,-313.5);
    sphereBlob2.position.set(400,2,-50);
    sphereBlob2.scale.set(180,180,180);
    this.scene.add(sphereBlob2);

  }

  initFonimaDomo = () => {
    // salmon
    const sphereBlob = new THREE.Mesh(
      this.blob.getGeometry(),
      new THREE.MeshBasicMaterial({
        side: THREE.BackSide, color: new THREE.Color(0xFFB592)
      })
    );
    sphereBlob.position.set(-202.38,2,146.69);
    sphereBlob.scale.set(35,35,35);
    this.scene.add(sphereBlob);

    // verde
    const sphereBlob4 = new THREE.Mesh(
      this.blob.getGeometry(),
      new THREE.MeshBasicMaterial({
        side: THREE.FrontSide, color: new THREE.Color(0xBAF7C7)
      })
    );
    sphereBlob4.position.set(-202.38,2,146.69);
    sphereBlob4.scale.set(45,45,45);
    this.scene.add(sphereBlob4);

    // morado
    const sphereBlob3 = new THREE.Mesh(
      this.blob.getGeometry(),
      new THREE.MeshBasicMaterial({
        side: THREE.BackSide, color: new THREE.Color(0xA792FF)
      })
    );
    sphereBlob3.position.set(-202.38,2,146.69);
    sphereBlob3.scale.set(34,36,33);
    this.scene.add(sphereBlob3);

    const items = 20;
    let centerX = 50;
    let centerY = 2;
    let radius = 10;
    const textGroup = new THREE.Group();

    for (let i=0; i<items; i++) {
      const x = centerX + radius * Math.sin(2 * Math.PI * i / items);
      const z = centerY + radius * Math.cos(2 * Math.PI * i / items);
      const text = createText(`FONIMA`, 0x000000, 2);
      text.position.set(x, z, 2);
      text.rotation.y = Math.PI /2;
      textGroup.add(text)
    }

    const box = new THREE.Box3().setFromObject(textGroup);
    box.getCenter(textGroup.position);
    textGroup.position.multiplyScalar(-1);
    const pivot = new THREE.Group(-202.38, 0, 146.69);
    pivot.position.set(-202.38,10,146.69);
    pivot.add(textGroup);
    this.scene.add(pivot);
    this.interactiveObjects.textGroup = pivot;
  }

  initStairs = () => {

    for (let step = 1; step<120; step+=1.5) {
            const anchura = 10;
            const profundidad = 1;
            const altura = .2;
            const posX = 45;
            const posY = 0;
            const posZ = 70;

            const escalera = new CANNON.Box(new CANNON.Vec3(anchura, altura*step, profundidad*step))
            const escaleraBody = new CANNON.Body({mass: 0, shape: escalera});
            escaleraBody.position.set(
                posX + 0,
                posY + (altura*2*step),
                posZ + (profundidad * 2 * step));

                const axis = new CANNON.Vec3(0,1,0);
const angle = (step*.01)*Math.PI / 2;
escaleraBody.quaternion.setFromAxisAngle(axis, angle);

            this.physicsWorld.addBody(escaleraBody);





const geometryEscalera = new THREE.BoxGeometry(anchura*2, altura*2*step, profundidad*2*step);




/*

const mesh = new THREE.Mesh(geometryEscalera, new THREE.MeshBasicMaterial());
  mesh.position.set(
      posX + 0,
      posY + (altura*2*step),
      posZ + (profundidad * 2 * step));
        mesh.rotation.y=(step*.01)*Math.PI / 2;
    //  this.scene.add(mesh)
*/

/*
                            const wireframe = new THREE.WireframeGeometry( geometryEscalera );

                            const line = new THREE.LineSegments( wireframe );
                            line.material.depthTest = true;
                            line.material.opacity = 0.25;
                            line.material.transparent = false;
                            line.material.color = new THREE.Color(0x000000);
                            line.position.set(
                                posX + 0,
                                posY + (altura*2*step),
                                posZ + (profundidad * 2 * step));
                              line.rotation.y=(step*.01)*Math.PI / 2;

            this.scene.add(line);
            */
        }

    this.gif = new GifAnimation(
      "/gifs-gallery/gif-prueba.png",
      new THREE.Vector3(-494,1.5,-15),
      new THREE.Vector3(-Math.PI/2, Math.PI/2, 0),
      [2,2],
      [5, 10, 49, 15]
    );

    this.scene.add(this.gif.getMesh());
  }

  initInstancedStairs = () => {

    const count = 100;
    const geometry = new THREE.BoxGeometry(1,1,2);
    const material = new THREE.MeshBasicMaterial();
    const wireframeMaterial = new THREE.MeshBasicMaterial({
      wireframe: true,
      color: "black"
    });
    const mesh = new THREE.InstancedMesh(geometry, material, count);
    const wireframeMesh = new THREE.InstancedMesh(geometry, wireframeMaterial, count);
//    mesh.instanceMatrix.setUsage(THREE.DynamicDrawUsage); // will be updated every frame

    this.scene.add(mesh);
    this.scene.add(wireframeMesh);
    const dummy = new THREE.Object3D();

    //  mesh.instanceMatrix.needsUpdate = true;
    const anchura = 10;
    const profundidad = 1;
    const altura = .2;
    const posX = 45;
    const posY = 0;
    const posZ = 70;
    const stepSize = 1.5;
    let step = 1;

    for (let i = 1; i<81; i+=1) {
      dummy.scale.set(anchura*2, altura*step*2, profundidad*step);
      dummy.position.set(
        posX + 0,
        posY + (altura*2*step),
        posZ + (profundidad*2*step));
      dummy.rotation.y=(step*.01)*Math.PI/2;
      dummy.updateMatrix();
      mesh.setMatrixAt( i, dummy.matrix );
      wireframeMesh.setMatrixAt( i, dummy.matrix );
      step += stepSize;
    }

}


initHumanStairs = () => {

  const count = 30;
  const geometry = new THREE.BoxGeometry(1,1,2);
  const material = new THREE.MeshBasicMaterial();
  const wireframeMaterial = new THREE.MeshBasicMaterial({
    wireframe: true,
    color: "black"
  });
  const mesh = new THREE.InstancedMesh(geometry, material, count);
  const wireframeMesh = new THREE.InstancedMesh(geometry, wireframeMaterial, count);
  const container = new THREE.Group();
  const dummy = new THREE.Object3D();

  //  mesh.instanceMatrix.needsUpdate = true;
  const anchura = 10;
  const profundidad = 1;
  const altura = .2;
  const posX = 0;
  const posY = 0;
  const posZ = 0;
  const stepSize = 1.5;
  let step = 1;
  for (let i = 1; i<81; i+=1) {
    dummy.scale.set(anchura*2, altura*2, profundidad);
    dummy.position.set(
      posX + 0,
      posY + (altura*4*step),
      posZ + (profundidad*2*step));

    dummy.rotation.y=(step*.01)*Math.PI/2;
    dummy.updateMatrix();
    mesh.setMatrixAt( i, dummy.matrix );
    wireframeMesh.setMatrixAt( i, dummy.matrix );
    step += stepSize;
  }
  container.add(mesh)
  container.add(wireframeMesh);
  container.rotation.y = -Math.PI/2;
  container.position.set(-130,0,-189);
  this.scene.add(container);
}

  espiropapa = () => {

/*
  const points = [];
    for (let j = 0; j <= Math.PI*2.1; j += (2 * Math.PI) / 50) {
     points.push(Math.cos(j), Math.sin(j), 0);
    }

    const lineMaterial = new MeshLineMaterial({color: new THREE.Color( 0x000000), lineWidth: .2});
    this.interactiveObjects.lineas = [];
    	for (let j = 0; j <= 85; j+=1) {
   			const line = new MeshLine();
   			line.setPoints(points);

   			const mesh = new THREE.Mesh(line, lineMaterial);
   			mesh.position.set(4+j,1.5+j*.4,-2-(j*2))
   			mesh.scale.set(1,(.5*j)+.5,1+j*.5)
   			this.scene.add(mesh);
   			this.interactiveObjects.lineas.push(mesh)
   		}
      */




const count = 81;

const geometry = new THREE.TorusGeometry( 1, .01, 3, 32 );
const material = new THREE.MeshBasicMaterial({
  color: "black"
});
this.espiroMesh = new THREE.InstancedMesh(geometry, material, count);
this.espiroMesh.instanceMatrix.setUsage( THREE.DynamicDrawUsage );

const container = new THREE.Group();
const dummy = new THREE.Object3D();


this.rotations = []
for (let j = 0; j<count; j+=1) {
  dummy.position.set(4+j,1.5+j*.4,-2-(j*2));
  dummy.scale.set(1,(.5*j)+.5,1+j*.5);
  this.rotations[j] = 0;

  this.espiroMesh.setMatrixAt( j, dummy.matrix );

}
container.add(this.espiroMesh)
container.position.set(0,0,0);
this.scene.add(container);

return () => {



  for (let j = 0; j<count; j+=1) {


    this.rotations[j] += .0001*(j+1);
    dummy.rotation.x = this.rotations[j];
    dummy.position.set(4+j,1.5+j*.4,-2-(j*2));
    dummy.scale.set(1,(.5*j)+.5,1+j*.5);
    dummy.updateMatrix();

    this.espiroMesh.setMatrixAt( j, dummy.matrix );

  }

this.espiroMesh.instanceMatrix.needsUpdate = true;



}

 }

  createWireframeSpace = () => {
      const sphereGeometry2 = new THREE.SphereGeometry(1, 128, 128);
            const geo2 = new THREE.EdgesGeometry(sphereGeometry2); // or WireframeGeometry( geometry )
            const mat2 = new THREE.LineBasicMaterial({ color: new THREE.Color( 0x000000 ), linewidth: 2 });
            const wireframe2 = new THREE.LineSegments(geo2, mat2);
            wireframe2.position.set(-493.15,2,0);
            wireframe2.scale.set(94,94,94);
            this.scene.add(wireframe2);
            this.interactiveObjects.wireframeSphere = wireframe2;

  // for (let i = 0; i<this.interactiveObjects.wireframeSphere.geometry.attributes.position.length; i++){
  for (let i = 0; i < wireframe2.geometry.attributes.position.count; i+=10){
   const p = wireframe2.geometry.attributes.position.array[i];

   // wireframe2.geometry.attributes.position.array[i] *= (Math.random() < 0.5 ? Math.random() : Math.random()*-1);
   wireframe2.geometry.attributes.position.array[i] =  p * Math.random();
  }
  wireframe2.geometry.verticesNeedUpdate = true;


// blackhole
  const insideGeo = new THREE.SphereGeometry(1, 32, 32);
  const interiorMat = new THREE.MeshBasicMaterial({ color: new THREE.Color( 0x000000 ),side: THREE.BackSide });
  const interior = new THREE.Mesh(insideGeo, interiorMat);
  interior.position.set(-493.15,2,0);
  interior.scale.set(35,35,35);
  this.scene.add(interior);

  const floorGeometry = new THREE.CircleGeometry(35, 64);
  const floorMaterial = new THREE.MeshBasicMaterial( { color: 0x000000,side: THREE.BackSide } );
  const circle = new THREE.Mesh(floorGeometry, floorMaterial);
  circle.position.set(-493,.001,0);

  circle.rotation.x = Math.PI/2;
  this.scene.add(circle);

  this.createPoints(20000,[-493,4,0]);

    //this.initVideoShader();


  }

  initVideoShader = () => {
    const video = document.getElementById('video');
    video.src = "ejemplo.mp4";
    video.addEventListener('loadedmetadata', () => {
      video.play();
    });

		video.addEventListener('play', () => {
			this.currentTime = 3;
      console.log("playing?")
		}, false);
     this.videoShader = new VideoShader(video, this.renderer, this.scene, this.camera);
  }

  replaceVideo = async (videoId) => {
    const fileExists = await checkIfFileExists(`videos/${videoId}.mp4`);
    if(!fileExists) return;
    this.video.pause();
    let firstChild = this.video.firstElementChild;
    while (firstChild) {
        firstChild.remove();
        firstChild = this.video.firstElementChild;
    }
    const source = document.createElement('source');
    source.setAttribute('src', `videos/${videoId}.mp4`);
    this.video.appendChild(source);
    this.video.load();
    this.video.play();
  }

  applyDynamicSettingsChanges(data) {
    super.applyDynamicSettingsChanges(data);
    if (data.videoDome) {
      this.videoDome.replaceVideo(data.videoDome);
    }
  }

  update = () => {
    const delta = this.clock.getDelta();
/*
    this.espiropapaUpdate();

    if (this.boomer) this.boomer.update(1000 * delta);
    // this.flagShader.update()
    if (this.videoShader) this.videoShader.update();
    this.dissevelt.update();
    this.blob.update();
		if (this.interactiveObjects.rotante) {
			this.interactiveObjects.rotante.rotation.y += .01;

		}

    if (this.interactiveObjects.lineas) {
      this.interactiveObjects.lineas.forEach((el,i) => el.rotation.x += .0001*(i+1));
    }

    this.interactiveObjects.instructions.forEach((item, i) => {
      item.rotation.y += .01;
    });
    this.interactiveObjects.textGroup.rotation.z += .01;

		if(this.gif) this.gif.animate();
    this.wind.update();
    this.flag.update();
    this.flag.applyWind(this.wind);
*/
  }

}
export default VirtualSpace;
