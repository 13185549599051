import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { createFloor } from '../../../three-utils/physicsUtils';

const initMainBlob = (scene, physicsWorld, physicsEngine, gifTexture, blobGeometry) => {
  const fbxLoader = new FBXLoader();
  // escenario
  const sphereBlob2 = new THREE.Mesh(
    blobGeometry,
    new THREE.MeshNormalMaterial({
      side: THREE.DoubleSide,
    }),
  );
  //  sphereBlob2.position.set(390, 2, -509);
  sphereBlob2.position.set(260, 2, -400);
  sphereBlob2.scale.set(180, 180, 180);
  scene.add(sphereBlob2);

  const oxxoLight = new THREE.PointLight(0xFF0050, 4, 100);
  oxxoLight.position.set(405, 2, -457);
  scene.add(oxxoLight);

  fbxLoader.load('models/fonima/oxxo_negro.fbx', (model) => {
    model.children.map((children) => {
      children.traverse((child) => {
        if (child.isMesh) {
          const mesh = child;
          mesh.material = new THREE.MeshPhongMaterial({
            map: gifTexture,
          });
        }
      });
    });

    model.position.set(0, 0, 0);
    model.scale.set(1, 1, 1);
    scene.add(model);
  });

  fbxLoader.load('models/fonima/oxxo_blanco.fbx', (model) => {
    model.children.map((children) => {
      children.traverse((child) => {
        if (child.isMesh) {
          const mesh = child;
          mesh.material = new THREE.MeshBasicMaterial({
            color: 'black',
          });
        }
      });
    });
    model.position.set(0, 0, 0);
    model.scale.set(1, 1, 1);
    scene.add(model);
  });

  createFloor(physicsWorld, [153 / 2, 15.000 / 2, 2], [227.353, 12, -475.440], 0.27);
  createFloor(physicsWorld, [127 / 2, 3, 2], [214.993, 3, -471.490], 0.27);

  createFloor(physicsWorld, [115.000 / 2, 20, 1], [195.633, 20.000, -432.900], 0.860);
  createFloor(physicsWorld, [2, 24.360 / 2, 115.000 / 2], [290.030, -0.520, -440.160], -0.170, 0.860);
  createFloor(physicsWorld, [6 / 2, 20.000 / 2, 125 / 2], [155.633, 4, -390.910]);

  createFloor(physicsWorld, [2, 8.000 / 2, 19.000 / 2], [157.020, 4, -312.530]);

  createFloor(physicsWorld, [2, 10.000 / 2, 19.000 / 2], [142.010, 5, -325.090]);
  createFloor(physicsWorld, [2, 10.000 / 2, 19.000 / 2], [142.010, 5, -325.090]);
  createFloor(physicsWorld, [6, 11.000 / 2, 1], [148.810, 5, -332.720]);
  createFloor(physicsWorld, [7, 2, 17 / 2], [153, 8.570, -323.640]);
  createFloor(physicsWorld, [12 / 2, 5, 2], [149.450, 5.000, -317.890]);

  // esfera de atras
  createFloor(physicsWorld, [15, 5, 2], [146.160, 4.000, -311.530], -0.630, 0.860);
  createFloor(physicsWorld, [8, 7, 2], [277.500, 4.000, -505.580]);
  createFloor(physicsWorld, [8, 2, 7], [277.460, 10.000, -499.480]);
  createFloor(physicsWorld, [2, 6, 9], [269.990, 4.8, -497.370]);
  createFloor(physicsWorld, [2, 6, 9], [284.390, 4.8, -498.090]);
  createFloor(physicsWorld, [7, 2.5, 2], [291.340, 2.500, -492.530]);

  const light1 = new THREE.PointLight(0xffffff, 10, 80);
  light1.position.set(195.633, 4, -428);
  scene.add(light1);
};

export default initMainBlob;
