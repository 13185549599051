import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import VirtualSpaceInterface from '../../../oxxxo/VirtualSpaceInterface';
import { importedModels } from '../../../three-utils/modelsLoader';
import initGifsGallery from './initGifsGallery';
import initEspiropapa from './initEspiropapa';
import initDomoFonima from './initDomoFonima';
import initTunnel from './initTunnel';
import initTerrain from './initTerrain';
import initBlackHoleSpace from './initBlackHoleSpace';
import { initStairs, initStairsPhysics } from './initStairs';
import initOxxo from './initOxxo';
import initPosters from './initPosters';
import initMainBlob from './initMainBlob';
import initInteractiveSphere from './initInteractiveSphere';
import initParticles from './initParticles';
import { createStairs, createStairsMesh } from '../../../three-utils/physicsUtils';
import TextDisplay from '../../../three-utils/TextDisplay';
import BlobObject from '../../../three-utils/BlobObject';
import VideoPlayer from '../../../three-utils/VideoPlayer';
import Flag from '../../../three-utils/flag/Flag';
import Wind from '../../../three-utils/flag/Wind';
import Dissevelt from '../../../three-utils/Dissevelt';
import TextureAnimator from '../../../three-utils/TextureAnimator';
import GalloDdeOro from '../../../three-utils/flag/textures/gallo.jpg';
import FlagShader from '../../../three-utils/FlagShader';

/*
import exportGLTF from '../util/exportGLTF';
import Kinectron from '../lib/kinectron-client';
import KinectGeometry from '../lib/kinectGeometry';
*/

export default class VirtualSpace extends VirtualSpaceInterface {
  createSpace() {
    this.scene.background = new THREE.Color('white');

    const light = new THREE.AmbientLight('white');
    this.scene.add(light);

    this.animatedPosters = initPosters(this.scene);

    this.textureLoader.load('textures/fonima/domo-fonima/fonima_logo.png', (texture) => {
      const geometryT = new THREE.PlaneGeometry(6, 3);
      const materialT = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
      });
      const text = new THREE.Mesh(geometryT, materialT);
      text.position.set(50, 4.5, -4);
      this.scene.add(text);
    });

    const groundWhiteMaterial = new THREE.MeshToonMaterial({ color: new THREE.Color('white') });
    const groundMesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(1000, 1000), groundWhiteMaterial);
    groundMesh.receiveShadow = true;
    groundMesh.rotation.x = -Math.PI / 2;
    this.scene.add(groundMesh);

    this.flag = new Flag(
      this.scene,
      this.physicsWorld,
      '/textures/fonima/bandera.png',
      new THREE.Vector3(77, 76, 300),
      true,
    );

    this.wind = new Wind(this.flag.mesh);

    this.scene.add(importedModels.fonima);
    this.scene.add(importedModels.cerros);

    this.fonimaBanderaTexture = new THREE.ImageUtils.loadTexture('textures/fonima/bandera.png');
    this.boomer = new TextureAnimator(this.fonimaBanderaTexture, 5, 7, 34, 40); // texture, #horiz, #vert, #total, duration.

    this.fonimaPrimero = new THREE.ImageUtils.loadTexture('textures/fonima/gifs-gallery/sprites/animasivo/primero.png');
    this.boomer1 = new TextureAnimator(this.fonimaPrimero, 15, 10, 150, 15); // texture, #horiz, #vert, #total, duration.

    this.fonimaSegundo = new THREE.ImageUtils.loadTexture('textures/fonima/gifs-gallery/sprites/animasivo/segundo.png');
    this.boomer2 = new TextureAnimator(this.fonimaSegundo, 10, 6, 59, 15); // texture, #horiz, #vert, #total, duration.

    this.fonimaTercero = new THREE.ImageUtils.loadTexture('textures/fonima/gifs-gallery/sprites/animasivo/tercero.png');
    this.boomer3 = new TextureAnimator(this.fonimaTercero, 15, 10, 150, 15); // texture, #horiz, #vert, #total, duration.

    this.animateGifGallery = initGifsGallery(this.scene, this.raycaster);
    this.updateEspiropapa = initEspiropapa(this.scene);

    setTimeout(() => {
      initTunnel([-177, 2, -2], this.scene);
      initStairs(this.scene);
      initStairsPhysics(this.physicsWorld);

      initParticles(20000, [-493, 4, 0], this.scene);
      initBlackHoleSpace(this.scene, this.physicsWorld);

      this.domoFonima = initDomoFonima(
        this.scene,
        this.blob.getGeometry(),
        this.textureLoader,
      );

      this.textDisplay = new TextDisplay(this.scene, [172, 30.220, -442], 40, 18);
      initInteractiveSphere(this.scene, this.physicsWorld);
      this.dissevelt = new Dissevelt(this.scene, this.camera, [230, 50, 275], this.fonimaBanderaTexture);
      initTerrain(this.physicsWorld);
    }, 0);

    this.blob = new BlobObject(1, 64, 64); // 128
    setTimeout(() => {
      this.initHumanModel();
      initMainBlob(
        this.scene,
        this.physicsWorld,
        this.physicsEngine,
        this.fonimaBanderaTexture,
        this.blob.getGeometry(),
      );

      const oxxoGroundMesh = new THREE.Mesh(
        new THREE.PlaneBufferGeometry(50, 41),
        new THREE.MeshBasicMaterial(),
      );
      oxxoGroundMesh.position.set(351, 11.6, -355.6);
      oxxoGroundMesh.rotation.x = -Math.PI / 2;
      this.scene.add(oxxoGroundMesh);

      initOxxo(this.physicsWorld, this.physicsEngine);
      this.videoDome = new VideoPlayer('http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4', [248.540, 0, -426.730], this.scene);

    }, 0);

    const geometry = new THREE.BoxGeometry(1, 1, 1);
    const material = new THREE.MeshBasicMaterial({ color: new THREE.Color(0xA792FF) });
    const cube = new THREE.Mesh(geometry, material);
    cube.position.set(354, 3, -354); // Oxxxo
    // cube.position.set(-493.15, 2, 0); // gif gallery

    this.flag2 = new Flag(this.scene, this.physicsWorld, GalloDdeOro, new THREE.Vector3(-493.15, 2, 0), false);

    this.flagShader = new FlagShader(GalloDdeOro);
    const flagShaderMesh = this.flagShader.getMesh();
    flagShaderMesh.position.y = 5;
    // this.scene.add(flagShaderMesh);

    // cube.position.set(240.120, 13.940, -10.720); // terreno
    // cube.position.set(-60.223,14.6999,314.31116); //terreno2
    cube.position.set(278.2, 1, -498.87); // escenario

    //  cube.position.set(265, 0, -452); // domo de video
    const util = new THREE.Object3D();
    util.name = 'util';
    util.add(cube);
    //   this.scene.add(util);

    const scene1 = new THREE.Scene();
    scene1.name = 'Scene1';

    const materialxxx = new THREE.MeshStandardMaterial({
      color: 0xffff00,
      metalness: 0.5,
      roughness: 1.0,
      flatShading: true,
    });
    const spherexxx = new THREE.Mesh(new THREE.SphereGeometry(70, 10, 10), materialxxx);
    spherexxx.position.set(0, 0, 0);
    spherexxx.name = 'Spherexxx';
    scene1.add(spherexxx);

    // exportGLTF( spherexxx );

    /*
  const kinectronIpAddress = '192.168.15.25'; //Add Kinectron IP here
  //const kinectronIpAddress = 'b284bc7756d0.ngrok.io'; //Add Kinectron IP here

	//Open connection with Kinect
	const	kinectron = new Kinectron(kinectronIpAddress);

  this.kinectronGeo = new KinectGeometry('mesh');
  this.kinectronGeo.position.set(25,2,25);
  this.scene.add(this.kinectronGeo);
  setTimeout(() => {
    kinectron.makeConnection();
    console.log("connection")
    kinectron.startKey(this.onNewKinectFrame);}, 5000);

  */
  }

  onNewKinectFrame(ktronImg) {
    this.kinectronGeo.kinectron.bind(ktronImg.src);
  }

  initHumanModel() {
    createStairs(this.physicsWorld, 60, [-280, 0, -172], Math.PI / 2, this.physicsEngine.slipperyMaterial);
    createStairsMesh(this.scene, 60, [-280, 0, -172], Math.PI / 2);
    this.leePerryMaterial = this.textureLoader.load('https://threejs.org/examples/models/gltf/LeePerrySmith/Map-COL.jpg');

    const loader = new GLTFLoader();
    loader.load('https://threejs.org/examples/models/gltf/LeePerrySmith/LeePerrySmith.glb', (gltf) => {
      this.leePerrySmith = gltf.scene.children[0];
      this.leePerrySmith.material = new THREE.MeshPhongMaterial({
        map: this.leePerryMaterial,
      });
      this.leePerrySmith.position.set(-230.8, 30, -213.55);
      this.leePerrySmith.scale.set(10, 10, 10);
      this.scene.add(this.leePerrySmith);
    });
  }

  replaceLeePerrySmithMaterial(id) {
    if (!this.leePerrySmith) return;
    switch (id) {
      case 'piel':
        this.leePerrySmith.material.map = this.leePerryMaterial;
        break;
      case 'bandera':
        this.leePerrySmith.material.map = this.fonimaBanderaTexture;
        break;
      case 'primero':
        this.leePerrySmith.material.map = this.fonimaPrimero;
        break;
      case 'segundo':
        this.leePerrySmith.material.map = this.fonimaSegundo;
        break;
      case 'tercero':
        this.leePerrySmith.material.map = this.fonimaTercero;
        break;
      default:
    }
  }

  applyDynamicSettingsChanges(data) {
    if (data.videoDome) {
      this.videoDome.replaceVideo(data.videoDome);
    }
    if (data.humanModel) {
      this.replaceLeePerrySmithMaterial(data.humanModel);
    }
    if (data.display1) {
      try {
        this.textDisplay.setDisplayText(data.display1);
      } catch (err) {
        console.log(err);
      }
    }
  }

  update() {
    const delta = this.clock.getDelta();

    this.updateEspiropapa();

    if (this.boomer) this.boomer.update(1000 * delta);
    if (this.boomer1) this.boomer1.update(300 * delta);
    if (this.boomer2) this.boomer2.update(300 * delta);
    if (this.boomer3) this.boomer3.update(300 * delta);

    if (this.dissevelt) this.dissevelt.update();
    this.blob.update();

    if (this.animatedPosters) {
      this.animatedPosters();
    }

    if (this.domoFonima) {
      this.domoFonima();
    }

    if (this.animateGifGallery) {
      this.animateGifGallery();
    }

    if (this.textDisplay && this.audioStreams[0]) {
      this.textDisplay.update(this.audioStreams[0]);
    }
    this.flagShader.update();
    if (this.flag) {
      this.wind.update();
      this.flag.update();
      this.flag.applyWind(this.wind);
    }
    if (this.flag2) {
      this.flag2.update();
      this.flag2.applyWind(this.wind);
    }
  }
}
