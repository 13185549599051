import * as THREE from 'three';
import * as CANNON from 'cannon-es';

const createOrtoedro = ({
  textureLoader, raycaster, physicsWorld, image, url = '', position, rotation, width = 2, height = 1, ancho = 1,
}) => {
  /* sides order: arriba, frente, reverso, a, b */
  const sides = [
    {
      image: `${image}_arriba.png`,
      size: { width, height: height * ancho },
      sidePosition: {
        x: 0,
        y: 0 + (height / 2),
        z: 0 + (height / 2) * ancho,
      },
      sideRotation: { x: Math.PI / 2, y: 0, z: 0 },
    },
    {
      image: `${image}_frente.png`,
      size: { width, height },
      sidePosition: {
        x: 0,
        y: 0,
        z: 0,
      },
      sideRotation: { x: 0, y: Math.PI, z: 0 },
    },
    {
      image: `${image}_reverso.png`,
      size: { width, height },
      sidePosition: {
        x: 0,
        y: 0,
        z: 0 + (height * ancho),
      },
      sideRotation: { x: 0, y: 0, z: 0 },
    },
    {
      image: `${image}_ladoa.png`,
      size: { width: height * ancho, height },
      sidePosition: {
        x: 0 - (width / 2),
        y: 0,
        z: 0 + (height / 2) * ancho,
      },
      sideRotation: { x: 0, y: Math.PI * 1.5, z: 0 },
    },
    {
      image: `${image}_ladob.png`,
      size: { width: height * ancho, height },
      sidePosition: {
        x: 0 + (width / 2),
        y: 0,
        z: 0 + (height / 2) * ancho,
      },
      sideRotation: { x: 0, y: Math.PI / 2, z: 0 },
    },
  ];
  const octoedroGroup = new THREE.Group();
  sides.map((side) => {
    textureLoader.load(side.image, (_texture) => {
      const texture = _texture;
      texture.encoding = THREE.sRGBEncoding;
      const geometry = new THREE.PlaneGeometry(side.size.width, side.size.height);
      const material = new THREE.MeshPhongMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true,
      });
      const mesh = new THREE.Mesh(geometry, material);
      mesh.position.set(side.sidePosition.x, side.sidePosition.y, side.sidePosition.z);
      mesh.rotation.set(side.sideRotation.x, side.sideRotation.y, side.sideRotation.z);
      mesh.userData = { URL: url };
      raycaster.addClickableObject(mesh);
      octoedroGroup.add(mesh);
    });
  });
  octoedroGroup.position.set(position.x, position.y, position.z);
  // octoedroGroup.rotation.set(rotation.x, rotation.y, rotation.z);

  if (physicsWorld) {
    const octaedroPhysicsBox = new CANNON.Box(new CANNON.Vec3(width / 2, height / 2, height / 2));
    const octaedroPhysicsBody = new CANNON.Body({ mass: 0, shape: octaedroPhysicsBox });
    octaedroPhysicsBody.position.set(position.x, position.y, position.z + (height / 2));
    physicsWorld.addBody(octaedroPhysicsBody);
  }
  return octoedroGroup;
};

export default createOrtoedro;
