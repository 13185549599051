/*
By Arno Di Nunzio in Tutorials on February 11, 2020
https://tympanus.net/codrops/2020/02/11/how-to-create-a-physics-based-3d-cloth-with-cannon-js-and-three-js/
*/
import * as THREE from 'three';
import * as C from 'cannon-es';
import { PlainAnimator } from 'three-plain-animator/lib/plain-animator';

export default class Flag {
  constructor(scene, world, image, position, isGif = false, tilesAmountHorizontally = 7, tilesAmountVertically = 5, tilesTotalAmount = 34, framesPerSecond = 15) {
    this.size = 12;
    this.mass = 1;
    this.position = position;
    this.isGif = isGif;
    this.scene = scene;
    this.world = world;
    this.textureLoader = new THREE.TextureLoader().load(image);
    this.sizes = new THREE.Vector2(0, 0);
    this.offset = new THREE.Vector2(0, 0);
    this.bufferV = new THREE.Vector3();
    this.bufferV2 = new C.Vec3();
    this.getSizes();
    this.createMesh(tilesAmountHorizontally, tilesAmountVertically, tilesTotalAmount, framesPerSecond);
    this.createStitches();
  }

  getSizes() {
    const width = 10;
    const height = 10;
    const top = 1;
    const left = 2;
    this.sizes.set(width, height);
    this.offset.set(
      left - window.innerWidth / 2 + width / 2,
      -top + window.innerHeight / 2 - height / 2,
    );
  }

  createMesh(tilesAmountHorizontally, tilesAmountVertically, tilesTotalAmount, framesPerSecond) {
    if (this.isGif) {
      this.animator = new PlainAnimator(this.textureLoader, tilesAmountHorizontally, tilesAmountVertically, tilesTotalAmount, framesPerSecond);
      const texture = this.animator.init();
      texture.generateMipmaps = false;
      texture.wrapS = THREE.ClampToEdgeWrapping;
      texture.wrapT = THREE.ClampToEdgeWrapping;
      texture.minFilter = THREE.LinearFilter;
      this.geometry = new THREE.PlaneBufferGeometry(1, 1, this.size, this.size);
      this.material = new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true,
        side: THREE.DoubleSide,
      });
    } else {
      this.geometry = new THREE.PlaneBufferGeometry(1, 1, this.size, this.size);
      this.material = new THREE.MeshBasicMaterial({
        map: this.textureLoader,
        side: THREE.DoubleSide,
      });
    }

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.mesh.position.set(this.position.x, this.position.y, this.position.z);
    this.mesh.rotation.set(-1.50, 1.890, 0);
    this.mesh.scale.set(2, 2, 0.2);
    this.container = new THREE.Object3D();
    this.container.add(this.mesh);
    this.container.scale.set(1, 1, 1);
    this.scene.add(this.container);
  }

  createStitches() {
    const particleShape = new C.Particle();
    const { position } = this.geometry.attributes;
    const { x: width, y: height } = this.sizes;

    this.stitches = [];

    for (let i = 0; i < position.count; i += 1) {
      const row = Math.floor(i / (this.size + 1));
      const pos = new C.Vec3(
        position.getX(i) * width,
        position.getY(i) * height,
        position.getZ(i),
      );

      const stitch = new C.Body({
        mass: row === 0 ? 0 : this.mass / position.count,
        linearDamping: 0.8,
        position: pos,
        shape: particleShape,
      });

      this.stitches.push(stitch);
      this.world.addBody(stitch);
    }

    for (let i = 0; i < position.count; i += 1) {
      const col = i % (this.size + 1);
      const row = Math.floor(i / (this.size + 1));

      if (col < this.size) this.connect(i, i + 1);
      if (row < this.size) this.connect(i, i + this.size + 1);
    }
  }

  connect(i, j) {
    const c = new C.DistanceConstraint(this.stitches[i], this.stitches[j]);

    this.world.addConstraint(c);
  }

  applyWind(wind) {
    const { position } = this.geometry.attributes;
    for (let i = 0; i < position.count; i += 1) {
      const stitch = this.stitches[i];
      const windNoise = wind.flowfield[i];
      const tempPosPhysic = this.bufferV2.set(
        windNoise.x,
        windNoise.y,
        windNoise.z,
      );

      stitch.applyForce(tempPosPhysic, C.Vec3.ZERO);
    }
  }

  update() {
    if(this.animator) this.animator.animate();
    const { position } = this.geometry.attributes;
    const { x: width, y: height } = this.sizes;

    for (let i = 0; i < position.count; i+=1) {
      const p = this.bufferV.copy(this.stitches[i].position);
      position.setXYZ(i, p.x / width, p.y / height, p.z);
    }

    position.needsUpdate = true;
  }
}
