const sounds = {
  drop: 'audio/drop.mp3',
  flutter: 'audio/flutter.mp3',
  modem: 'audio/modem.mp3',
  osc: 'audio/osc.mp3',
  tada: 'audio/tada.mp3',
  voz: 'audio/voz.mp3',
};

export default class AudioSampler {
  constructor(soundFilePaths = sounds) {
    try {
      window.AudioContext = window.AudioContext || window.webkitAudioContext;
      this.context = new AudioContext();
    } catch (e) {
      console.log('Web Audio API is not supported in this browser');
      return;
    }
    this.soundFilePaths = soundFilePaths;
    this.buffers = {};
    this.preLoadSounds();
  }

  preLoadSounds() {
    Object.entries(this.soundFilePaths).forEach(([soundId, url]) => {
      // Load buffer asynchronously
      const request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'arraybuffer';
      request.onload = () => {
        this.context.decodeAudioData(
          request.response,
          (buffer) => {
            if (!buffer) {
              console.log('error decoding file data: ' + url);
              return;
            }
            this.buffers[soundId] = buffer;
          },
          (error) => {
            console.error('decodeAudioData error', error);
          },
        );
      };
      request.onerror = () => {
        console.log('BufferLoader: XHR error');
      };
      request.send();
    });
  }

  playNote(soundId) {
    if (!this.buffers[soundId]) return;
    const sound = this.buffers[soundId];
    if (!this.context.createGain) {
      this.context.createGain = this.context.createGainNode;
    }
    const gainNode = this.context.createGain();
    const source = this.context.createBufferSource();
    source.buffer = sound;
    source.connect(gainNode);
    gainNode.connect(this.context.destination);
    source.loop = false;
    if (!source.start) {
      source.start = source.noteOn;
    }
    source.start(0);
  }
}
