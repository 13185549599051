import randomAvatarPosition from './randomPosition';

// http://localhost:3000/?nombre=juan&avatar=2&lugar=mx

const validateNombre = (nombreInput) => {
  /^([a-z0-9 ]{1,})$/.test(nombreInput);
  return nombreInput;
};

const validateAvatar = (avatarInput) => {
  const avatars = ['0', '1', '2'];
  return avatars.includes(avatarInput) ? avatarInput : false;
};

const validateLugar = (lugarInput) => {
  const lugares = ['usa', 'mx'];
  return lugares.includes(lugarInput) ? randomAvatarPosition() : false;
};

const pasaporte = (query) => {
  const nombreInput = query.get('nombre');
  const avatarInput = query.get('avatar');
  const lugarInput = query.get('lugar');
  const username = validateNombre(nombreInput);
  const avatar = validateAvatar(avatarInput);
  const initialAvatarPosition = validateLugar(lugarInput);
  if (username && avatar && initialAvatarPosition) {
    return { username, avatar, initialAvatarPosition };
  }
  return false;
};

export default pasaporte;
