import * as THREE from "three";
import { Noise } from 'noisejs';

export default class BlobObject {
  constructor(radius, widthSegments, heightSegments, geometry) {
    this.geometry = geometry ?? new THREE.SphereGeometry(radius, widthSegments, heightSegments);
    this.noise = new Noise(Math.random());
  }

  getGeometry = () => {
    return this.geometry;
  }

  update = () => {
    const time = performance.now() * 0.001;
    const k = 3;
    
    for (let i = 0; i < this.geometry.vertices.length; i++) {
      const p = this.geometry.vertices[i];
      p.normalize().multiplyScalar(1 + 0.3 * this.noise.perlin3(p.x * k + time, p.y * k, p.z * k));
    }
  
    this.geometry.computeVertexNormals();
    this.geometry.normalsNeedUpdate = true;
    this.geometry.verticesNeedUpdate = true;
  }

}
