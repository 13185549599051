import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import loadManager from './loaderManager';
/*
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
*/

export const importedModels = [];

const fbxloader = new FBXLoader(loadManager);
const gLTFLoader = new GLTFLoader();
const fontLoader = new THREE.FontLoader();

const loadAvatar1 = (resolve) => {
  fbxloader.load('/models/avatars/human_walk_0.fbx', (model) => {
    model.traverse((child) => {
      if (child.isMesh) {
        const mesh = child;
        mesh.material = new THREE.MeshBasicMaterial({ skinning: true, color: 0x000000, wireframe: true });
        mesh.material.wireframe = true;
      }
    });
    model.rotateY(Math.PI);
    model.scale.set(0.007, 0.007, 0.007);
    const avatar = {};
    avatar.model = model;
    avatar.animation = model.animations[0];
    resolve(avatar);
  });
};

const loadAvatar2 = (resolve) => {
  fbxloader.load('/models/avatars/oxxxo char new try 1.fbx', (model) => {

    const childrenToRemove = [];
    /*
    model.traverse((child) => {
      if (child.isMesh) {
        const mesh = child;
        mesh.material = new THREE.MeshNormalMaterial({ skinning: true });
      }
      if (child.isLight) {
        childrenToRemove.push(child);
      }
    });
    childrenToRemove.forEach((child) => {
      model.remove(child);
    });
    */
    model.rotateY(Math.PI);
    model.scale.set(0.01, 0.01, 0.01);
    const avatar = {};
    avatar.model = model;
    avatar.animation = model.animations[0];
    resolve(avatar);
  });
};

const loadModeloMota1 = (resolve) => {
  fbxloader.load('/models/avatars/modelosMota/1.fbx', (model) => {

    const childrenToRemove = [];
    /*
    model.traverse((child) => {
      if (child.isMesh) {
        const mesh = child;
        mesh.material = new THREE.MeshNormalMaterial({ skinning: true });
      }
      if (child.isLight) {
        childrenToRemove.push(child);
      }
    });
    childrenToRemove.forEach((child) => {
      model.remove(child);
    });
    */
    model.rotateY(Math.PI);
    model.scale.set(0.01, 0.01, 0.01);
    const avatar = {};
    avatar.model = model;
    avatar.animation = model.animations[0];
    resolve(avatar);
  });
};
const loadModeloMota2 = (resolve) => {
  fbxloader.load('/models/avatars/modelosMota/2.fbx', (model) => {

    const childrenToRemove = [];
    /*
    model.traverse((child) => {
      if (child.isMesh) {
        const mesh = child;
        mesh.material = new THREE.MeshNormalMaterial({ skinning: true });
      }
      if (child.isLight) {
        childrenToRemove.push(child);
      }
    });
    childrenToRemove.forEach((child) => {
      model.remove(child);
    });
    */
    model.rotateY(Math.PI);
    model.scale.set(0.01, 0.01, 0.01);
    const avatar = {};
    avatar.model = model;
    avatar.animation = model.animations[0];
    resolve(avatar);
  });
};

const loadModeloMota3 = (resolve) => {
  fbxloader.load('/models/avatars/modelosMota/3.fbx', (model) => {

    const childrenToRemove = [];
    /*
    model.traverse((child) => {
      if (child.isMesh) {
        const mesh = child;
        mesh.material = new THREE.MeshNormalMaterial({ skinning: true });
      }
      if (child.isLight) {
        childrenToRemove.push(child);
      }
    });
    childrenToRemove.forEach((child) => {
      model.remove(child);
    });
    */
    model.rotateY(Math.PI);
    model.scale.set(0.01, 0.01, 0.01);
    const avatar = {};
    avatar.model = model;
    avatar.animation = model.animations[0];
    resolve(avatar);
  });
};

const loadFonima = (resolve) => {
  fbxloader.load('/models/fonima/fonima.fbx', (model) => {
    resolve(model);
  });
};

const loadCerros = (resolve) => {
  fbxloader.load('/models/fonima/cerros.fbx', (model) => {
    resolve(model);
  });
};

const loadFont = (resolve) => {
  // fontLoader.load('fonts/helvetiker_regular.typeface.json', font => resolve(font));
  fontLoader.load('fonts/hanson_bold.json', (font) => resolve(font));
};

export const loadAvatarModels = async () => {
/*

 importedModels.man = await new Promise(loadAvatar1);
importedModels.monite = await new Promise(loadAvatar2);
*/

  const loadAvatarCumple = (resolve) => {
    gLTFLoader.load('/avatars/cumple/scene.gltf', (gltf) => {
      gltf.scene.traverse((node) => {
        node.castShadow = true;
        if (!node.isMesh) return;
        /*
        node.material.wireframe = true;
        node.material.wireframeLinewidth = 10
        node.receiveShadow = true;
        */
      });
      gltf.scene.scale.set(0.01, 0.01, 0.01);
      const avatar = {};
      avatar.model = gltf.scene;
      avatar.animation = gltf.animations[2];
      resolve(avatar);
    });
  };
  importedModels.mota1 = await new Promise(loadModeloMota1);
  importedModels.mota2 = await new Promise(loadModeloMota2);
  importedModels.mota3 = await new Promise(loadModeloMota3);
  importedModels.woman = await new Promise(loadAvatar2);
  importedModels.avatarCumple = await new Promise(loadAvatarCumple);
  importedModels.cerros = await new Promise(loadCerros);
  importedModels.fonima = await new Promise(loadFonima);
  importedModels.font = await new Promise(loadFont);
};
