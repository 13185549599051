import * as THREE from "three";
import * as CANNON from 'cannon-es';
import { merge, fromEvent, interval } from 'rxjs';
import { switchMap, takeUntil, filter, throttleTime, delay } from 'rxjs/operators';
import { isDebugMode } from '../../util/debug.util';
import store from '../../store/store';

export default class Controls {
  constructor (camera, avatar, emitMoveAvatar, container, controlsContainer, chatbox, avatarLight) {
    this.container = container;
    this.controlsContainer = controlsContainer;
    this.chatbox = chatbox;
    this.avatarLight = avatarLight;
    this.camera = camera;
    this.emitMoveAvatar = emitMoveAvatar;
    this.cannonBody = avatar.getPhysicsBody();
    this.avatar =  avatar;
    this.lookUp = false;
    this.lookDown = false;
    this.lookRight = false;
    this.lookLeft = false;
    this.moveForward = false;
    this.moveBackward = false;
    this.moveLeft = false;
    this.moveRight = false;
    this.velocityFactor = 18; //4
    this.jumpVelocity = 30;
    this.canJump = false;
    this.pitchObject = new THREE.Object3D();
    //this.pitchObject.rotation.x = .4;
    this.pitchObject.add(this.avatar.getModelMesh());
    
    if (this.avatarLight) {
      const sphere = new THREE.SphereBufferGeometry(0.005, 16, 8);
      const light1 = new THREE.PointLight(0xffffff, 1, 7);

      light1.add(new THREE.Mesh(sphere,
        new THREE.MeshPhongMaterial({
          color: 0xff0040,
          emissive: 0xff0040,
          specular: 0xff0040,
          emissiveIntensity: 1,
          shininess: 500 })
      ));

      light1.castShadow = true;
      light1.position.y =.5;
      this.pitchObject.add(light1);
    }
    
    this.yawObject = new THREE.Object3D();
    this.yawObject.add(this.pitchObject);
    this.quat = new THREE.Quaternion();
    this.velocity = this.cannonBody.velocity;
    this.setupCollide();
    this.setupEventListeners();
  }

  setupCollide = () => {
    const contactNormal = new CANNON.Vec3(); // Normal in the contact, pointing *out* of whatever the player touched
    const upAxis = new CANNON.Vec3(0,1,0);
    this.cannonBody.addEventListener("collide", e => {
      const {contact} = e;

      // contact.bi and contact.bj are the colliding bodies, and contact.ni is the collision normal.
      // We do not yet know which one is which! Let's check.
      if(contact.bi.id === this.cannonBody.id)
          // bi is the player body, flip the contact normal
          contact.ni.negate(contactNormal);
      else
          // bi is something else. Keep the normal as it is
          contactNormal.copy(contact.ni);

      // If contactNormal.dot(upAxis) is between 0 and 1, we know that the contact normal is somewhat in the up direction.
      // Use a "good" threshold value between 0 and 1 here!
      if(contactNormal.dot(upAxis) > 0.5)
          this.canJump = true;
    });
  }

  onTouchMove = (event, touchStart) => {
    // const movementY = event.touches[0].clientY - touchStart.touches[0].clientY|| 0;
    const movementX = event.touches[0].clientX - touchStart.touches[0].clientX|| 0;
    this.yawObject.rotation.y -= movementX * 0.00009;
  //  this.pitchObject.rotation.x -= movementY * 0.00009;

    this.pitchObject.rotation.x = Math.max(-Math.PI/2, Math.min(Math.PI/2, this.pitchObject.rotation.x));
  }

  onMouseMove = (event) => {
    if (!store.getState().shouldMove) return
    const movementX = event.movementX || event.mozMovementX || event.webkitMovementX || 0;
    const movementY = event.movementY || event.mozMovementY || event.webkitMovementY || 0;

    this.yawObject.rotation.y -= movementX * 0.002;
    this.pitchObject.rotation.x -= movementY * 0.002;
    this.pitchObject.rotation.x = Math.max(-Math.PI/2, Math.min(Math.PI/2, this.pitchObject.rotation.x));
  };

  onKeyDown = (event) => {
    if (!store.getState().shouldMove) return;
    switch (event.keyCode) {
      // WASD (Camera)
      case 87: // up (W)
        this.lookUp = true;
        break;

      case 65: // left (A)
        this.lookLeft = true;
        break;

      case 83: // down (S)
        this.lookDown = true;
        break;

      case 68: // right (D)
        this.lookRight = true;
        break;

      // ARROWS (Movement)
      case 38: // up
        this.moveForward = true;
        break;

      case 37: // left
        this.moveLeft = true;
        break;

      case 40: // down
        this.moveBackward = true;
        break;

      case 39: // right
        this.moveRight = true;
        break;

      case 32: // space
        if (this.canJump === true) { this.velocity.y = this.jumpVelocity };
        this.canJump = false;


          console.log(this.yawObject.position);
        
        break;

      default:
        break;
    }
    };

  onKeyUp = (event) => {
    this.avatar.stopWalking();
    switch(event.keyCode) {
      // WASD (Camera)
      case 87: // up (W)
        this.lookUp = false;
        break;

      case 65: // left (A)
        this.lookLeft = false;
        break;

      case 83: // down (S)
        this.lookDown = false;
        break;

      case 68: // right (D)
        this.lookRight = false;
        break;

      // ARROWS (Movement)
      case 38: // up
        this.moveForward = false;
        break;

      case 37: // left
        this.moveLeft = false;
        break;

      case 40: // down
        this.moveBackward = false;
        break;

      case 39: // right
        this.moveRight = false;
        break;

      default:
        break;
    }
  };

  onMouseDown = (element) => {
    const key = element.getAttribute("data-direction");
    switch(key) {
      case "up":
        this.moveForward = true;
        break;

      case "left":
        this.moveLeft = true;
        break;

      case "down":
        this.moveBackward = true;
        break;

      case "right":
        this.moveRight = true;
        break;

      case "lookUp":
        this.lookUp = true;
        break;

      case "lookLeft":
        this.lookLeft = true;
        break;

      case "lookDown":
        this.lookDown = true;
        break;

      case "lookRight":
        this.lookRight = true;
        break;

      case "jump": // space
        if (this.canJump === true){
          this.velocity.y = this.jumpVelocity;
        }
        this.canJump = false;
        break;
      default:
        break;
    };
  };

  onMouseUp = () => {
    this.moveForward = false;
    this.moveBackward = false;
    this.moveLeft = false;
    this.moveRight = false;
    this.lookUp = false;
    this.lookLeft = false;
    this.lookRight = false;
    this.lookDown = false;
    this.avatar.stopWalking();
  };

  setupEventListeners = () => {
    document.addEventListener('touchmove', (event) => {
      if(event.scale !== 1){ event.preventDefault(); }
    }, false);

    ['mouseup', 'touchend'].forEach(e => {
      document.addEventListener(e, () =>{
        this.onMouseUp();
        document.querySelectorAll('.controls__button--pressed').forEach(element => {
            element.classList.remove("controls__button--pressed");
        });
      });
    });

    ['mousedown', 'touchstart'].forEach(e => {
      document.querySelectorAll('.controls__button').forEach(element => {
        element.addEventListener(e, event => {
            if (!event.button) this.onMouseDown(element)
                     element.classList.add("controls__button--pressed");
        });
      })
    });

    document.addEventListener("mousedown", () => {
        document.addEventListener("mousemove", this.onMouseMove);
    });

    document.addEventListener("mouseup", () => {
        document.removeEventListener("mousemove", this.onMouseMove);
    });

    document.addEventListener('keydown', this.onKeyDown, false);
    document.addEventListener('keyup', this.onKeyUp, false);

    const mouseDownCanvas$ = fromEvent(this.container.querySelectorAll('canvas'), 'mousedown');
    const mouseDownControls$ = fromEvent(this.controlsContainer, 'mousedown');
    const mouseDownChatBox$ = fromEvent(this.chatbox, 'mousedown');

    const mouseUp$ = fromEvent(document.documentElement, 'mouseup');
    const keyDown$ = fromEvent(document.documentElement, 'keydown');
    const keyUp$ = fromEvent(document.documentElement, 'keyup');

    const touchStart$ = fromEvent(document.documentElement, 'touchstart');
    const touchEnd$ = fromEvent(document.documentElement, 'touchend');

    const streamInterval = 10;
    const inertiaDelay = 1500;
    const throttleTimeSeconds = 1000;

    const mouseDown$ = merge(
        mouseDownCanvas$,
        mouseDownControls$,
        mouseDownChatBox$
    );

    const mouseStream$ = mouseDown$.pipe(
        switchMap(() =>
            interval(streamInterval).pipe(
                takeUntil(mouseUp$.pipe(delay(inertiaDelay)))
            )
        )
    );

    const tapStream$ = touchStart$.pipe(
        switchMap(() =>
            interval(streamInterval).pipe(
                takeUntil(touchEnd$.pipe(delay(inertiaDelay)))
            )
        )
    );

    const controlKeys$ = keyDown$.pipe(
        filter((e) => [38,87,37,65,40,83,39,68,32].includes(e.keyCode))
    );

    const keyStream$ = controlKeys$.pipe(
      switchMap(() =>
          interval(streamInterval).pipe(
              takeUntil(keyUp$.pipe(delay(inertiaDelay)))
          )
      ));

    const keyOrMouseDown$ = merge(
          mouseStream$,
          tapStream$,
          keyStream$,
    );

    const onMovement = keyOrMouseDown$.pipe(
      throttleTime(throttleTimeSeconds)
    );

    const afterJump$ = fromEvent(this.cannonBody, 'collide');
    const afterJumpThrottle$ = afterJump$.pipe(throttleTime(1000));

    const onMovementOrAfterJump = merge(
          onMovement,
          afterJumpThrottle$,
    );

    onMovementOrAfterJump.pipe(filter(() => {
      return store.getState().shouldMove;
    })).subscribe(() => {
      this.emitMoveAvatar(this.cannonBody.position, this.yawObject.rotation)
    });
  }

  getObject = () => {
    return this.yawObject;
  };

  update = (newDelta) => {
    const inputVelocity = new THREE.Vector3();
    const euler = new THREE.Euler();
    const delta = newDelta * 0.1;
    inputVelocity.set(0,0,0);

    if(this.lookUp){
      this.pitchObject.rotation.x +=  0.05;
    }
    if(this.lookDown){
      this.pitchObject.rotation.x -= 0.05;
    }

    if(this.lookRight){
      this.yawObject.rotation.y -= 0.05;
    }

    if(this.lookLeft){
      this.yawObject.rotation.y += 0.05;
    }

    if (this.moveForward){
      if(this.pitchObject.rotation.x > 1.7){
        this.pitchObject.rotation.x = 0;
      }
      this.avatar.walk();
      inputVelocity.z = -this.velocityFactor * delta;
    }
    if (this.moveBackward){
        this.avatar.walk();
        inputVelocity.z = this.velocityFactor * delta;
    }

    if ( this.moveLeft ){
        this.avatar.walk();
        inputVelocity.x = -this.velocityFactor * delta;
    }
    if (this.moveRight){
      this.avatar.walk();
      inputVelocity.x = this.velocityFactor * delta;
    }

    // Convert velocity to world coordinates
    euler.x = this.pitchObject.rotation.x;
    euler.y = this.yawObject.rotation.y;
    euler.order = "XYZ";
    this.quat.setFromEuler(euler);
    inputVelocity.applyQuaternion(this.quat);

    this.velocity.x = inputVelocity.x;
    this.velocity.z = inputVelocity.z;

    this.yawObject.position.copy(this.cannonBody.position);
    //this.cannonBody.quaternion.copy(this.yawObject.quaternion);
    this.camera.position.x = this.yawObject.position.x + (4* Math.sin(euler.y));
    this.camera.position.y = this.yawObject.position.y + 1;
    this.camera.position.z = this.yawObject.position.z + (4* Math.cos(euler.y));

    const newPosition = new THREE.Vector3( this.yawObject.position.x,
                                          (this.yawObject.position.y + 2 * this.pitchObject.rotation.x) + 1.4,
                                          this.yawObject.position.z );
    this.camera.lookAt(newPosition);
    return newPosition;
  };
};
