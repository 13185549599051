import * as THREE from 'three';

const initTunnel = (position = [0, 2, 0], scene) => {
  const [x, y, z] = position;
  const insideGeo = new THREE.SphereGeometry(1, 128, 128);
  const interiorMat = new THREE.MeshBasicMaterial({
    color: new THREE.Color(0x000000),
    side: THREE.BackSide,
  });
  const interior = new THREE.LineSegments(insideGeo, interiorMat);
  interior.position.set(x, y, z);
  interior.scale.set(70, 10, 10);
  scene.add(interior);

  const interiorMatCirculo = new THREE.MeshBasicMaterial({
    color: new THREE.Color(0x000000),
    side: THREE.BackSide,
  });

  const interiorCirculo = new THREE.LineSegments(insideGeo, interiorMatCirculo);
  interiorCirculo.position.set(x, y, z);
  interiorCirculo.scale.set(10, 10, 10);
  scene.add(interiorCirculo);

  const insideGeoFondo = new THREE.SphereGeometry(1, 128, 128);
  const interiorMatFondo = new THREE.MeshBasicMaterial({
    color: new THREE.Color(0xFFFFFF),
    side: THREE.BackSide,
  });
  const interiorFondo = new THREE.Mesh(insideGeoFondo, interiorMatFondo);
  interiorFondo.position.set(x, y, z);
  interiorFondo.scale.set(70, 10, 10);
  scene.add(interiorFondo);
};

export default initTunnel;
