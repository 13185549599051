import React from 'react';
import ScrollIntoView from 'react-scroll-into-view';
import './ScrollDownButton.scss';

const ScrollDownButton = () => (
  <div className="scrollDownButton">
    <ScrollIntoView selector="#events">
      <div className="scrollDownButton__link">
        <span />
        <span />
        <span />
      </div>
    </ScrollIntoView>
  </div>
);

export default ScrollDownButton;
