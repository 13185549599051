import * as THREE from 'three';
import { isDebugMode } from '../util/debug.util';

const loadManager = new THREE.LoadingManager();
loadManager.onStart = (url, itemsLoaded, itemsTotal) => {
  if (isDebugMode()) {
    console.log(`Started loading file: ${url}.\nLoaded ${itemsLoaded} of ${itemsTotal} files.`);
  }
};

loadManager.onLoad = () => {
  if (isDebugMode()) {
    console.log('Loading complete!');
  }
};

loadManager.onProgress = (url, itemsLoaded, itemsTotal) => {
  if (isDebugMode()) {
    console.log(`Loading file: ${url}.\nLoaded ${itemsLoaded} of ${itemsTotal} files.`);
  }
};

loadManager.onError = (url) => {
  if (isDebugMode()) {
    console.log(`There was an error loading ${url}`);
  }
};

export default loadManager;
