import React from 'react';
import { withTranslation } from 'react-i18next';
import Tilt from 'react-tilt';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import credenciales from './img/credenciales.png';
import mixlrInput from './img/mixlr_input.png';
import mixlrLogout from './img/mixlr_logout.png';

import './index.scss';

const Instructions = (props) => {
  const { t } = props;
  return (
    <>
      <div className="infoPage">
        <div className="infoPage__header">
          <Tilt className="Tilt infoPage__tilt" options={{ max: 25, perspective: 1000 }}>
            <h1>instrucciones</h1>
          </Tilt>
        </div>
        <div style={{ padding: '50px 20px', maxWidth: '800px', margin: 'auto' }}>
          <div className="infoPage__text">
            Para transmitir audio usamos Mixlr.
            <ul>
              <li>
                Descargar mixlr desde
                {' '}
                <a href="https://mixlr.com/download" rel="noopener noreferrer" target="_blank">
                  https://mixlr.com/download
                  {' '}
                </a>
                <br />
                (seleccionar la versión de prueba de cualquiera de los planes).
              </li>
              <li>
                Acceder a la cuenta de mixlr con las credenciales que te proporcionamos.
                <br />
                <img src={credenciales} alt="login de mixlr" style={{ border: '1px solid black' }} />
              </li>
              <li>
                Si entraste con otra cuenta, puedes salir desde este menú y volver a entrar con las credenciales correctas.
                <br />
                <img src={mixlrLogout} alt="logout de mixlr" style={{ border: '1px solid black' }} />
              </li>
              <li>
                El audio de tu presentación debe entrar al input de Mixlr para poder ser transmitido,
                se puede usar un cable virtual como Soundflower o Virtual Audio Cable.
                Puedes seguir estas instrucciones para usar Soundflower con Ableton Live:
                <br />
                <a href="https://blog.mixlr.com/2010/07/how-to-use-mixlr-with-ableton-live-traktor-etc/" rel="noopener noreferrer" target="_blank">
                  https://blog.mixlr.com/2010/07/how-to-use-mixlr-with-ableton-live-traktor-etc/
                </a>
              </li>
              <li>
                Desde este menú puedes seleccionar el canal con la señal de audio que vas a transmitir:
                <br />
                <img src={mixlrInput} alt="input de mixlr" style={{ border: '1px solid black' }} />
              </li>
              <li>
                Una vez que la señal esté llegando a mixlr presiona Start para empezar a transmitir, recuerda que hay un retraso de varios segundos en la transmisión.
              </li>
            </ul>

          </div>

          <br />
          <br />
          <a href="/">
            <ArrowBackIosIcon />
          </a>
        </div>
      </div>
    </>
  );
};

export default withTranslation()(Instructions);
