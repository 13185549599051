import VirtualSpace from './VirtualSpace';

const config = {
  virtualSpace: new VirtualSpace(),
  avatars: [
    {
      id: 0, avatarSelectorImagePath: '/avatars/Kurenniemi/avatar.gif', isGif: false, avatarPath: 'mota1',
    },
    {
      id: 1, avatarSelectorImagePath: '/avatars/Kurenniemi/avatar.gif', isGif: false, avatarPath: 'mota2',
    },
    {
      id: 2, avatarSelectorImagePath: '/avatars/Kurenniemi/avatar.gif', isGif: false, avatarPath: 'mota3',
    },
  ],
  socketRoom: 'room',
  avatarLight: false,
  nombreDeEspacio: 'Kurenniemi',
  audioStreams: [
    {
      url: 'audio/silencio.mp3',
      position: [0, 0, 0],
      distance: 80,
    },
  ],
};

export default config;
