import * as THREE from 'three';
import * as CANNON from 'cannon-es';

export const createStairs = (physicsWorld, steps, position, yAngle, material) => {
  const anchura = 2.2;
  const profundidad = 0.48;
  const altura = 0.25;
  const escaleraBody = new CANNON.Body({ mass: 0, material });

  for (let i = 0; i < steps; i += 1) {
    const escalera = new CANNON.Box(new CANNON.Vec3(anchura, altura, profundidad));
    escaleraBody.addShape(
      escalera,
      new CANNON.Vec3(
        0,
        (altura * 2 * i) + altura / 2,
        (profundidad * 2 * i) + profundidad / 2,
      ),
    );
  }

  const [posX, posY, posZ] = position;
  escaleraBody.position.set(posX, posY, posZ);
  const yAxis = new CANNON.Vec3(0, 1, 0);
  escaleraBody.quaternion.setFromAxisAngle(yAxis, yAngle);
  physicsWorld.addBody(escaleraBody);
};

export const createStairsMesh = (scene, count, position, yAngle) => {
  const [posX, posY, posZ] = position;
  const geometry = new THREE.BoxBufferGeometry(1, 1, 2);
  const material = new THREE.MeshBasicMaterial({
    color: new THREE.Color(186 / 255, 247 / 255, 199 / 255),
  });
  const wireframeMaterial = new THREE.MeshBasicMaterial({
    wireframe: true,
    color: 'white',
  });
  const mesh = new THREE.InstancedMesh(geometry, material, count);
  const wireframeMesh = new THREE.InstancedMesh(geometry, wireframeMaterial, count);
  mesh.position.set(posX, posY, posZ);
  wireframeMesh.position.set(posX, posY, posZ);

  mesh.rotation.set(0, yAngle, 0);
  wireframeMesh.rotation.set(0, yAngle, 0);

  scene.add(mesh);
  scene.add(wireframeMesh);

  const dummy = new THREE.Object3D();
  const anchura = 4.4;
  const profundidad = 0.48;
  const altura = 0.25 * 2;

  mesh.scales = new Float32Array(mesh.count);
  for (let i = 0; i < count; i += 1) {
    dummy.scale.set(anchura, altura, profundidad);
    dummy.position.set(
      0,
      (altura * i) + altura / 4,
      (profundidad * 2 * i) + profundidad / 2,
    );
    dummy.updateMatrix();
    mesh.setMatrixAt(i, dummy.matrix);
    mesh.updateMatrix();
    wireframeMesh.setMatrixAt(i, dummy.matrix);
  }
};

export const createFloor = (physicsWorld, shapeSizes, position, yRotationAngle, xRotationAngle) => {
  const [shapeX, shapeY, shapeZ] = shapeSizes;
  const [posX, posY, posZ] = position;
  const shape = new CANNON.Box(new CANNON.Vec3(shapeX, shapeY, shapeZ));
  const body = new CANNON.Body({ mass: 0, shape });
  body.position.set(posX, posY, posZ);
  if (xRotationAngle) {
    const xAxis = new CANNON.Vec3(1, 1, 0);
    body.quaternion.setFromAxisAngle(xAxis, xRotationAngle);
  }
  if (yRotationAngle) {
    const yAxis = new CANNON.Vec3(0, 1, 0);
    body.quaternion.setFromAxisAngle(yAxis, yRotationAngle);
  }

  physicsWorld.addBody(body);
};
