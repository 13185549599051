import * as THREE from "three";
import * as CANNON from 'cannon-es';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import VirtualSpaceInterface from '../../../oxxxo/VirtualSpaceInterface';
import Raycaster from '../../../three-utils/Raycaster';
import Sanity from "../../../util/sanity";
import Flag from "../../../three-utils/flag/Flag";
import Wind from "../../../three-utils/flag/Wind";
import GifAnimation from "../../../three-utils/GifAnimation";
// import BanderaGif from "./util/flag/textures/ezgif.com-gif-maker.png";
// import Bandera from "./util/flag/textures/bandera-de-mexico.png";

class VirtualSpace extends VirtualSpaceInterface {

  createSpace = () => {

    const light = new THREE.AmbientLight(0x101010); // soft white light
    this.scene.add(light);

    const cannonBox = new CANNON.Box(new CANNON.Vec3(68, 3, 0.5))
    const boxBody = new CANNON.Body({mass: 0, shape: cannonBox}); // Step 2
    boxBody.position.set(-12,2.5,-19.6);

    this.physicsWorld.addBody(boxBody); // Step 3

    const fbxLoader = new FBXLoader();

    // escaleras
    for (let i = 1 ; i<50 ; i+=1){
        const anchura = 2
        const profundidad = .5
        const altura = .2
        const escalera = new CANNON.Box(new CANNON.Vec3(anchura, altura, profundidad))
        const escaleraBody = new CANNON.Body({mass: 0, shape: escalera});
        escaleraBody.position.set(0,(altura*2*i),profundidad*2*i);
        this.physicsWorld.addBody(escaleraBody);
    }
  /*
    fbxLoader.load('/models/doctor-simi/source/simifbx.fbx', (model) => {
      model.position.set(0,0,0);
      model.scale.set(.1,.1,.1);
      this.scene.add(model);
    });
    */

    fbxLoader.load('/models/snugal.fbx', (model) => {
      console.log(model)
      model.position.set(0,0,0);
      model.scale.set(0.1, 0.1, 0.1);
      this.scene.add(model);
    });

    fbxLoader
      .load('/models/maruchan1.fbx', (model) => {
        const stemGeometry = new THREE.InstancedBufferGeometry();
        THREE.BufferGeometry.prototype.copy.call( stemGeometry, model.children[0].geometry );
        stemGeometry.scale(0.0001, 0.0001, 0.0001);
        const defaultTransform = new THREE.Matrix4()
				.multiply(new THREE.Matrix4().makeScale( 7, 7, 7 ));
		  stemGeometry.applyMatrix4( defaultTransform );

      const count = 800;
			const mesh = new THREE.InstancedMesh(stemGeometry, model.children[0].material, count);
      const amount = 8
			let i = 0;
			const offset = (amount - 1) / 2;
			const transform = new THREE.Object3D();
			for(let x = 0; x < amount; x+=1) {
				for(let y = 0; y < amount; y+=1) {
					for(let z = 0; z < amount; z+=1) {
						transform.position.set(offset - x, offset - y, offset - z);
            transform.rotation.set(offset - x, offset - y, offset - z);
						transform.updateMatrix();
						mesh.setMatrixAt(i+=1, transform.matrix);
					}
				}
			}
      mesh.position.set(-21.244,4,0)
			this.scene.add(mesh);
    })

		const clickableBoxGeometry = new THREE.BoxGeometry(1, 1, 1);
    const clickableBox =  new THREE.Mesh(clickableBoxGeometry, new THREE.MeshPhongMaterial( {
        color: 0xff0000,
        emissive:0xff0000,
        specular: 0xff0000,
        emissiveIntensity: 10,
        shininess: 500})
      )
    this.scene.add(clickableBox);
    this.raycaster.addClickableObject(clickableBox);

		const sphere = new THREE.SphereBufferGeometry(0.05, 16, 8);
		const light1 = new THREE.PointLight(0xff0000, 1, 10);
		light1.add(new THREE.Mesh(sphere, new THREE.MeshPhongMaterial( {
		  color: 0xff0000,
		  emissive:0xff0000,
		  specular: 0xff0000,
		  emissiveIntensity: 10,
		  shininess: 500})
    ));
		light1.castShadow = true;
    light1.position.set(41, 1.3, -24);
    this.interactiveObjects.bulbLight = light1;
    this.scene.add(light1);

    // this.flag = new Flag(this.scene, this.physicsWorld, Bandera, new THREE.Vector3(0,3,0), false);
/*
    this.flag = new Flag(this.scene, this.physicsWorld, BanderaGif, new THREE.Vector3(0,3,0), true);
    this.wind = new Wind(this.flag.mesh);

    this.gif = new GifAnimation(
      BanderaGif,
      new THREE.Vector3(2,3,0),
      new THREE.Vector3(-1.50, 1.890, 0),
      [3,3],
      [5, 10, 49, 15]
    );

    this.scene.add(this.gif.getMesh());
  */
  }

  update = () => {
    if(this.gif) this.gif.animate();
    this.raycaster.onMouseOver();
    // this.wind.update();
    // this.flag.update();
    // this.flag.applyWind(this.wind);
  }
}

export default VirtualSpace;
