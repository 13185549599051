import * as THREE from 'three';

const movementSpeed = .1;
const totalObjects = 1000;
const objectSize = 0.4;

export default class ExplodeAnimation {
  constructor(x, y, z, color = 0xFFFFFF, pointsTexture = null){
    this.color = color;
    this.pointsTexture = pointsTexture;
    this.dirs = [];
    this.init(x,y,z);
  }

  init = (x,y,z) => {
    const geometry = new THREE.Geometry();
    for (let i = 0; i < totalObjects; i++) {
      const vertex = new THREE.Vector3();
      vertex.x = x;
      vertex.y = y;
      vertex.z = z;

      geometry.vertices.push(vertex);
      this.dirs.push({
        x: (Math.random() * movementSpeed) - (movementSpeed / 2),
        y: (Math.random() * movementSpeed) - (movementSpeed / 2),
        z: (Math.random() * movementSpeed) - (movementSpeed / 2)
      });
    }
    const colorE = new THREE.Color();
    colorE.setRGB(Math.random(), Math.random(), Math.random());
    let materialP = new THREE.PointsMaterial({
      size: objectSize,
      transparent: true,
      map: this.pointsTexture,
    });

    const particles = new THREE.Points(geometry, materialP);
    particles.frustrumCulled = false;

    this.object = particles;
    this.xDir = (Math.random() * movementSpeed) - (movementSpeed / 2);
    this.yDir = (Math.random() * movementSpeed) - (movementSpeed / 2);
    this.zDir = (Math.random() * movementSpeed) - (movementSpeed / 2);
  }

  update = () => {
    let pCount = totalObjects;
    while (pCount--) {
      const particle = this.object.geometry.vertices[pCount]
      particle.y += this.dirs[pCount].y;
      particle.x += this.dirs[pCount].x;
      particle.z += this.dirs[pCount].z;
    }
    this.object.geometry.computeBoundingSphere();
    this.object.geometry.verticesNeedUpdate = true;
  }

  getObject = () => {
    return this.object;
  }
}
