import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { firebaseRealtimeDatabase } from '../util/firebaseConnection';
import { shouldMove, shouldNotMove } from '../store/actions';
import store from '../store/store';

const KurenniemiControls = () => {
  const dispatch = useDispatch();
  const [frequency, setFrequency] = useState(1);
  const [modulation, setModulation] = useState(1000);

  const { config } = store.getState();
  const { nombreDeEspacio, uuid } = config;

  const emitUpdate = () => {
    firebaseRealtimeDatabase
      .ref(`${nombreDeEspacio}${process.env.REACT_APP_FIREBASE_USERS_DATABASE_SUFFIX}/${uuid}`)
      .once('value', (snapshot) => {
        if (snapshot.val() !== null) {
          // update only if user exists in db
          snapshot.ref.update({
            id: uuid,
            modulation,
            frequency,
          });
        } else {
          console.warn('logout if user is not on the db');
          window.location.reload();
        }
      });
  };

  const updateFrequency = ({ target }) => {
    emitUpdate();
    setFrequency(target.value);
  };

  const updateModulation = ({ target }) => {
    setModulation(target.value);
    emitUpdate();
  };

  const showControls = nombreDeEspacio === 'Kurenniemi';

  return (
    <>
      {showControls && (
      <>
        <div>
          <label htmlFor="frequency-input">
            Frequency
            <br />
            <input
              id="frequency-input"
              type="range"
              min="0.1"
              max="1000"
              step=".1"
              value={frequency}
              onMouseDown={() => dispatch(shouldNotMove())}
              onMouseUp={() => dispatch(shouldMove())}
              onChange={updateFrequency}
            />
          </label>
        </div>
        <div>
          <label htmlFor="modulation-input">
            modulation
            <br />
            <input
              id="modulation-input"
              type="range"
              min="0"
              max="1000"
              step="1"
              value={modulation}
              onMouseDown={() => dispatch(shouldNotMove())}
              onMouseUp={() => dispatch(shouldMove())}
              onChange={updateModulation}
            />
          </label>
        </div>
      </>
      )}
    </>
  );
};

export default KurenniemiControls;
