import * as THREE from 'three';
import { PlainAnimator } from 'three-plain-animator/lib/plain-animator';

/*
spriteConfig:
[4, 4, 10, 15]
4	- number of frames horizontally
4	- number of frames vertically
10 - total number of frames
15 -	frames per second (fps)
*/

export default class GifAnimation {
  constructor(imageFile, position, rotation, size, spriteConfig, preventResizing = false) {
    const spriteTexture = new THREE.TextureLoader().load(imageFile);
    this.animator = new PlainAnimator(
      spriteTexture,
      spriteConfig[0],
      spriteConfig[1],
      spriteConfig[2],
      spriteConfig[3],
    );
    const texture = this.animator.init();
    if (preventResizing && false) {
      texture.generateMipmaps = false;
      texture.wrapS = THREE.ClampToEdgeWrapping;
      texture.wrapT = THREE.ClampToEdgeWrapping;
      texture.minFilter = THREE.LinearFilter;
    }

    const geometry = new THREE.PlaneGeometry(size[0], size[1]);

    /*
     const material = new THREE.MeshBasicMaterial({
      map: texture,
      depthWrite: false,
      transparent: true,
      side: THREE.DoubleSide,
    });
    */

    // matazanos
    const material = new THREE.MeshPhongMaterial({
      map: texture,
      depthWrite: false,
      transparent: true,
      side: THREE.DoubleSide,
    });

    this.mesh = new THREE.Mesh(geometry, material);

    this.mesh.castShadow = true;
    this.mesh.receiveShadow = false;

    this.mesh.position.set(position.x, position.y, position.z);
    this.mesh.rotation.set(rotation.x, rotation.y, rotation.z);
  }

  getMesh() {
    return this.mesh;
  }

  animate() {
    if (this.animator) this.animator.animate();
  }
}
