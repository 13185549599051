import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import './CardsList.scss';

export default function CardsList({
  text, image, link, detail, target = '_blank',
}) {
  return (
    <div className="cards-list">
      <a href={link} className="cards-list__link" target={target} rel="noopener noreferrer">
        <Card className="cards-list__card" square>
          <CardActionArea>
            <CardMedia className="cards-list__media" image={image} />
            <CardContent className="cards-list__content">
              {text}
              <br />
              <span className="cards-list__detail">{detail}</span>
            </CardContent>
          </CardActionArea>
        </Card>
      </a>
    </div>
  );
}
