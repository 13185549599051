import React from 'react';
import { withTranslation } from 'react-i18next';
import Tilt from 'react-tilt';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { ReactComponent as Logo } from '../../img/oxxxo-logo.svg';
import taalLogo from './img/logos/taal_logo.png';
import casiopea from './img/logos/casiopea.png';
import dessignare from './img/logos/dessignare.png';
import rockJuvenil from './img/logos/rock_juvenil.png';
import { ReactComponent as Manifiesto } from './img/logos/manifiesto.svg';
import { ReactComponent as Animasivo } from './img/logos/animasivo.svg';
import { ReactComponent as Calitic } from './img/logos/calitic.svg';
import { ReactComponent as FoncaLogo } from './img/logos/cultura_fonca.svg';
import { ReactComponent as FonimaLogo } from './img/logos/fonima_logo.svg';
import { ReactComponent as VentanaFonima } from './img/fonima_ventana.svg';
import { ReactComponent as GlitchIllustration } from './img/date.svg';
import './index.scss';

const Fonima = ({ t }) => (
  <>
    <div className="infoPage">
      <div className="infoPage__header">
        <Tilt className="Tilt infoPage__tilt" options={{ max: 25, perspective: 1000 }}>
          <VentanaFonima style={{ width: '80%' }} />
        </Tilt>
      </div>
      <div style={{ padding: '50px 20px', maxWidth: '800px', margin: 'auto' }}>
        <div className="infoPage__text">
          {t('fonima.main_text')}
        </div>

        <div className="grid">
          <div className="grid__container">
            <div className="grid__item">
              <div className="grid__item-outline">
                <div className="grid__item-header">
                  <span>+</span>
                </div>
                <div style={{ padding: '15px 10px' }}>
                  <h1>{t('infoPage.artists')}</h1>
                </div>
              </div>
            </div>
            <div className="grid__item" style={{ backgroundColor: '#BAF7C7' }}>
              <div className="grid__item-outline">
                <a
                  href="https://soundcloud.com/manitasnerviosas"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="grid__item-header">
                    <span>+</span>
                  </div>
                  <div style={{ padding: '15px 10px' }}>
                    Manitas Nerviosas
                    <br />
                    (CDMX)
                  </div>
                </a>
              </div>
            </div>
            <div className="grid__item">
              <div className="grid__item-outline">
                <a
                  href="https://soundcloud.com/manitasnerviosas"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="grid__item-picture"
                    style={{
                      backgroundImage: 'url(/pages/artists/manitas_nerviosas.jpg)',
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="grid__item">
              <div className="grid__item-outline">
                <div className="grid__item-header">
                  <span>+</span>
                </div>
              </div>
            </div>
            <div className="grid__item">
              <div className="grid__item-outline">
                <a
                  href="https://soundcloud.com/e-a_m-s"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="grid__item-picture"
                    style={{
                      backgroundImage: 'url(/pages/artists/eams.jpg)',
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="grid__item">
              <div className="grid__item-outline">
                <a
                  href="https://soundcloud.com/e-a_m-s"
                  target="_blank"
                  rel="noopener noreferrer"
                >

                  <div className="grid__item-header">
                    <span>+</span>
                  </div>
                  <div style={{ padding: '15px 10px' }}>
                    Eams
                    <br />
                    (Guadalajara)
                  </div>
                </a>
              </div>
            </div>
            <div className="grid__item" style={{ backgroundColor: '#A792FF' }}>
              <div className="grid__item-outline">
                <div className="grid__item-header">
                  <span>+</span>
                </div>
              </div>
            </div>
            <div className="grid__item">
              <div className="grid__item-outline">
                <a
                  href="https://grenda.bandcamp.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="grid__item-header">
                    <span>+</span>
                  </div>
                  <div style={{ padding: '15px 10px' }}>
                    Grenda
                    <br />
                    (Tijuana)
                  </div>
                </a>
              </div>
            </div>
            <div className="grid__item">
              <div className="grid__item-outline">
                <a
                  href="https://grenda.bandcamp.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="grid__item-picture"
                    style={{
                      backgroundImage: 'url(/pages/artists/grenda.jpg)',
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="grid__item">
              <div className="grid__item-outline">
                <a
                  href="https://www.facebook.com/child.mx.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div
                    className="grid__item-picture"
                    style={{
                      backgroundImage: 'url(/pages/artists/child.jpg)',
                    }}
                  />
                </a>
              </div>
            </div>
            <div className="grid__item" style={{ backgroundColor: '#FFB592' }}>
              <div className="grid__item-outline">
                <a
                  href="https://www.facebook.com/child.mx.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="grid__item-header">
                    <span>+</span>
                  </div>
                  <div style={{ padding: '15px 10px' }}>
                    Child
                    <br />
                    (UK)
                  </div>
                </a>
              </div>
            </div>
            <div className="grid__item">
              <div className="grid__item-outline" />
            </div>
          </div>
        </div>

        <br />
        <br />

        <h1>{t('infoPage.schedule')}</h1>
        <div className="infoPage__horarios">
          <div className="infoPage__horarios__column">
            <ul>
              <li>
                <span>10:00pm - 10:30pm</span>
                {' '}
                Eams
              </li>
              <li>
                <span>10:40pm - 11:10pm</span>
                {' '}
                Manitas Nerviosas
              </li>
              <li>
                <span>11:20pm - 11:50pm</span>
                {' '}
                Child
              </li>
              <li>
                <span>12:00pm - 12:30am</span>
                {' '}
                Grenda
              </li>
            </ul>
          </div>
        </div>

        <br />
        <div style={{ width: '40%', margin: 'auto' }}>
          <GlitchIllustration />
        </div>
        <br />
        <br />
        <div className="infoPage__logos">
          <a
            className="infoPage__logo"
            href="https://www.instagram.com/taal.mx/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={taalLogo} alt="taal.mx logo" style={{ filter: 'invert(0%)' }} />
          </a>
          <a className="infoPage__logo">
            <div className="login__logoBg">
              <Logo className="login__logo" style={{ width: '100%', filter: 'invert(95%)' }} />
            </div>
          </a>
          <a className="infoPage__logo">
            <FoncaLogo style={{ width: '100%' }} />
          </a>
          <a
            className="infoPage__logo"
            href="https://www.fonima.mx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FonimaLogo />
          </a>
          <a
            className="infoPage__logo"
            href="https://www.animasivo.net/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Animasivo style={{ width: '50%' }} />
          </a>
        </div>
        <div className="infoPage__logos">
          <a
            className="infoPage__logo"
            href="https://www.instagram.com/rockjuvenilmx/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={rockJuvenil} alt="taal.mx logo" style={{ maxHeight: '50px', filter: 'invert(0%)' }} />
          </a>
          <a
            className="infoPage__logo"
            href="https://calitic.com.mx/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="">
              <Calitic />
            </div>
          </a>
          <a
            className="infoPage__logo"
            href="https://www.casiopea.mx"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={casiopea} alt="taal.mx logo" style={{ filter: 'invert(0%)' }} />
          </a>
          <a
            className="infoPage__logo"
            href="https://www.bymanifiesto.mx/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Manifiesto />
          </a>
          <a
            className="infoPage__logo"
            href="https://www.dessignare.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={dessignare} alt="taal.mx logo" style={{ width: '60%', filter: 'invert(0%)' }} />
          </a>
        </div>
        <br />
        <br />
        <div style={{ textAlign: 'center', fontSize: '11px' }}>
          Proyecto beneficiado por el Sistema de apoyos a la creación y a proyectos culturales (Fonca).
        </div>
        <br />
        <br />
        <a href="/">
          <ArrowBackIosIcon />
        </a>
      </div>
    </div>
  </>
);

export default withTranslation()(Fonima);
