import { Noise } from 'noisejs';

function generateHeightMap(rows, cols, seed, frequency, amplitude) {
  const perlin = new Noise(seed);

  const heightMap = [];
  for (let i = 0; i < rows; i++) {
    const innerArray = [];
    for (let j = 0; j < cols; j++) {
      // Use Perlin noise to generate values between 0 and 1
      const value = perlin.perlin2(i / frequency, j / frequency) * amplitude;

      // Adjust the range to match the desired values in heightMap
      innerArray.push(value);
    }
    heightMap.push(innerArray);
  }

  return heightMap;
}

// Example usage with 10 rows and 20 columns, seed value 1234, frequency 10, and amplitude 50
const generatedHeightMap = generateHeightMap(100, 100, 91909, 8, 50);
// Example usage with 10 rows and 20 columns
export default generatedHeightMap;
