import * as CANNON from 'cannon-es';
//import CannonDebugRenderer from './lib/CannonDebugRenderer';
import cannonDebugger from 'cannon-es-debugger';
import { isDebugMode } from '../util/debug.util';

export default class PhysicsEngine {

  constructor(scene){
    this.scene = scene;
    this.world = new CANNON.World();
    this.world.quatNormalizeSkip = 0;
    this.world.quatNormalizeFast = false;

    const solver = new CANNON.GSSolver();
    solver.iterations = 7;
    solver.tolerance = 0.1;
    this.world.solver = new CANNON.SplitSolver(solver);
    this.world.gravity.set(0,-50,0);
    this.world.broadphase = new CANNON.NaiveBroadphase();
    this.createMaterial();
    this.createGround();
    this.initPhysicsDebugrenderer();
  }

  createMaterial = () => {
    this.slipperyMaterial = new CANNON.Material('slipperyMaterial');
    const slipperyContactMaterial = new CANNON.ContactMaterial(
      this.slipperyMaterial,
      this.slipperyMaterial,
      {
        friction: 0.2,
        restitution: 0.0,
      }
    );
    this.world.addContactMaterial(slipperyContactMaterial);

    this.groundMaterial = new CANNON.Material();

    const slipperyGroundCm = new CANNON.ContactMaterial(
        this.groundMaterial,
        this.slipperyMaterial,
        {
          friction: 0.003,
          restitution: 0.10,
        }
    );
    this.world.addContactMaterial(slipperyGroundCm);
  }

  createGround = () => {
    const groundShape = new CANNON.Plane();
    const groundBody = new CANNON.Body({ mass: 0, material: this.groundMaterial});
    groundBody.addShape(groundShape);
    groundBody.quaternion.setFromAxisAngle( new CANNON.Vec3(1,0,0), -Math.PI/2 );
    this.world.addBody(groundBody);
  }

  getWorld = () => {
    return this.world;
  }

  initPhysicsDebugrenderer = () => {
    if(isDebugMode()) {
      this.cannonDebugRenderer = cannonDebugger(this.scene, this.world.bodies);
    }
  }
/*
  getShape = (geometry, scales = 1) => {

    const parameters = geometry.parameters;

    switch ( geometry.type ) {
      case 'BoxBufferGeometry':
        const halfExtents = new CANNON.Vec3();
        console.log(scales)
        const x = scales;
        const y = scales;
        const z = scales;
        halfExtents.x = parameters.width !== undefined ? parameters.width / 2 * x: 0.5;
        halfExtents.y = parameters.height !== undefined ? parameters.height / 2 * y: 0.5;
        halfExtents.z = parameters.depth !== undefined ? parameters.depth / 2 * z: 0.5;
        console.log(halfExtents)
        return new CANNON.Box( halfExtents );

      case 'PlaneBufferGeometry':
        return new CANNON.Plane();

      case 'SphereBufferGeometry':
        const radius = parameters.radius;
        return new CANNON.Sphere(radius);
    }

    return null;
  }

  addMesh = (mesh, mass = 0) => {
    const shape = this.getShape(mesh.geometry);
    if (shape !== null) {
      if (mesh.isInstancedMesh) {
        this.handleInstancedMesh(mesh, shape, mass);
      } else if (mesh.isMesh) {
        this.handleMesh(mesh, mass, shape);
      }
    }
  }

  handleMesh = (mesh, mass, shape) => {
    const position = new CANNON.Vec3();
    position.copy(mesh.position);

    const quaternion = new CANNON.Quaternion();
    quaternion.copy(mesh.quaternion);

    const body = new CANNON.Body({
      position,
      quaternion,
      mass,
      shape,
    });
    this.world.addBody(body);
  }

  handleInstancedMesh = (mesh, shape, mass = 0) => {
    const array = mesh.instanceMatrix.array;
    const bodies = [];
    for (let i = 0; i < mesh.count; i ++) {
      const index = i * 16;
      const position = new CANNON.Vec3();
      position.set(
        array[index + 12],
        array[index + 13],
        array[index + 14]
      );



      const scaledShape = this.getShape(mesh.geometry, mesh.scales[i]);
      console.log(shape)
      console.log(scaledShape)
      const body = new CANNON.Body({
        position,
        mass,
        shape: scaledShape,
      });

      this.world.addBody(body);
    }
  }
*/
  update = () =>{
    this.world.step(1/60);
    if(this.cannonDebugRenderer !== undefined) this.cannonDebugRenderer.update();
  }

}
