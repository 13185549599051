import * as THREE from "three";
import * as CANNON from "cannon-es";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { RectAreaLightHelper } from "three/examples/jsm/helpers/RectAreaLightHelper";
import VirtualSpaceInterface from '../../../oxxxo/VirtualSpaceInterface';
import Raycaster from "../../../three-utils/Raycaster";
import createText from "../../../three-utils/createText";
import GifAnimation from "../../../three-utils/GifAnimation";
import ExplodeAnimation from "../../../three-utils/ExplodeAnimation";
import Bajapodcast from "../../../three-utils/flag/textures/ezgif.com-gif-maker.png";
import { ilustradores, ilustraciones, extras } from "./datosDeIlustradores";
import createOrtoedro from "./createOrtoedro";
import createPlaneLink from "./createPlaneLink";

class VirtualSpace extends VirtualSpaceInterface {
 
  createSpace = () => {
    this.renderer.physicallyCorrectLights = true;
    this.renderer.gammaOutput = false;
    this.renderer.gammaFactor = 1.5;
    this.renderer.outputEncoding = THREE.LinearEncoding;
    this.renderer.shadowMap.enabled = true; // hace la sombra
    this.renderer.toneMapping = THREE.ReinhardToneMapping;
    this.renderer.toneMappingExposure = 1.68 ** 5.0;

    const light = new THREE.AmbientLight(0x404040);
    this.scene.add(light);

    const fbxLoader = new FBXLoader();
   
    // instructions
    const instructions = createText(
      `Haz doble click en las ilustraciones\n
      para ir al sitio de lxs ilustradorxs`,
      0xa61469,
      0.2
    );
    instructions.position.set(50, 3, -4);
    this.scene.add(instructions);

    // pantalla matazanos
    const screenWidth = 5;
    const screenHeight = 2;
    const blueRectLightCeremonia = new THREE.RectAreaLight(
      0xf3aaaa,
      0,
      screenWidth,
      screenHeight
    );
    blueRectLightCeremonia.position.set(45, 5, -20);
    blueRectLightCeremonia.rotation.set(0, Math.PI, 0);

    this.scene.add(blueRectLightCeremonia);
    const blueRectLightCeremoniaHelper = new RectAreaLightHelper(
      blueRectLightCeremonia,
      0xffffff
    );
    blueRectLightCeremonia.add(blueRectLightCeremoniaHelper);
    blueRectLightCeremoniaHelper.color = new THREE.Color(1, 0, 0);
    blueRectLightCeremoniaHelper.update();
    this.interactiveObjects.blueRectLightCeremonia = blueRectLightCeremonia;
    this.interactiveObjects.blueRectLightCeremoniaHelper =
      blueRectLightCeremoniaHelper;

    const display1 = new THREE.Group();
    display1.position.set(45.1, 5, -19.9);
    this.interactiveObjects.display1 = display1;
    this.scene.add(display1);

    const sphere = new THREE.SphereGeometry(0.01, 16, 8);
    const light1 = new THREE.PointLight(0xff0000, 1, 20);
    light1.add(
      new THREE.Mesh(
        sphere,
        new THREE.MeshPhongMaterial({
          color: 0xff0000,
          emissive: 0xff0000,
          specular: 0xff0000,
          emissiveIntensity: 10,
          shininess: 500,
        })
      )
    );
    light1.castShadow = true;
    light1.position.set(45, 1.3, -20);
    this.interactiveObjects.luzEscenarioMatazanos = light1;
    this.scene.add(light1);

    // pantalla oxxxo
    const blueRectLightOxxxo = new THREE.RectAreaLight(
      0xf3aaaa,
      0,
      screenWidth,
      screenHeight
    );
    blueRectLightOxxxo.position.set(2, 3.5, 7.5);
    blueRectLightOxxxo.rotation.set(0, Math.PI * 1.5, 0);

    this.scene.add(blueRectLightOxxxo);
    const blueRectLightOxxxoHelper = new RectAreaLightHelper(
      blueRectLightOxxxo,
      0xffffff
    );
    blueRectLightOxxxo.add(blueRectLightOxxxoHelper);
    blueRectLightOxxxoHelper.color = new THREE.Color(1, 0, 0);
    blueRectLightOxxxoHelper.update();
    this.interactiveObjects.blueRectLightOxxxo = blueRectLightOxxxo;
    this.interactiveObjects.blueRectLightOxxxoHelper = blueRectLightOxxxoHelper;

    const display2 = new THREE.Group();
    display2.position.set(2.1, 3.5, 7.5);
    display2.rotation.set(0, Math.PI / 2, 0);
    this.interactiveObjects.display2 = display2;
    this.scene.add(display2);

    const light2 = new THREE.PointLight(0x55009f, 1, 20);
    light2.add(
      new THREE.Mesh(
        sphere,
        new THREE.MeshPhongMaterial({
          color: 0x55009f,
          emissive: 0xff0000,
          specular: 0xff0000,
          emissiveIntensity: 10,
          shininess: 500,
        })
      )
    );
    light2.castShadow = true;
    light2.position.set(2, 1.3, 7.5);
    this.interactiveObjects.luzEscenarioOxxxo = light2;
    this.scene.add(light2);

    this.textureLoader.load("textures/matazanos/suelo.jpg", (texture) => {
      const groundTexture = texture;
      groundTexture.wrapS = THREE.RepeatWrapping;
      groundTexture.wrapT = THREE.RepeatWrapping;
      groundTexture.encoding = THREE.sRGBEncoding;
      groundTexture.repeat.set(250, 250);
      groundTexture.anisotropy = 8;

      const groundMaterialz = new THREE.MeshPhongMaterial({
        map: groundTexture,
      });
      const mesh = new THREE.Mesh(
        new THREE.PlaneBufferGeometry(1000, 1000),
        groundMaterialz
      );
      mesh.receiveShadow = true;
      mesh.rotation.x = -Math.PI / 2;
      this.scene.add(mesh);
    });

    fbxLoader.load(
      "models/matazanos/harte.fbx",
      (loadedModel) => {
        loadedModel.position.set(0, 0, 0);
        loadedModel.scale.set(1, 1, 1);
        this.scene.add(loadedModel);
      },
      undefined,
      (error) => {
        console.error(error);
      }
    );

    let centerX = -45;
    let centerY = -20;
    let radius = 15;
    let items = ilustradores.length;
    let i = 0;

    ilustradores.map((ilustrador, i) => {
      const x = centerX + radius * Math.sin((2 * Math.PI * i) / items);
      const z = centerY + radius * Math.cos((2 * Math.PI * i) / items);

      const octoedro = createOrtoedro({
        textureLoader: this.textureLoader,
        raycaster: this.raycaster,
        physicsWorld: this.physicsWorld,
        image: `textures/matazanos/mesas/${ilustrador.nombre}`,
        url: ilustrador.link,
        position: new THREE.Vector3(-x, 0.5, z),
        rotation: new THREE.Vector3(0, Math.sin((4 * Math.PI * i) / items), 0),
      });
      this.scene.add(octoedro);
    });

    centerX = 100;
    centerY = -58;
    radius = 20;
    items = ilustraciones.length;
    i = 0;

    ilustraciones.map((ilustrador, i) => {
      const x = centerX + radius * Math.sin((2 * Math.PI * i) / items);
      const z = centerY + radius * Math.cos((2 * Math.PI * i) / items);

      createPlaneLink({
        textureLoader: this.textureLoader,
        raycaster: this.raycaster,
        scene: this.scene,
        image: `textures/matazanos/ilustradores/${ilustrador.nombre}.png`,
        url: ilustrador.link,
        position: new THREE.Vector3(x, 2, z),
        size: { height: 4, width: 4 },
      });
    });

    extras.map((extra) => {
      createPlaneLink({
        textureLoader: this.textureLoader,
        raycaster: this.raycaster,
        scene: this.scene,
        image: `textures/matazanos/props/${extra.nombre}.png`,
        url: extra.link ?? undefined,
        position: new THREE.Vector3(extra.x, extra.y, extra.z),
        size: { height: extra.height, width: extra.width },
      });
    });

    const light4 = new THREE.PointLight(0xff6060, 10, 7);
    light4.add(
      new THREE.Mesh(
        sphere,
        new THREE.MeshPhongMaterial({
          color: 0xff0000,
          emissive: 0xff0000,
          specular: 0xff0000,
          emissiveIntensity: 10,
          shininess: 500,
        })
      )
    );
    light4.castShadow = true;
    light4.position.set(70, 2, -4);
    this.scene.add(light4);

    this.scene.add(
      createOrtoedro({
        textureLoader: this.textureLoader,
        raycaster: this.raycaster,
        physicsWorld: null,
        image: "textures/matazanos/oxxxo/oxxxo",
        url: "https://www.instagram.com/_matazanos_/",
        position: new THREE.Vector3(1, 5, 1),
        rotation: new THREE.Vector3(0, 0, 0),
        width: 25,
        height: 15,
      })
    );

    this.scene.add(
      createOrtoedro({
        textureLoader: this.textureLoader,
        raycaster: this.raycaster,
        physicsWorld: null,
        image: "textures/matazanos/oxxxo/oxxxo_interior",
        url: "https://www.instagram.com/_matazanos_/",
        position: new THREE.Vector3(1, 5, 1.1),
        rotation: new THREE.Vector3(0, 0, 0),
        width: 24.7,
        height: 14.7,
      })
    );

    this.scene.add(
      createOrtoedro({
        textureLoader: this.textureLoader,
        raycaster: this.raycaster,
        physicsWorld: null,
        image: "textures/matazanos/ricosjugos/afuera",
        url: "https://www.instagram.com/ricosjugos",
        position: new THREE.Vector3(70, 5, 20),
        rotation: new THREE.Vector3(Math.PI, 0, 0),
        width: 20,
        height: 10,
        ancho: 2,
      })
    );

    this.scene.add(
      createOrtoedro({
        textureLoader: this.textureLoader,
        raycaster: this.raycaster,
        physicsWorld: null,
        image: "textures/matazanos/ricosjugos/interiores",
        url: "https://www.instagram.com/ricosjugos",
        position: new THREE.Vector3(70, 5, 20.1),
        rotation: new THREE.Vector3(Math.PI, 0, 0),
        width: 19.7,
        height: 9.7,
        ancho: 2,
      })
    );

    const light3 = new THREE.PointLight(0x905090, 3, 15);
    light3.add(
      new THREE.Mesh(
        sphere,
        new THREE.MeshPhongMaterial({
          color: 0xff0000,
          emissive: 0xff0000,
          specular: 0xff0000,
          emissiveIntensity: 10,
          shininess: 500,
        })
      )
    );
    light3.position.set(70, 3, 30);
    this.scene.add(light3);

    for (let step = 1; step < 50; step += 1) {
      const anchura = 2;
      const profundidad = 0.5;
      const altura = 0.2;
      const posX = 45;
      const posY = 0;
      const posZ = -70;

      const escalera = new CANNON.Box(
        new CANNON.Vec3(anchura, altura, profundidad)
      );
      const escaleraBody = new CANNON.Body({ mass: 0, shape: escalera });
      escaleraBody.position.set(
        posX + 0,
        posY + altura * 2 * step,
        posZ + profundidad * 2 * step
      );
      this.physicsWorld.addBody(escaleraBody);
      const color = new THREE.Color(`rgb(${255 - step * 3},${0},${step * 3})`);
      const geometry = new THREE.BoxGeometry(
        anchura * 2,
        altura * 2,
        profundidad * 2
      );
      const mesh = new THREE.Mesh(
        geometry,
        new THREE.MeshPhongMaterial({ color })
      );
      mesh.position.set(
        posX + 0,
        posY + altura * 2 * step,
        posZ + profundidad * 2 * step
      );
      this.scene.add(mesh);
    }

    const textureLoader = new THREE.TextureLoader();
    textureLoader.load(
      "textures/matazanos/domo/frosh_panoramica.png",
      (texture) => {
        // texture.mapping = THREE.UVMapping;
        const geometry = new THREE.CylinderBufferGeometry(
          10,
          10,
          10,
          32,
          undefined,
          true
        );

        // const geometry = new THREE.SphereBufferGeometry(10, 32, 16)
        const fondo = new THREE.Mesh(
          geometry,
          new THREE.MeshPhongMaterial({ map: texture })
        );
        fondo.material.lightMapIntensity = 0.02;
        fondo.material.side = THREE.DoubleSide;
        fondo.position.set(45, 4, -20);
        this.interactiveObjects.ThreeSixtyBackground = fondo;
        this.scene.add(fondo);
      }
    );

    textureLoader.load("textures/matazanos/cielo.jpg", (texture) => {
      // texture.mapping = THREE.UVMapping;
      const geometry = new THREE.SphereGeometry(700, 4, 4);
      const cielo = new THREE.Mesh(
        geometry,
        new THREE.MeshPhongMaterial({ map: texture })
      );
      cielo.material.lightMapIntensity = 0.02;
      cielo.material.side = THREE.DoubleSide;
      cielo.position.set(0, 0, 0);
      this.interactiveObjects.sky = cielo;
      this.scene.add(cielo);
    });

    this.gif = new GifAnimation(
      Bajapodcast,
      new THREE.Vector3(32, 2, -55),
      new THREE.Vector3(0, 0, 0),
      [2, 2],
      [5, 6, 49, 15]
    );

    this.scene.add(this.gif.getMesh());

    /************* physics ************/
    const mass = 0;

    // galeria
    let shape = new CANNON.Sphere(22);
    let body = new CANNON.Body({ mass, shape });
    body.position.set(102, 19, -56);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 19));
    body = new CANNON.Body({ mass, shape });
    body.position.set(70, 2, -58);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 19));
    body = new CANNON.Body({ mass, shape });
    body.position.set(134, 2, -58);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(19, 7, 1));
    body = new CANNON.Body({ mass, shape });
    body.position.set(100, 2, -25);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(19, 7, 1));
    body = new CANNON.Body({ mass, shape });
    body.position.set(100, 2, -90);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(6.5, 7, 1));
    body = new CANNON.Body({ mass, shape });
    body.position.set(120, 2, -33);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(6.5, 7, 1));
    body = new CANNON.Body({ mass, shape });
    body.position.set(83.5, 2, -33);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(7.5, 7, 1));
    body = new CANNON.Body({ mass, shape });
    body.position.set(84.5, 2, -81.5);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(8, 7, 1));
    body = new CANNON.Body({ mass, shape });
    body.position.set(119, 2, -81.5);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 8));
    body = new CANNON.Body({ mass, shape });
    body.position.set(126, 2, -73);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 8));
    body = new CANNON.Body({ mass, shape });
    body.position.set(126, 2, -41);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 8));
    body = new CANNON.Body({ mass, shape });
    body.position.set(77, 2, -40);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 8));
    body = new CANNON.Body({ mass, shape });
    body.position.set(77, 2, -74);
    this.physicsWorld.addBody(body);

    // ricos jugos
    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 10));
    body = new CANNON.Body({ mass, shape });
    body.position.set(80, 3, 30);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 10));
    body = new CANNON.Body({ mass, shape });
    body.position.set(60, 3, 30);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(10, 7, 1));
    body = new CANNON.Body({ mass, shape });
    body.position.set(70, 3, 40);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(8, 7, 1));
    body = new CANNON.Body({ mass, shape });
    body.position.set(72, 3, 20);
    this.physicsWorld.addBody(body);

    //  oxxxo
    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 8));
    body = new CANNON.Body({ mass, shape });
    body.position.set(-11, 5, 8);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(12, 7, 1));
    body = new CANNON.Body({ mass, shape });
    body.position.set(1, 5, 16);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(12, 7, 1));
    body = new CANNON.Body({ mass, shape });
    body.position.set(1, 5, 1);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 2));
    body = new CANNON.Body({ mass, shape });
    body.position.set(13, 5, 14);
    this.physicsWorld.addBody(body);

    shape = new CANNON.Box(new CANNON.Vec3(1, 7, 2));
    body = new CANNON.Body({ mass, shape });
    body.position.set(13, 5, 3);
    this.physicsWorld.addBody(body);
  };

  explosion = () => {
    if (this.interactiveObjects.explode) {
      this.scene.remove(this.interactiveObjects.explode.getObject());
    }
    this.interactiveObjects.explode = new ExplodeAnimation(1, 3.5, 8);
    this.scene.add(this.interactiveObjects.explode.getObject());
    setTimeout(() => {
      if (this.interactiveObjects.explode) {
        this.scene.remove(this.interactiveObjects.explode.getObject());
      }
      delete this.interactiveObjects.explode;
    }, 15000);
  };

  applyDynamicSettingsChanges(data) {
    super.applyDynamicSettingsChanges(data);
    if (data.display1) {
      try {
        const displayGroup1 = this.interactiveObjects.display1;
        for (let i = displayGroup1.children.length - 1; i >= 0; i -= 1) {
          displayGroup1.remove(displayGroup1.children[i]);
        }
        const display1 = createText(data.display1, 0x000000, 0.5);
        displayGroup1.add(display1);
      } catch (err) {
        console.log(err);
      }
    }
    if (data.display2) {
      try {
        const displayGroup2 = this.interactiveObjects.display2;
        for (let i = displayGroup2.children.length - 1; i >= 0; i -= 1) {
          displayGroup2.remove(displayGroup2.children[i]);
        }
        const display2 = createText(data.display2, 0x000000, 0.5);
        displayGroup2.add(display2);
      } catch (err) {
        console.log(err);
      }
    }
    if (data.imagenDeDomo) {
      console.log(data.imagenDeDomo);
      try {
        this.textureLoader.load(data.imagenDeDomo, (texture) => {
          console.log(texture);
          this.interactiveObjects.ThreeSixtyBackground.material.map = texture;
          this.interactiveObjects.ThreeSixtyBackground.material.map.needsUpdate = true;
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  update = () => {
    if (this.interactiveObjects.explode) {
      this.interactiveObjects.explode.update();
    }
    if (this.gif) {
      this.gif.animate();
    }

    if (this.interactiveObjects.ThreeSixtyBackground) {
      this.interactiveObjects.ThreeSixtyBackground.rotation.y += 0.001;
    }
    if (this.interactiveObjects.sky) {
      this.interactiveObjects.sky.rotation.y += 0.0005;
    }

    if (this.audioStreams[0]) {
      // matazanos
      this.audioStreams[0].renderFftData();
      const audioData = this.audioStreams[0].analyser.data[0];
      const hex = this.audioStreams[0].mapRange(
        Math.max(150, audioData),
        150,
        220,
        -0.5,
        0.5
      );
      this.interactiveObjects.luzEscenarioMatazanos.intensity = Math.max(
        0,
        hex * 10
      );
      // this.interactiveObjects.luzEscenarioMatazanos.position.y = Math.cos(this.time*0.0005)*0.75+1.25;
      this.interactiveObjects.blueRectLightCeremonia.intensity = hex;
      this.interactiveObjects.blueRectLightCeremoniaHelper.color =
        new THREE.Color(hex, hex, hex);
      this.interactiveObjects.blueRectLightCeremoniaHelper.update();
    }

    if (this.audioStreams[1]) {
      // oxxxo
      this.audioStreams[1].renderFftData();
      const audioData = this.audioStreams[1].analyser.data[0];
      const hex = this.audioStreams[0].mapRange(
        Math.max(150, audioData),
        150,
        220,
        -0.5,
        0.5
      );
      this.interactiveObjects.luzEscenarioOxxxo.intensity = Math.max(
        0,
        hex * 10
      );
      this.interactiveObjects.blueRectLightOxxxo.intensity = hex;
      this.interactiveObjects.blueRectLightOxxxoHelper.color = new THREE.Color(
        hex,
        hex,
        hex
      );
      this.interactiveObjects.blueRectLightOxxxoHelper.update();
    }
  };
}
export default VirtualSpace;
