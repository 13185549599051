import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { withTranslation } from 'react-i18next';
import { flag } from 'country-emoji';
import AvatarSelector from '../AvatarSelector';
import ScrollDownButton from '../ScrollDownButton';
import { ReactComponent as Logo } from '../../img/oxxxo-logo.svg';
import randomAvatarPosition from './randomAvatarPosition';
import getCountryName from '../../util/getCountryName';
import './Login.scss';

const Login = ({
  username, setUsername, unableToConnect, connected, t, setConnected,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedAvatar, setSelectedAvatar] = useState(0);
  const [country, setCountry] = useState('');
  const [countryFlag, setCountryFlag] = useState('');

  useEffect(() => {
    getCountryName().then(({ country_code }) => {
      setCountry(country_code);
      const flagEmoji = flag(country_code);
      setCountryFlag(flagEmoji);
    });
  }, []);

  const login = () => {
    if (!username) return;
    setLoading(true);
    setTimeout(() => {
      const initialAvatarPosition = randomAvatarPosition();
      setConnected(selectedAvatar, initialAvatarPosition, country, countryFlag);
    }, 0);
  };

  const onKeyPressHandler = (event) => {
    if (event.charCode === 13) {
      login();
    }
  };

  const onBlurHandler = () => {
    setUsername(username.trim());
  };

  const onChangeHandler = (event) => {
    setUsername(event.target.value);
  };

  return (
    <div className={`login login--fonima ${connected ? 'login--hide' : ''}`}>
      <div>
        {username
          ? <AvatarSelector selectedAvatar={selectedAvatar} setSelectedAvatar={setSelectedAvatar} />
          : (
            <div className="login__logoBg" style={{ minWidth: '0%' }}>
              <Logo className="login__logo" />
            </div>
          )}
        <br />
        <br />
        {!loading && !unableToConnect
                && (
                <TextField
                  maxLength="20"
                  className="login__name-input"
                  placeholder={t('login.name_placholder')}
                  onKeyPress={onKeyPressHandler}
                  onBlur={onBlurHandler}
                  onChange={onChangeHandler}
                  value={username}
                />
                )}
        {unableToConnect && <div> hay demasiados usuarios conectados, no puedes entrar por el momento</div>}
        <br />
        <br />

        {!unableToConnect && (
        <button
          type="submit"
          disabled={connected}
          className="login__button login__button--pulsate"
          onClick={login}
        >
          {t('login.enter')}
        </button>
        )}

        <br />
        <br />
        {!username && <ScrollDownButton />}
      </div>
    </div>
  );
};

export default withTranslation()(Login);
