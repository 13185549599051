import * as THREE from "three";
import { SkeletonUtils }from 'three/examples/jsm/utils/SkeletonUtils';
import { importedModels } from '../../three-utils/modelsLoader';

export default class CharacterModel{
  constructor(modelName){
    const importedModel = importedModels[modelName];
    this.model = SkeletonUtils.clone(importedModel.model);
    this.mixer = new THREE.AnimationMixer(this.model);
    this.clip = importedModel.animation;
    this.action = this.mixer.clipAction(this.clip);
  }

  getModel = () => {
    return this.model;
  }

  playAnimation = () => {
    this.action.play();
  }

  stopAnimation = () => {
    this.action.stop();
  }

  updateAnimation = (delta) => {
    this.mixer.update(delta)
  }

}
