import { firebaseFirestore } from '../../util/firebaseConnection';

export default class FirebaseChat {
  constructor(nombreDeEspacio) {
    this.db = firebaseFirestore;
    this.collection = `${nombreDeEspacio}-chat`;
  }

  sendChatMessage(data) {
    this.db
      .collection(this.collection)
      .add(data);
  }

  receiveChatMessage(callback) {
    this.db
      .collection(this.collection)
      .orderBy('timestamp', 'asc')
      .limitToLast(Number(process.env.REACT_APP_FIREBASE_LIMIT_OF_CHAT_MESSAGES))
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            callback(change.doc.data());
          }
        });
      }, (error) => {
        console.log(error);
      });
  }
}
