const getCountryName = () => fetch(`${window.location.protocol}//api.ipify.org/?format=json`)
  .then((response) => response.json())
  .then(({ ip }) => fetch(`${window.location.protocol}//www.iplocate.io/api/lookup/${ip}?apikey=${process.env.REACT_APP_IPLOCATE_API_KEY}`))
  .then((response) => response.json())
  .then(({ country, country_code }) => ({ country, country_code }))
  .catch((e) => { console.error(e); });

/*
  const pass = pasaporte(props.urlQuery);
  console.log(pass)
  if(pass){
    setUsername(pass.nombre);
    initUser(pass.avatar, pass.lugar).then(() => {
      props.setConnected(pass.nombre, pass.avatar, pass.lugar);
      props.shouldMove();
    });
  }
  */

export default getCountryName;
