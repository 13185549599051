import * as THREE from 'three';
import { importedModels } from './modelsLoader';

const createText = (text, color, size) => {
  let matDark;
  if (color) {
    matDark = new THREE.MeshBasicMaterial({
      color: new THREE.Color(color),
      side: THREE.DoubleSide,
    });
  } else {
    matDark = new THREE.MeshNormalMaterial();
  }
  const shapes = importedModels.font.generateShapes(text, size);
  const textGeometry = new THREE.ShapeBufferGeometry(shapes);
  textGeometry.computeBoundingBox();
  const xMid = -0.5 * (textGeometry.boundingBox.max.x - textGeometry.boundingBox.min.x);
  textGeometry.translate(xMid, 0, 0);
  return new THREE.Mesh(textGeometry, matDark);
};

export default createText;
