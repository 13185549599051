import * as CANNON from 'cannon-es';

const createCylinder = (position, cylinderShape) => {
  const [x, y, z] = position;
  const [radiusTop, radiusBottom, height, numSegments] = cylinderShape;
  const shape = new CANNON.Cylinder(
    radiusTop,
    radiusBottom,
    height,
    numSegments,
  );
  const body = new CANNON.Body({ mass: 0 });
  const quat = new CANNON.Quaternion(1.5, 0, 0, 0);
  quat.normalize();
  body.addShape(shape, new CANNON.Vec3(), quat);
  body.position.set(x, y, z);
  return body;
};

const terrainData = [
  {
    position: [190, 10, 25],
    cylinderShape: [40, 5, 50, 10],
  },
  {
    position: [237.250, 3, -39.750],
    cylinderShape: [40, 5, 50, 10],
  },
  {
    position: [237.950, 0, -8.860],
    cylinderShape: [45, 5, 28, 10],
  },
  {
    position: [272.180, 32.4, -66.890],
    cylinderShape: [37, 6, 67, 10],
  },
  {
    position: [-61.80, 0, 311.80],
    cylinderShape: [45, 5, 28, 10],
  },
  {
    position: [-81.833, 10, 255.361],
    cylinderShape: [40, 5, 50, 10],
  },
  {
    position: [-17.150, 32.4, 345.410],
    cylinderShape: [37, 6, 67, 10],
  },
  {
    position: [-30.20, 3, 305.49],
    cylinderShape: [40, 5, 50, 10],
  },
];

const initTerrainPhysics = (physicsWorld) => {
  terrainData.forEach((item) => {
    const body = createCylinder(item.position, item.cylinderShape);
    physicsWorld.addBody(body);
  });
};

export default initTerrainPhysics;
