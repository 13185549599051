import * as THREE from 'three';
import * as Tone from 'tone';
import { MeshLine, MeshLineMaterial } from 'three.meshline';
import { Noise } from 'noisejs';
import { firebaseRealtimeDatabase } from '../../util/firebaseConnection';
import store from '../../store/store';
import createAudioNodes from './createAudioNodes';

export default class ErkkiObject {
  constructor(userAvatar, audioStream) {
    this.users = {};
    this.userAvatarPosition = userAvatar.getPhysicsBody().position;
    this.noteOn = 1;
    this.object3D = new THREE.Object3D();
    this.noise = new Noise(Math.random());
    this.audioStream = audioStream;
    this.audioContext = audioStream.context;
    // this.initAudio(userAvatar);
    Tone.setContext(this.audioContext);
    this.initReverb();
    this.initListeners();
  }

  initReverb() {
    this.reverb = new Tone.Reverb({
      decay: 4,
      wet: 0.8,
    });
    this.feedbackDelay = new Tone.FeedbackDelay('8n', 0.5);
    this.feedbackDelay.feedback.value = 0.7;

    this.phaser = new Tone.Vibrato({
      frequency: 1, // modulation frequency in Hz
      depth: 0.4, // modulation depth (0 to 1)
      type: 'sine',
    });
    this.chorus = new Tone.Chorus({
      frequency: 1.5, // Modulation frequency in Hz
      delayTime: 3.5, // Delay time in milliseconds
      depth: 0.7, // Modulation depth
      type: 'sine', // Oscillator type for modulation ('sine', 'square', 'triangle', 'sawtooth')
      spread: 180, // Spread of the effect across the stereo field
    });
  }

  /*
  initAudio(userAvatar) {
    this.listener = new THREE.AudioListener();
    this.positionalAudio = new THREE.PositionalAudio(this.listener);
    this.positionalAudio.setRolloffFactor(15);
    this.positionalAudio.setDistanceModel('exponential');
    this.positionalAudio.setRefDistance(80);

    this.audioContext = this.positionalAudio.context;
    this.object3D.add(this.positionalAudio);
    userAvatar.getModelMesh().add(this.listener);
  }
  */
  initListeners() {
    const { config } = store.getState();
    const { nombreDeEspacio } = config;

    const db = firebaseRealtimeDatabase;
    const ref = db.ref(`${nombreDeEspacio}${process.env.REACT_APP_FIREBASE_USERS_DATABASE_SUFFIX}`);

    ref.on('child_added', (snapshot) => {
      const userData = snapshot.val();
      const notUpdatedLately = Date.now() - userData.lastUpdate > 1800000;
      if (!userData.username || notUpdatedLately || this.users[userData.id]) {
        console.log('user not updated lately or already exists');
        return;
      }
      this.users[userData.id] = {};
      this.users[userData.id].userData = userData;
      this.addUserAudio(userData);
      this.disconnectUserAudioNodes();
      this.connectUserAudioNodes();
      this.addUserGeometry(userData);
    });

    ref.on('child_removed', (snapshot) => {
      const userData = snapshot.val();
      const { id } = userData;
      if (!this.users[id]) {
        return;
      }

      this.removeUserAudio(id);
      this.removeUserGeometry(id);
      delete this.users[id];
      this.disconnectUserAudioNodes();
      this.connectUserAudioNodes();
    });

    ref.on('child_changed', (snapshot) => {
      const userData = snapshot.val();
      const { id, frequency, modulation } = userData;
      if (this.users[id]) {
        this.users[userData.id].userData = userData;
        this.users[id].audioNodes.osc.frequency.value = frequency;
        this.users[id].audioNodes.gain.gain.value = modulation;
      }
    });
  }

  removeUserGeometry(id) {
    this.object3D.remove(this.users[id].object);
  }

  addUserAudio(userData) {
    const audioNodes = createAudioNodes(userData, this.audioContext);
    /*
    const { osc, gain } = audioNodes;
    gain.gain.value = 0.01;
    // osc.connect(gain);
    const gainOut = this.audioContext.createGain();
    // gain.gain.value = 3000;
    gainOut.gain.value = 0.31;
    osc.connect(gainOut);
    this.positionalAudio.setNodeSource(gainOut); // gain.gain?
    */
    this.users[userData.id].audioNodes = audioNodes;
  }

  removeUserAudio(id) {
    const { audioNodes } = this.users[id];
    if (audioNodes) {
      audioNodes.osc.stop();
      audioNodes.osc.disconnect();
      audioNodes.gain.disconnect();
      delete audioNodes.osc;
      delete audioNodes.gain;
    }
  }

  connectUserAudioNodes() {
    let firstElement = true;
    let previousOsc;

    Object.keys(this.users).forEach((id) => {
      const { audioNodes } = this.users[id];
      const { osc, gain } = audioNodes;

      if (firstElement) {
        const gainOut = this.audioContext.createGain();
        gainOut.gain.value = 0.2;

        // osc.connect(gainOut);
        // this.audioStream.setNodeSource(gainOut);

        const envelope = this.audioContext.createGain();
        envelope.gain.value = 0;
        // osc.connect(envelope);

        Tone.connect(osc, this.feedbackDelay);
        Tone.connect(this.feedbackDelay, this.reverb);
        Tone.connect(this.reverb, this.phaser);
        Tone.connect(this.phaser, this.chorus);
        Tone.connect(this.chorus, gainOut);
        this.audioStream.setNodeSource(gainOut);

        const notes = ['C4', 'E4', 'G4', 'A4'];
        let index = 0;

        Tone.Transport.scheduleRepeat((time) => {
        // Calculate the note to play and the next note's index
          const note = notes[index % notes.length];
          index += 1;

          // Set the oscillator frequency based on the note
          const noteFrequency = Tone.Frequency(note).toFrequency();
          osc.frequency.setValueAtTime(noteFrequency, time);

          // "Note on" - increase gain
          envelope.gain.setValueAtTime(1, time);

          // "Note off" - decrease gain shortly afterwards
          envelope.gain.setValueAtTime(0, time + 0.1);

          this.noteOn = noteFrequency / 100;

          // Schedule to return cube to original size at note off (after 8th note duration)
          Tone.Draw.schedule(() => {
            this.noteOn = 1;
          }, time + Tone.Time('16n').toSeconds());
        }, '8n');

        //  Tone.connect(this.reverb, this.audioContext.destination);
        // this.audioStream.setNodeSource(this.reverb);

        // Tone.Transport.start();

        firstElement = false;
        previousOsc = osc;
      } else {
        gain.connect(previousOsc.frequency);
        // connect each audio node gain to the previous user osc
        previousOsc = osc;
      }
    });
  }

  disconnectUserAudioNodes() {
    Object.keys(this.users).forEach((id) => {
      const { audioNodes } = this.users[id];
      const { gain } = audioNodes;
      gain.disconnect();
    });
  }

  addUserGeometry(userData) {
    const userObject = new THREE.Object3D();
    const curve = new THREE.CubicBezierCurve3(
      new THREE.Vector3(-1, 0, 0),
      new THREE.Vector3(-1, 1.5, 3),
      new THREE.Vector3(2, 1.5, -2),
      new THREE.Vector3(1, 0, 2),
      new THREE.Vector3(1, 1, 0),
      new THREE.Vector3(-1, -1.5, 3),
      new THREE.Vector3(-2, 1.5, 4),
      new THREE.Vector3(-1, 1, 2),
    );
    const pointsCurve = curve.getPoints(150);
    const geometryCurve = new THREE.BufferGeometry().setFromPoints(pointsCurve);

    const lineCurve = new MeshLine();
    lineCurve.setGeometry(geometryCurve);
    const geometry = new THREE.Geometry().fromBufferGeometry(lineCurve);

    const rand = (offset, amount) => offset + (0.5 - Math.random()) * amount;
    const color = new THREE.Color(Math.random() * 0xFF00FF);

    for (let x = 0; x < 10; x += 1) {
      const material = new MeshLineMaterial({ color });
      const splineObject = new THREE.Line(geometry, material);
      splineObject.position.set(rand(5, 3), rand(3, 3), rand(-10, 3));
      splineObject.rotation.set(rand(0, 3), rand(0, 3), rand(0, 3));
      splineObject.scale.set(2, 2, 2);
      userObject.add(splineObject);
    }
    this.object3D.add(userObject);
    this.users[userData.id].object = userObject;
    this.users[userData.id].geometry = geometry;
  }

  getObject3D() {
    return this.object3D;
  }

  updateReverb(distance) {
    const maxDistance = 80;
    const wetLevel = Math.max(0, Math.min((distance - 10) / (maxDistance - 10), 1));
    this.reverb.wet.value = wetLevel;
    this.feedbackDelay.feedback.value = Math.max(0, wetLevel - 0.3);
  }

  update() {
    const distance = this.userAvatarPosition.distanceTo(new THREE.Vector3(0, 0, 0));
    this.updateReverb(distance);
    Object.keys(this.users).forEach((id) => {
      const { geometry, userData } = this.users[id];

      const time = performance.now() * 0.0005;
      // const k = 3;
      const k = userData.frequency / 10;
      const modTime = (time % 10);
      const mod = (userData.modulation / 100) + modTime;

      for (let i = 0; i < geometry.vertices.length; i++) {
        const p = geometry.vertices[i];
        p.normalize().multiplyScalar(
          1 + (mod * 0.003 * i) + this.noise.perlin3(p.x * k + time, p.y * k + mod, p.z * k * mod) + this.noteOn,
        );
      }
      geometry.computeVertexNormals();
      geometry.normalsNeedUpdate = true;
      geometry.verticesNeedUpdate = true;
    });
  }
}
