import * as THREE from "three";
import vertexShader from "./glsl/vertex";
import fragmentShader from "./glsl/fragment";

export default class FlagShader {
  constructor(img){
    this.clock = new THREE.Clock();
    this.geometry = new THREE.PlaneGeometry(5, 5, 2, 2);
    this.material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      uniforms: {
        uTime: { value: 0.0 },
        uTexture: { value: new THREE.TextureLoader().load(img) }
      },
      side: THREE.DoubleSide
    });
    this.mesh = new THREE.Mesh(this.geometry, this.material);
  }

  getMesh = () => this.mesh;

  update = () => {
    this.material.uniforms.uTime.value = this.clock.getElapsedTime();
  }
}
