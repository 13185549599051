import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Div100vh from 'react-div-100vh';
import { isMobile } from 'react-device-detect';
import { flag } from 'country-emoji';
import { shouldMove, setOxxxoConfig } from './store/actions';
import Oxxxo from './oxxxo/Oxxxo';
import Login from './components/Login';
import LandingInfo from './components/LandingInfo';
import { loadAvatarModels } from './three-utils/modelsLoader';
import { FullPageLoader } from './components/Loader';
import Instructions from './components/InstructionsSlider';
import getCountryName from './util/getCountryName';
import { setDebug } from './util/debug.util';
import pasaporte from './util/pasaporte';
import initUser from './util/initUser';
import isUsersLimitReached from './util/isUsersLimitReached';
import MainContainer from './components/MainContainer';
import './style.scss';

const App = ({
  settings, urlQuery, uuid,
}) => {
  const dispatch = useDispatch();
  const { virtualSpace, avatarLight, nombreDeEspacio } = settings;
  const urlQueryParams = new URLSearchParams(urlQuery);
  const initialInstructionsCompleted = setDebug(urlQueryParams);
  const mainContainer = React.createRef();
  const controlsContainer = React.createRef();
  const chatContainer = React.createRef();

  const [loading, setLoading] = useState(true);
  const [connected, setConnected] = useState(false);
  const [username, setUsername] = useState(null);
  const [unableToConnect, setUnableToConnect] = useState(false);
  const [instructionsCompleted, setInstructionsCompleted] = useState(initialInstructionsCompleted);
  const [oxxxo, setOxxxo] = useState(null);

  const connect = async (avatar, initialAvatarPosition, country, countryFlag) => {
    await initUser(
      nombreDeEspacio, uuid, avatar, username, initialAvatarPosition, country, countryFlag
    );
    dispatch(setOxxxoConfig({ ...settings, uuid }));

    oxxxo.init(avatar, initialAvatarPosition);

    setConnected(true);
    dispatch(shouldMove());
  };

  const asyncLoad = async () => {
    await loadAvatarModels();
    const oxxxoInstance = await new Oxxxo(
      mainContainer.current,
      controlsContainer.current,
      chatContainer.current,
      uuid,
      virtualSpace,
      avatarLight,
      isMobile,
    );

    setOxxxo(oxxxoInstance);
    setLoading(false);
    const isLimitReached = await isUsersLimitReached(nombreDeEspacio);
    setUnableToConnect(isLimitReached);

    const pass = pasaporte(urlQueryParams);
    if (pass) {
      getCountryName().then(({ country_code }) => {
        const countryFlag = flag(country_code);
        connect(pass.username, pass.avatar, pass.initialAvatarPosition, country_code, countryFlag);
      });
    }
  };

  useEffect(() => {
    asyncLoad();
    dispatch(setOxxxoConfig(settings));
  }, []);

  return (
    <Div100vh className="root-container">
      {loading && !unableToConnect && <FullPageLoader />}
      {(!instructionsCompleted || !connected)
        && (
        <div className={`root-container__landing ${connected ? 'root-container__landing--back' : ''}`}>
          {connected && !instructionsCompleted
          && (
          <Instructions
            isMobile={isMobile}
            setInstructionsCompleted={setInstructionsCompleted}
          />
          )}
          <Login
            connected={connected}
            setConnected={connect}
            username={username}
            setUsername={setUsername}
            unableToConnect={unableToConnect}
          />

          {!loading && !connected
            && <LandingInfo />}
        </div>
        )}
      <MainContainer
        oxxxo={oxxxo}
        connected={connected}
        isMobile={isMobile}
        uuid={uuid}
        username={username}
        ref={mainContainer}
        controlsContainer={controlsContainer}
        chatContainer={chatContainer}
        nombreDeEspacio={nombreDeEspacio}
      />
    </Div100vh>
  );
};

export default App;
