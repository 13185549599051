import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import Raycaster from '../three-utils/Raycaster';
import { firebaseFirestore } from '../util/firebaseConnection';
import sanityModelLoader from '../three-utils/sanityModelLoader';
import store from '../store/store';

export default class VirtualSpaceInterface {
  init(scene, physicsWorld, audioStreams,
    multiplayer, camera, renderer, physicsEngine, userAvatar) {
    this.scene = scene;
    this.physicsWorld = physicsWorld;
    this.physicsEngine = physicsEngine;
    this.interactiveObjects = {};
    this.audioStreams = audioStreams;
    this.userAvatar = userAvatar;
    this.multiplayer = multiplayer;
    this.camera = camera;
    this.renderer = renderer;
    this.textureLoader = new THREE.TextureLoader();
    this.clock = new THREE.Clock();
    this.delta = this.clock.getDelta();
    this.initRaycaster();
    this.initPhysics();
    const { config } = store.getState();
    const { nombreDeEspacio } = config;
    this.nombreDeEspacio = nombreDeEspacio;
    sanityModelLoader(nombreDeEspacio, this.scene);
  }

  initPhysics() {
    // Materials
    const groundMaterial = new CANNON.Material('groundMaterial');
    // Adjust constraint equation parameters for ground/ground contact
    const groundGroundCm = new CANNON.ContactMaterial(groundMaterial, groundMaterial, {
      friction: 0.4,
      restitution: 0.3,
      contactEquationStiffness: 1e8,
      contactEquationRelaxation: 3,
      frictionEquationStiffness: 1e8,
      frictionEquationRegularizationTime: 3,
    });
    // Add contact material to the world
    this.physicsWorld.addContactMaterial(groundGroundCm);
  }

  initRaycaster() {
    this.raycaster = new Raycaster(this.camera, true);
    const callback = (userData) => { window.open(userData.URL); };
    this.raycaster.setOnClickCallback(callback);
  }

  createSpace() {
    this.scene.background = new THREE.Color('red');
  }

  applyDynamicSettingsChanges(data) {
    if (data.turnOnFog) {
      this.scene.fog = new THREE.Fog('black', 7, 10);
    } else {
      this.scene.fog = null;
    }
    if (data.fireExplosion && this.explosion) {
      this.explosion();
    }
  }

  initDynamicSettingsListener() {
    const docRef = firebaseFirestore.collection('space-settings').doc(this.nombreDeEspacio);
    docRef.onSnapshot((doc) => {
      const data = doc.data();
      if (data) {
        this.applyDynamicSettingsChanges(data);
      }
    });
  }

  update() {
    this.delta = this.clock.getDelta();
  }
}
