import { firebaseRealtimeDatabase } from "../../util/firebaseConnection";
import store from '../../store/store';
import { updateConnectedUsersCount } from '../../store/actions';
export default class FirebaseMultiplayerSync {

    constructor(players, myID){
      const { config } = store.getState();
      const { nombreDeEspacio } = config;
      this.usersCollection = `${nombreDeEspacio}${process.env.REACT_APP_FIREBASE_USERS_DATABASE_SUFFIX}`;
      this.db = firebaseRealtimeDatabase;
      this.myID = myID;
    }

    setAddPlayer = (fn) => {
      this.addPlayer = fn;
    }

    setRemovePlayer = (fn) => {
      this.removePlayer = fn;
    }

    setUpdatePositions = (fn) => {
      this.updatePositions = fn;
    }

    initListeners = () => {
      const ref = this.db.ref(this.usersCollection);
      ref.on("child_changed", (snapshot) => {
        const user = {
          id: snapshot.key,
          data: snapshot.val()
        }
        this.updatePositions(user);
      });

      ref.on("value", (snapshots) => {
        let connectedUsersCount = 0; 
        snapshots.forEach((snapshot) => {
          const data = snapshot.val();

          // Check if the user is connected
          if (data && data.username && data.lastUpdate && Date.now() - data.lastUpdate <= 1800000) {
            connectedUsersCount++;
          }

          const isMyUserInfo = data.id === this.myID;
          const notUpdatedLately = !data.lastUpdate || Date.now() - data.lastUpdate > 1800000;
          if(!data.username || notUpdatedLately || isMyUserInfo) {
            return;
          }

          const user = {
            id: snapshot.key,
            data
          }
          this.addPlayer(user);
        });

        store.dispatch(updateConnectedUsersCount(connectedUsersCount));
      });

      ref.on("child_removed", (snapshot) => {
        const user = snapshot.val();
        this.removePlayer(user.id);
      });
    }

    emitMoveAvatar = (position, rotation, myID) => {
      this.db
      .ref(`${this.usersCollection}/${this.myID}`)
      .once('value', (snapshot) => {
        if(snapshot.val() !== null) {
          // update only if user exists in db
           snapshot.ref.update({
             id: myID,
             position: position.toArray(),
             lastUpdate: Date.now(),
             rotation: {
               _x: rotation._x,
               _y: rotation._y,
               _z: rotation._z
             }
           });
         } else {
            console.log('logout if user is not on the db')
            // logout if user is not on the db
            window.location.reload();
         }
      });
    }

  }
