import * as THREE from 'three';
import { VRButton } from 'three/examples/jsm/webxr/VRButton';
import { XRControllerModelFactory } from 'three/examples/jsm/webxr/XRControllerModelFactory';
import TWEEN from '@tweenjs/tween.js';
import Stats from 'stats-js';
import { isDebugMode } from '../util/debug.util';
import PhysicsEngine from './PhysicsEngine';
import Multiplayer from './Multiplayer/Multiplayer';
import Avatar from './Avatar/Avatar';
import Controls from './Controls';
import createAudioStreams from './createAudioStreams';

class Oxxxo {
  static get CAMERA_SETTINGS () {
    return {
      viewAngle: 75,
      near: 0.1,
      far: 10000
    };
  }

  constructor(container, controlsContainer, chatbox, uuid, virtualSpace, avatarLight, isMobile){
    this.time = 0;
    this.uuid = uuid
    this.container = container;
    this.controlsContainer = controlsContainer;
    this.chatbox = chatbox;
    this.virtualSpace = virtualSpace;
    this.avatarLight = avatarLight;
    this.isMobile = isMobile;
    const width = container.clientWidth;
    const height = container.clientHeight;
    this.raycaster = new THREE.Raycaster();
    this.renderer = this.createRenderer(width, height);
    this.scene = new THREE.Scene();
    this.camera = this.createCamera(width, height, container);
    this.stats = this.initStats();
    return (async () => {
      this.initPhysicsEngine().then(({world,physicsEngine})=>{
        this.world = world;
        this.physicsEngine = physicsEngine;
      }).then(()=>{
        /*
        document.body.appendChild(VRButton.createButton(this.renderer));
        setTimeout(()=>{
          let estoEn2020 = Array.from(document.querySelectorAll('button'))
          .find(el =>
            (el.textContent === 'VR NOT SUPPORTED')
          );
          if(estoEn2020)estoEn2020.remove();
          estoEn2020 = Array.from(document.querySelectorAll('a'))
          .find(el =>
            (el.textContent === 'WEBXR NEEDS HTTPS')
          );
          if(estoEn2020)estoEn2020.remove();
        },200);
        */
      })
      return this;
    }).call(this);
  }

  init = (selectedAvatar, initialAvatarPosition) => {
    this.initAvatar(selectedAvatar, initialAvatarPosition)
    .then(() => {
      this.multiplayer = this.initMultiplayer();
      this.initControls();
      //this.setupVR();
    }).then(()=>{
    }).then(()=>{
      this.audioStreams = createAudioStreams(this.scene, this.userAvatar);
    }).then(()=>{
      this.createMeshes();
      this.startAnimationLoop();
    })
  }

  createRenderer = (width, height) => {
    this.clock = new THREE.Clock();
    const renderer = new THREE.WebGLRenderer({antialias: true});


    
   // renderer.physicallyCorrectLights = true;

    renderer.gammaOutput = false;
    renderer.gammaFactor = 1.5;
   renderer.outputEncoding = THREE.LinearEncoding;
    renderer.shadowMap.enabled = true;
    //  renderer.toneMapping = THREE.ReinhardToneMapping;
   
   // renderer.toneMappingExposure = 1.68 ** 5.0;





    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(width, height);
    renderer.xr.enabled = true;
    return renderer;
  };

  createCamera = (width, height) => {
    const settings = Oxxxo.CAMERA_SETTINGS;
    const camera = new THREE.PerspectiveCamera(
        settings.viewAngle,
        width / height,
        settings.near,
        settings.far
    );
    return camera;
  };

  initStats = () => {
    const isDebug = isDebugMode();
    if (isDebug) {
      const stats = new Stats();
      stats.showPanel(Number(isDebug));
      document.body.appendChild(stats.dom);
      return stats;
    }
      return null;
  }

  initPhysicsEngine = async () => {
    const physicsEngine = await new PhysicsEngine(this.scene);
    const world = physicsEngine.getWorld();
    return {world, physicsEngine};
  }

  createMeshes = () => {
    // to do remove this.world, use physicsEngine
    this.virtualSpace.init(this.scene,
                            this.world,
                            this.audioStreams,
                            this.multiplayer,
                            this.camera,
                            this.renderer,
                            this.physicsEngine,
                            this.userAvatar);
    this.virtualSpace.createSpace();
    this.virtualSpace.initDynamicSettingsListener();
  }

  initAvatar = async (selectedAvatar,initialAvatarPosition) => {
    this.userAvatar = await new Avatar( selectedAvatar,
                                        [120,100,40],
                                        // initialAvatarPosition,
                                         // [-481, 0, -5], // esfera negra galeria
                                        //  [353.8600925284935, 20, -346], // oxxxo
                                        //  [347,3,-23], // terreno
                                        //  [-167.968, 0.6999923, 124.87], // esfera fonima
                                         // [278.2, 1, -498.87], // escenario
                                        //  [-283, 0, -188], // humano
                                       // [-24.60676982436494,0.6999838712063833,208.75163643070155], //terreno2
                                          //[230,60,275], // esfera interactiva
                                        this.physicsEngine.slipperyMaterial,
                                        1
                                      );
    this.world.addBody(this.userAvatar.getPhysicsBody());
  }

  initMultiplayer = () => {
    const multiplayer = new Multiplayer(this.scene,
                                                  this.world,
                                                  this.physicsEngine.slipperyMaterial,
                                                  this.uuid);
    setTimeout(() => {
      this.players = multiplayer.getPlayers();
    },0)

    return multiplayer;
  }


  initControls = () => {
    this.keyboardControls = new Controls(
      this.camera,
      this.userAvatar,
      this.multiplayer.emitMoveAvatar,
      this.container,
      this.controlsContainer,
      this.chatbox,
      this.avatarLight,
    );
    this.scene.add(this.keyboardControls.getObject());

  }

  setupVR () {
    this.renderer.xr.enabled = true;
    
    // const button = new VRButton( this.renderer );
    document.body.appendChild(VRButton.createButton(this.renderer));
    const self = this;
    
    function onSelectStart() {
        
        this.userData.selectPressed = true;
    }

    function onSelectEnd() {

        this.userData.selectPressed = false;
        
    }
    this.controller = this.renderer.xr.getController( 0 );
    this.controller.addEventListener( 'selectstart', onSelectStart );
    this.controller.addEventListener( 'selectend', onSelectEnd );
    this.controller.addEventListener( 'connected', function ( event ) {

        const mesh = self.buildController.call(self, event.data );
        mesh.scale.z = 0;
        this.add( mesh );

    } );
    this.controller.addEventListener( 'disconnected', function () {

        this.remove( this.children[ 0 ] );
        self.controller = null;
        self.controllerGrip = null;

    } );
    this.scene.add( this.controller );

    const controllerModelFactory = new XRControllerModelFactory();

    this.controllerGrip = this.renderer.xr.getControllerGrip( 0 );
    this.controllerGrip.add( controllerModelFactory.createControllerModel( this.controllerGrip ) );
    this.scene.add( this.controllerGrip );
    
    this.dolly = new THREE.Object3D();
    // this.dolly.position.z = 5;
    this.dolly.add( this.camera );
    this.scene.add( this.dolly );
    
    this.dummyCam = new THREE.Object3D();
    this.camera.add( this.dummyCam );

  }

  buildController( data ) {
    let geometry, material;
    
    switch ( data.targetRayMode ) {
        
        case 'tracked-pointer':

            geometry = new THREE.BufferGeometry();
            geometry.setAttribute( 'position', new THREE.Float32BufferAttribute( [ 0, 0, 0, 0, 0, - 1 ], 3 ) );
            geometry.setAttribute( 'color', new THREE.Float32BufferAttribute( [ 0.5, 0.5, 0.5, 0, 0, 0 ], 3 ) );

            material = new THREE.LineBasicMaterial( { vertexColors: true, blending: THREE.AdditiveBlending } );

            return new THREE.Line( geometry, material );

        case 'gaze':

            geometry = new THREE.RingBufferGeometry( 0.02, 0.04, 32 ).translate( 0, 0, - 1 );
            material = new THREE.MeshBasicMaterial( { opacity: 0.5, transparent: true } );
            return new THREE.Mesh( geometry, material );

    }
  }

  handleController = ( controller, dt ) => {
    if (controller.userData.selectPressed ){
        
        const wallLimit = 1.3;
        const speed = 15;
        let pos = this.dolly.position.clone();
        pos.y += 1;

        let dir = new THREE.Vector3();
        //Store original dolly rotation
        const quaternion = this.dolly.quaternion.clone();
        //Get rotation for movement from the headset pose
        this.dolly.quaternion.copy( this.dummyCam.getWorldQuaternion() );
        this.dolly.getWorldDirection(dir);
        dir.negate();
        this.raycaster.set(pos, dir);

        let blocked = false;

        let intersect = this.raycaster.intersectObjects(this.colliders);
        if (intersect.length>0){
            if (intersect[0].distance < wallLimit) blocked = true;
        }

        if (!blocked){
            this.dolly.translateZ(-dt*speed);
            pos = this.dolly.getWorldPosition( this.origin );
        }

        //cast left
        dir.set(-1,0,0);
        dir.applyMatrix4(this.dolly.matrix);
        dir.normalize();
        this.raycaster.set(pos, dir);

        intersect = this.raycaster.intersectObjects(this.colliders);
        if (intersect.length>0){
            if (intersect[0].distance<wallLimit) this.dolly.translateX(wallLimit-intersect[0].distance);
        }

        //cast right
        dir.set(1,0,0);
        dir.applyMatrix4(this.dolly.matrix);
        dir.normalize();
        this.raycaster.set(pos, dir);

        intersect = this.raycaster.intersectObjects(this.colliders);
        if (intersect.length>0){
            if (intersect[0].distance<wallLimit) this.dolly.translateX(intersect[0].distance-wallLimit);
        }

        this.dolly.position.y = 0;

        //Restore the original rotation
        this.dolly.quaternion.copy( quaternion );

    }
  }

  startAnimationLoop = () => {
    this.renderer.setAnimationLoop(this.render);
  }

  render = () => {
    if(this.stats) this.stats.begin();

    this.physicsEngine.update();

    const delta = this.clock.getDelta();

    this.virtualSpace.update();

    this.userAvatar.update(delta);

    try{
      if(this.players) {
        Object.keys(this.players).forEach(mixer => {
          //if(!this.players[mixer]) return;
          //eslint-disable-next-line no-unused-expressions
            this.players[mixer]?.avatar.update(delta);
           // this.players[mixer].object3D.position.copy(this.players[mixer].physicsBody.position);
        });
      }

      TWEEN.update();
    }
    catch(error){
      TWEEN.removeAll();
      console.log(error);
    }
    if (this.keyboardControls) this.keyboardControls.update(Date.now() - this.time);

    if(this.stats) this.stats.end();
    if (this.controller) this.handleController(this.controller, delta);
    this.renderer.render(this.scene, this.camera);
    this.time = Date.now();
  };

  switchAudioChannel = () => {
    if (!this.audioStreams) return;
    if (this.audioStreams[0].isPlaying()){
      this.audioStreams[0].stop();
      this.audioStreams[1].play();
    }
    else{
      this.audioStreams[1].stop();
      this.audioStreams[0].play();
    }
  }

  setVolume = (value) => {
    if(this.audioStreams){
      this.audioStreams.forEach((stream) => stream.setVolume(value));
    }
  }

  handleWindowResize = () => {
    clearTimeout(this.resizeTimer);
    this.resizeTimer = setTimeout(() =>{
      const width = this.container.clientWidth;
      const height = this.container.clientHeight;
      this.renderer.setSize(width, height);
      this.camera.aspect = width / height;
      this.camera.updateProjectionMatrix();
    }, 200);
  };

  unmount = () => {
    window.cancelAnimationFrame(this.requestID);
  };

}

export default Oxxxo;
