import { createStairs, createFloor } from '../../../three-utils/physicsUtils';

const initOxxo = (physicsWorld, physicsEngine) => {
  // escaleras
  createStairs(physicsWorld, 14, [385, -0.5, -354.1], -Math.PI, physicsEngine.slipperyMaterial);
  createStairs(physicsWorld, 7, [382.5, 7.3, -373.9], -Math.PI / 2, physicsEngine.slipperyMaterial);

  // escalera descanzos
  createFloor(physicsWorld, [2.1, 0.25, 2.1], [385, 6.5, -369.6]);
  createFloor(physicsWorld, [2.1, 0.25, 2.1], [385, 7, -373.9]);
  createFloor(physicsWorld, [2.1, 0.25, 2.1], [374, 10.9, -373.9]);

  // paredes
  createFloor(physicsWorld, [30, 17.5, 0.5], [357.350, 17, -376.500]);
  createFloor(physicsWorld, [27, 17.5, 0.5], [360.710, 17, -335.040]);
  createFloor(physicsWorld, [0.5, 17.5, 20.5], [387.950, 17, -355.860]);
  createFloor(physicsWorld, [0.5, 17.5, 18], [326.720, 17, -358.900]);

  // entrada
  createFloor(physicsWorld, [1, 13, 4.4], [329.990, 21, -337.720], 0.760);
  createFloor(physicsWorld, [1, 5, 1.5], [328.220, 5, -341.320], 0.760);
  createFloor(physicsWorld, [1, 5, 1.5], [332.750, 5, -335.680], 0.760);

  // segundo piso
  createFloor(physicsWorld, [25, 0.25, 20], [351, 11.6, -355.6]);

  // pared segundo piso
  createFloor(physicsWorld, [1, 11.5, 7.5], [356.630, 23.260, -368.190]);
  createFloor(physicsWorld, [1, 11.5, 7.5], [356.630, 23.260, -343.990]);
};

export default initOxxo;
