import * as THREE from 'three';

const initDomoFonima = (scene, blobGeometry, textureLoader) => {
  // salmon
  const sphereBlob = new THREE.Mesh(
    blobGeometry,
    new THREE.MeshBasicMaterial({
      side: THREE.BackSide,
      color: new THREE.Color(0xFFB592),
    }),
  );
  sphereBlob.position.set(-202.38, 2, 146.69);
  sphereBlob.scale.set(35, 35, 35);
  scene.add(sphereBlob);

  // verde
  const sphereBlob4 = new THREE.Mesh(
    blobGeometry,
    new THREE.MeshBasicMaterial({
      side: THREE.FrontSide,
      color: new THREE.Color(0xBAF7C7),
    }),
  );

  sphereBlob4.position.set(-202.38, 2, 146.69);
  sphereBlob4.scale.set(45, 45, 45);
  scene.add(sphereBlob4);

  // morado
  const sphereBlob3 = new THREE.Mesh(
    blobGeometry,
    new THREE.MeshBasicMaterial({
      side: THREE.BackSide,
      color: new THREE.Color(0xA792FF),
    }),
  );

  sphereBlob3.position.set(-202.38, 2, 146.69);
  sphereBlob3.scale.set(34, 36, 33);
  scene.add(sphereBlob3);

  let logosMesh;
  textureLoader.load('textures/fonima/domo-fonima/logos.png', (texture) => {
    const geometryT = new THREE.PlaneGeometry(8, 4);
    const materialT = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    logosMesh = new THREE.Mesh(geometryT, materialT);
    logosMesh.position.set(-224.363, 3, 149.07);
    scene.add(logosMesh);
  });


  let queesMesh;
  textureLoader.load('textures/fonima/domo-fonima/que-es.png', (texture) => {
    const geometryT = new THREE.PlaneGeometry(6, 2);
    const materialT = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    queesMesh = new THREE.Mesh(geometryT, materialT);
    queesMesh.position.set(-179.06, 8, 144.13);
    scene.add(queesMesh);
  });

  let somosMesh;
  textureLoader.load('textures/fonima/domo-fonima/somos.png', (texture) => {
    const geometryT = new THREE.PlaneGeometry(6, 6);
    const materialT = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    somosMesh = new THREE.Mesh(geometryT, materialT);
    somosMesh.position.set(-179.06, 3.5, 144.13);
    somosMesh.rotation.y = Math.PI;
    scene.add(somosMesh);
  });

  let redesMesh;
  textureLoader.load('textures/fonima/domo-fonima/redes.png', (texture) => {
    const geometryT = new THREE.PlaneGeometry(3, 3);
    const materialT = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    redesMesh = new THREE.Mesh(geometryT, materialT);
    redesMesh.position.set(-206, 3, 169.12);
    redesMesh.rotation.y = Math.PI;
    scene.add(redesMesh);
  });

  let facebookMesh;
  textureLoader.load('textures/fonima/domo-fonima/facebook.png', (texture) => {
    const geometryT = new THREE.PlaneGeometry(1, 1);
    const materialT = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    facebookMesh = new THREE.Mesh(geometryT, materialT);
    facebookMesh.position.set(-191, 3, 164);
    facebookMesh.rotation.y = Math.PI;
    scene.add(facebookMesh);
  });

  let foncaMesh;
  textureLoader.load('textures/fonima/domo-fonima/cultura_fonca.svg', (texture) => {
    const geometry = new THREE.PlaneGeometry(6, 1);
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    foncaMesh = new THREE.Mesh(geometry, material);
    foncaMesh.position.set(-198.07, 3, 164.8);
    foncaMesh.rotation.y = Math.PI;
    scene.add(foncaMesh);
  });

  let instagramMesh;
  textureLoader.load('textures/fonima/domo-fonima/instagram.png', (texture) => {
    const geometryT = new THREE.PlaneGeometry(1, 1);
    const materialT = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide,
      transparent: true,
    });
    instagramMesh = new THREE.Mesh(geometryT, materialT);
    instagramMesh.position.set(-189, 3, 164);
    instagramMesh.rotation.y = Math.PI;
    scene.add(instagramMesh);
  });

  // textos
  const items = 20;
  const centerX = 50;
  const centerY = 2;
  const radius = 10;
  const textGroup = new THREE.Group();

  let pivot;
  textureLoader.load('textures/fonima/domo-fonima/fonima_logo.png', (texture) => {
    for (let i = 0; i < items; i += 1) {
      const x = centerX + radius * Math.sin((2 * Math.PI * i) / items);
      const z = centerY + radius * Math.cos((2 * Math.PI * i) / items);

      const geometryT = new THREE.PlaneGeometry(8, 4);
      const materialT = new THREE.MeshBasicMaterial({
        map: texture,
        side: THREE.DoubleSide,
        transparent: true,
      });
      const text = new THREE.Mesh(geometryT, materialT);
      text.position.set(x, z, 2);
      text.rotation.y = Math.PI / 2;
      textGroup.add(text);
    }

    const box = new THREE.Box3().setFromObject(textGroup);
    box.getCenter(textGroup.position);
    textGroup.position.multiplyScalar(-1);
    pivot = new THREE.Group(-202.38, 0, 146.69);
    pivot.position.set(-202.38, 10, 146.69);
    pivot.add(textGroup);
    scene.add(pivot);
  });

  /*
  for (let i = 0; i < items; i += 1) {
    const x = centerX + radius * Math.sin((2 * Math.PI * i) / items);
    const z = centerY + radius * Math.cos((2 * Math.PI * i) / items);

    const text = createText('FONIMA', 0x000000, 2);
    text.position.set(x, z, 2);
    text.rotation.y = Math.PI / 2;
    textGroup.add(text);
  }
  */

  return () => {
    if (logosMesh) logosMesh.rotation.y += 0.01;
    if (queesMesh) queesMesh.rotation.y += 0.03;
    if (foncaMesh) foncaMesh.rotation.y += 0.02;

    if (pivot) {
      pivot.rotation.z += 0.01;
      pivot.rotation.y += 0.001;
    }
  };
};

export default initDomoFonima;
