import React from 'react';
import { isMobile } from 'react-device-detect';
import arrowImg from '../img/arrow.svg';
import circleImg from '../img/circle.svg';
import './ControlsComponent.scss';

const ControlsComponent = React.forwardRef((props, refContainer) => (
  <div ref={refContainer} className={`controls ${isMobile ? '' : 'controls--hidden'}`}>
    <div className="controls__container">
      <span
        className="controls__button"
        data-direction="up"
      >
        <img
          src={arrowImg}
          style={{ transform: 'rotate(180deg)' }}
          alt="button-up"
        />
      </span>
      <div>
        <span
          className="controls__button"
          data-direction="left"
        >
          <img
            src={arrowImg}
            alt="button-left"
            style={{ transform: 'rotate(90deg)' }}
          />
        </span>
        <span
          className="controls__button"
          data-direction="jump"
        >
          <img
            src={circleImg}
            alt="button-jump"
          />
        </span>
        <span
          className="controls__button"
          data-direction="right"
        >
          <img
            src={arrowImg}
            alt="button-right"
            style={{ transform: 'rotate(-90deg)' }}
          />
        </span>
      </div>
      <span
        className="controls__button"
        data-direction="down"
      >
        <img
          src={arrowImg}
          alt="button-down"
        />
      </span>
    </div>
    <div className="controls__container controls__container--right">
      <span
        className="controls__button"
        data-direction="lookUp"
      >
        <img
          src={arrowImg}
          alt="look up button"
          style={{ transform: 'rotate(180deg)' }}
        />
      </span>
      <div>
        <span
          className="controls__button"
          data-direction="lookLeft"
        >
          <img
            src={arrowImg}
            alt="button-look-left"
            style={{ transform: 'rotate(90deg)' }}
          />
        </span>
        <span
          className="controls__button"
          data-direction="jump"
        >
          <img
            src={circleImg}
            alt="button-jump"
          />
        </span>
        <span
          className="controls__button"
          data-direction="lookRight"
        >
          <img
            src={arrowImg}
            alt="button-look-right"
            style={{ transform: 'rotate(-90deg)' }}
          />
        </span>
      </div>
      <span
        className="controls__button"
        data-direction="lookDown"
      >
        <img src={arrowImg} alt="button-look-down" />
      </span>
    </div>
  </div>
));

export default ControlsComponent;
