import * as THREE from 'three';
import * as CANNON from 'cannon-es';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { RectAreaLightHelper } from 'three/examples/jsm/helpers/RectAreaLightHelper';
import VirtualSpaceInterface from '../../../oxxxo/VirtualSpaceInterface';

export default class VirtualSpace extends VirtualSpaceInterface {
  createSpace() {
    const textureLoader = new THREE.TextureLoader();

    // Materials
    const groundMaterial = new CANNON.Material('groundMaterial');
    // Adjust constraint equation parameters for ground/ground contact
    const groundGroundCm = new CANNON.ContactMaterial(groundMaterial, groundMaterial, {
      friction: 0.4,
      restitution: 0.3,
      contactEquationStiffness: 1e8,
      contactEquationRelaxation: 3,
      frictionEquationStiffness: 1e8,
      frictionEquationRegularizationTime: 3,
    });
    // Add contact material to the world
    this.physicsWorld.addContactMaterial(groundGroundCm);

    const fbxLoader = new FBXLoader();
    fbxLoader.load('/models/ceremonia/ceremonia_logo.fbx', (model) => {
      model.position.set(-1, -1, -1);
      model.scale.set(0.01, 0.01, 0.01);
      this.interactiveObjects.ceremoniaLogo = model;
      this.scene.add(model);
    });
    fbxLoader.load('/models/ceremonia/--v3.fbx', (model) => {
      model.position.set(-1, -1, -1);
      model.scale.set(0.01, 0.01, 0.01);
      this.scene.add(model);
    });

    const light = new THREE.AmbientLight(0x707070); // soft white light
    this.scene.add(light);

    // pantalla ceremonia

    const blueRectLightCeremonia = new THREE.RectAreaLight(0xf3aaaa, 0, 20, 10);
    blueRectLightCeremonia.position.set(42, 4.5, -38.8);
    blueRectLightCeremonia.rotation.set(0, Math.PI, 0);

    this.scene.add(blueRectLightCeremonia);
    const blueRectLightCeremoniaHelper = new RectAreaLightHelper(blueRectLightCeremonia, 0xffffff);
    blueRectLightCeremonia.add(blueRectLightCeremoniaHelper);
    blueRectLightCeremoniaHelper.color = new THREE.Color(1, 0, 0);
    blueRectLightCeremoniaHelper.update();
    this.interactiveObjects.blueRectLightCeremonia = blueRectLightCeremonia;
    this.interactiveObjects.blueRectLightCeremoniaHelper = blueRectLightCeremoniaHelper;

    // ceremonia
    const display1 = new THREE.Group();
    display1.position.set(42, 4, -38.8);
    this.interactiveObjects.display1 = display1;
    this.scene.add(display1);

    // pantalla oxxxo
    const width = 10;
    const height = 10;
    const blueRectLightOxxxo = new THREE.RectAreaLight(0xf3aaaa, 0, width, height);
    blueRectLightOxxxo.position.set(16, 1, 0.8);
    blueRectLightOxxxo.rotation.set(0, Math.PI / 2, 0);

    this.scene.add(blueRectLightOxxxo);
    const blueRectLightOxxxoHelper = new RectAreaLightHelper(blueRectLightOxxxo, 0xffffff);
    blueRectLightOxxxo.add(blueRectLightOxxxoHelper);
    blueRectLightOxxxoHelper.color = new THREE.Color(1, 0, 0);
    blueRectLightOxxxoHelper.update();
    this.interactiveObjects.blueRectLightOxxxo = blueRectLightOxxxo;
    this.interactiveObjects.blueRectLightOxxxoHelper = blueRectLightOxxxoHelper;

    // oxxxo
    const display2 = new THREE.Group();
    display2.position.set(16, 3.5, 1);
    display2.rotation.set(0, Math.PI * 1.5, 0);
    this.interactiveObjects.display2 = display2;
    this.scene.add(display2);

    const sphere = new THREE.SphereBufferGeometry(0.05, 16, 8);
    const light1 = new THREE.PointLight(0xff0000, 1, 10);
    light1.add(new THREE.Mesh(sphere, new THREE.MeshPhongMaterial({
      color: 0xff0000,
      emissive: 0xff0000,
      specular: 0xff0000,
      emissiveIntensity: 10,
      shininess: 500,
    })));
    light1.castShadow = true;
    light1.position.set(41, 1.3, -24);
    this.interactiveObjects.bulbLight = light1;
    this.scene.add(light1);

    textureLoader.load('textures/ceremonia/grasslight-big.jpg', (groundTexture) => {
      groundTexture.wrapS = THREE.RepeatWrapping;
      groundTexture.wrapT = THREE.RepeatWrapping;
      groundTexture.encoding = THREE.sRGBEncoding;
      groundTexture.repeat.set(25, 25);
      groundTexture.anisotropy = 8;

      const groundMaterialz = new THREE.MeshLambertMaterial({ map: groundTexture });
      const mesh = new THREE.Mesh(new THREE.PlaneBufferGeometry(200, 200), groundMaterialz);
      mesh.rotation.x = -Math.PI / 2;
      // mesh.receiveShadow = true;
      this.scene.add(mesh);
    });
  }
}
